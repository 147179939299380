import axios from "axios";

//post
export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const UPDATE_POST = "UPDATE_POST";
export const UPDATE_MEDIA = "UPDATE_MEDIA";
export const DELETE_MEDIA = "DELETE_MEDIA";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";

export const getPosts = () => async (dispatch) =>
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/message/`, {
      withCredentials: true,
    })
    .then((res) => {
      const currentDate = new Date();
      const newArray = res.data
        .filter((post) => new Date(post.scheduledDate) <= currentDate)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      dispatch({ type: GET_POSTS, payload: newArray });
    })
    .catch((error) => console.error(error));

export const getPost = (postId) => async (dispatch) =>
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/message/${postId}`, {
      withCredentials: true,
    })
    .then((res) => dispatch({ type: GET_POST, payload: res.data }))
    .catch((error) => console.error(error));

export const updatePost = (postId, texte, sousTraitant) => {
  return async (dispatch) => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/api/message/edit/` + postId,
        withCredentials: true,
        data: { texte, sousTraitant }, // Inclure la valeur de la case à cocher dans l'objet data
      });
      dispatch({
        type: UPDATE_POST,
        payload: { texte, postId },
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateMedia = (postId, media) => async (dispatch) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_API_URL}/api/message/edit/${postId}`,
      media,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: UPDATE_MEDIA,
      payload: { media, postId },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deletePost = (postId) => async (dispatch) => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/message/${postId}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: DELETE_POST,
      payload: { postId },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteMediaMessage = (postId, media) => {
  return async (dispatch) => {
    try {
      await axios({
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/api/message/` + postId,
        withCredentials: true,
        data: media,
      });
      dispatch({
        type: DELETE_MEDIA,
        payload: { media, postId },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const addPost = (data) => {
  return async (dispatch) => {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/message/`,
      withCredentials: true,
      data: data,
    })
      .then((res) => {
        dispatch({ type: ADD_POST, payload: res.data });
      })
      .catch((error) => console.error(error));
  };
};
