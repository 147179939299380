import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isEmpty } from "../utils/Utils";
import DeleteCard from "./DeleteCard";
import { UidContext } from "../../Components/App.Context";
import moment from "moment";
import "moment/locale/fr";
import {
  addKnwoledgeFav,
  getKnwoledgeFav,
  removeKnowledgeFav,
} from "../../actions/knowledgeFav.actions";
import parse from "html-react-parser";

const extractIframeTag = (content) => {
  if (!content) {
    return { iframeTag: null, hasIframe: false };
  }

  const iframeRegex = /<iframe\b[^>]*>([\s\S]*?)<\/iframe>/;
  const match = content.match(iframeRegex);
  if (match) {
    const iframeTag = match[0];
    const cleanedIframeTag = iframeTag.replace(
      /style="[^"]*position:\s*absolute;?[^"]*"/,
      'style="width: 100%; height: 100%;"'
    );
    return { iframeTag: cleanedIframeTag, hasIframe: true };
  }
  return { iframeTag: null, hasIframe: false };
};

const Card = ({ post }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const userData = useSelector((state) => state.userReducer);
  const uid = useContext(UidContext);
  const postes = [post];
  const dispatch = useDispatch();

  const knowledgeFav = useSelector((state) => state.knowledgeFav);

  useEffect(() => {
    !isEmpty(post) && setIsLoading(false);
  }, [post]);

  useEffect(() => {
    if (uid === userData?.id && userData?.isAdmin && !isAdmin) {
      setIsAdmin(true);
    }
  }, [isAdmin, uid, userData]);

  const formattedDate = moment(post?.createdAt).locale("fr").format("MMM YYYY");

  useEffect(() => {
    const myFavorite = Object.values(knowledgeFav).find(
      (fav) => fav?.userId === userData.id && fav?.knowledgeId === post?.id
    );
    myFavorite && setIsFavorite(true);
  }, [knowledgeFav, userData]);

  const handleAddFavorite = (e) => {
    e.preventDefault();
    const newIsFavorite = !isFavorite;

    dispatch(
      isFavorite
        ? removeKnowledgeFav(userData?.id, post?.id)
        : addKnwoledgeFav(userData?.id, post?.id)
    ).then(() => {
      dispatch(getKnwoledgeFav(userData?.id));
      setIsFavorite(newIsFavorite);
    });
  };

  const { iframeTag, hasIframe } = extractIframeTag(post?.texte);

  return (
    <div className="card-knowledge-container">
      {isLoading ? (
        <div className="chargement">
          <i className="fas fa-spinner fa-spin"></i>
          <span>CHARGEMENT</span>
        </div>
      ) : (
        <div className="knowledge-content">
          <span className="contentKnowledge">
            {postes.map((posts) => {
              return (
                <Link to={`/tutoriels/${posts?.id}`}>
                  {hasIframe && typeof iframeTag === "string" ? (
                    parse(iframeTag)
                  ) : (
                    <div className="coverTitle">
                      <p>{posts?.title}</p>
                    </div>
                  )}
                </Link>
              );
            })}
          </span>
          <span className="title">
            <div className="knowledge_info">
              <span className="date">{formattedDate}</span>
            </div>
            <div className="knowledge_title">
              {postes.map((posts) => {
                return (
                  <Link to={`/tutoriels/${posts.id}`}>
                    <p>{posts?.title}</p>
                  </Link>
                );
              })}
              <span className="user">
                {postes.map((posts) => {
                  return (
                    "Par: " + posts?.user?.firstname + " " + posts?.user?.name
                  );
                })}
              </span>
            </div>
          </span>
          <span className="categories">
            {postes.map((posts) => {
              return posts?.categories;
            })}
          </span>
          <div className="cardheader-container-delete">
            {isAdmin || userData.id === post.user.id ? (
              <div className="cardheader-container-edit">
                <div className="cardheader-edit">
                  <DeleteCard id={post.id} />
                </div>
              </div>
            ) : null}
          </div>
          <div className="cardheader-container-favorite">
            <button className="favorite-button" onClick={handleAddFavorite}>
              <i
                className={
                  isFavorite ? "fa-solid fa-bookmark" : "fa-regular fa-bookmark"
                }
              ></i>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
