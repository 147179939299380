import {
  GET_BOITEOUTILS,
  ADD_BOITEOUTILS,
  DELETE_BOITEOUTILS,
} from "../actions/boiteaoutils.actions";

const initialState = {};

export default function boiteaoutilReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BOITEOUTILS:
      return action.payload;
    case ADD_BOITEOUTILS:
      return {
        ...state,
        boiteOutils: [...state.boiteOutils, action.payload],
      };
    case DELETE_BOITEOUTILS:
      return {
        ...state,
        boiteOutils: state.boiteOutils.filter(
          (outil) => outil.id !== action.payload.id
        ),
      };

    default:
      return state;
  }
}
