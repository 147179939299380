import { GET_LIKE, LIKE_POST, UNLIKE_POST } from "../actions/likes.actions";

const initialState = {};

export default function likesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIKE:
      return action.payload;
    case LIKE_POST:
      return state.map((like) => {
        if (like.postId === action.payload.postId) {
          return {
            ...like,
            userId: [action.payload.userId],
          };
        }
        return like;
      });
    case UNLIKE_POST:
      return state.map((like) => {
        if (like.postId === action.payload.postId) {
          const userIdArray = Array.isArray(like.userId)
            ? like.userId
            : [like.userId];
          return {
            ...like,
            userId: userIdArray.filter(
              (userId) => userId !== action.payload.userId
            ),
          };
        }
        return like;
      });
    default:
      return state;
  }
}
