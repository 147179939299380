import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const SignupForm = () => {
  const [firstname, setFirstName] = useState("");
  const [name, setName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [coach, setCoach] = useState("");

  //Configuration liste de service
  const servicesConfig = [
    { value: "", label: "--Choisissez votre service--" },
    { value: "Coach", label: "Coach" },
    { value: "Référent technique", label: "Référent technique" },
    { value: "E-commerce", label: "Chargé de site E-commerce" },
    { value: "Graphiste", label: "Graphiste" },
    { value: "Chargé(e) de projet web", label: "Chargé(e) de projet web" },
    { value: "Traffic manager", label: "Traffic manager" },
    { value: "Ordonnancement", label: "Ordonnancement" },
    { value: "Planification", label: "Planification" },
  ];

  const [service, setService] = useState("");
  const usersData = useSelector((state) => state.usersReducer);

  const allCoach = Object.values(usersData).filter((service) => {
    return service.service === "Coach";
  });

  //Regex pour le controle du password
  let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
  let testPassword = strongRegex.test(password);

  //Regex pour le controle de l'eamil
  let strongEmailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/i
  );
  let testEmail = strongEmailRegex.test(email);

  const handleSignup = (e) => {
    e.preventDefault();
    if (!firstname || !name || !email || !password || !service || !coach) {
      toast.error("veuillez remplir tous les champs du formulaire");
    } else if (!testPassword) {
      toast.error(
        "Le mot de passe doit comprendre 8 caractères dont 2 chiffres. Ainsi qu'une lettre majuscule et minuscule."
      );
    } else if (!testEmail) {
      toast.error("Veuillez verifier votre email, il semble incorrect");
    } else {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/user/signup`,
        withCredentials: true,
        data: {
          name,
          firstname,
          birthday,
          email,
          password,
          service,
          coach,
        },
      })
        .then((res) => {
          console.log(res);
          if (res.data.errors) {
          } else {
            toast.success("Utilisateur créé avec succès");
          }
        })
        .catch(function (error) {
          if (error.response) {
            toast.error(error.response.data.message);
          }
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <form action="" onSubmit={handleSignup}>
        <label htmlFor="name">Nom</label>
        <input
          type="text"
          name="name"
          className="input_signup"
          id="name"
          placeholder="Entrez votre nom"
          onChange={(e) => setName(e.target.value)}
          value={name}
          required
        />
        <br />
        <label htmlFor="firstname">Prénom</label>
        <input
          type="text"
          name="firstname"
          className="input_signup"
          id="firstname"
          placeholder="Entrez votre prénom"
          onChange={(e) => setFirstName(e.target.value)}
          value={firstname}
          required
        />
        <br />
        <label htmlFor="birthday">Date d'entrée chez Local</label>
        <input
          type="date"
          name="birthday"
          className="input_signup"
          id="birthday"
          onChange={(e) => setBirthday(e.target.value)}
          value={birthday}
        />
        <br />
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          className="input_signup"
          id="email"
          placeholder="Entrez votre mail"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />
        <br />
        <label htmlFor="password">Mot de passe</label>
        <input
          type="password"
          name="password"
          className="input_signup"
          id="password"
          placeholder="Entrez votre mot de passe"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          required
        />
        <br />
        <label htmlFor="password">Service</label>
        <select
          name="service"
          id="service"
          onChange={(e) => setService(e.target.value)}
        >
          {servicesConfig.map((service) => (
            <option value={service.value}>{service.label}</option>
          ))}
        </select>
        <br />
        <label htmlFor="password">Coach de l'utilisateur</label>
        <select
          name="coach"
          id="coach-select"
          onChange={(e) => setCoach(e.target.value)}
        >
          <option value="">--Ajouter un coach--</option>
          {allCoach.map((coach) => {
            return (
              <option value={coach.firstname + " " + coach.name} key={coach.id}>
                {coach.firstname} {coach.name}
              </option>
            );
          })}
        </select>
        <br />
        <input type="submit" className="submit" value="Inscription" />
      </form>
    </>
  );
};

export default SignupForm;
