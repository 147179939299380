import React from "react";
import { Link } from "react-router-dom";

const KnowledgeMenu = (props) => {
  return (
    <div className="progressbar-wrapper">
      <ul className="progressbar">
        <li
          className={
            props.active === "requestKnowledge" ||
            props.active === "assignee" ||
            props.active === "termine" ||
            props.active === "annule"
              ? "active"
              : ""
          }
        >
          <Link to={"/requestKnowledge"}>
            <p>A traiter </p>
          </Link>
        </li>
        <li
          className={
            props.active === "assignee" ||
            props.active === "termine" ||
            props.active === "annule"
              ? "active"
              : ""
          }
        >
          <Link to={"/assignee"}>
            <p>Assignée </p>
          </Link>
        </li>
        <li
          className={
            props.active === "termine" || props.active === "annule"
              ? "active"
              : ""
          }
        >
          <Link to={"/reqestTermine"}>
            <p>Terminée </p>
          </Link>
        </li>
        <li className={props.active === "annule" ? "active-red" : ""}>
          <Link to={"/requestAnnule"}>
            <p>Annulée </p>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default KnowledgeMenu;
