import axios from "axios";

export const GET_COMMENTS_TUTO = "GET_COMMENTS_TUTO";
export const ADD_COMMENT_TUTO = "ADD_COMMENT_TUTO";
export const EDIT_COMMENT_TUTO = "EDIT_COMMENT_TUTO";
export const DELETE_COMMENT_TUTO = "DELETE_COMMENT_TUTO";

export const getComments = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/tuto/comment/all`,
      { withCredentials: true }
    );
    dispatch({
      type: GET_COMMENTS_TUTO,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const addComment = (commentaire, postId) => {
  return (dispatch) => {
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/tuto/comment/${postId}`,
      withCredentials: true,
      data: { commentaire: commentaire, postId: postId },
    })
      .then((res) => {
        dispatch({ type: ADD_COMMENT_TUTO, payload: { postId } });
      })
      .catch((error) => console.error(error));
  };
};

export const editComment = (commentaire, commentId) => {
  return (dispatch) => {
    return axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/api/tuto/comment/${commentId}`,
      withCredentials: true,
      data: { commentaire: commentaire },
    })
      .then((res) => {
        dispatch({
          type: ADD_COMMENT_TUTO,
          payload: { commentId, commentaire },
        });
      })
      .catch((error) => console.error(error));
  };
};

export const deleteComment = (commentId) => {
  return (dispatch) => {
    return axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/api/tuto/comment/${commentId}`,
      withCredentials: true,
    })
      .then((res) => {
        dispatch({ type: DELETE_COMMENT_TUTO, payload: { commentId } });
      })
      .catch((error) => console.error(error));
  };
};
