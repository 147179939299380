import React, { useState } from "react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { createKnowledge, getKnwoledge } from "../../actions/knowledge.actions";

const AddKnowledge = ({ socket, userData }) => {
  const [title, setTitle] = useState("");
  const [texte, setTexte] = useState("");
  const [publishOnFeed, setPublishOnFeed] = useState(false);

  const categoriesConfig = [
    { value: "", label: "--Choisir une catégorie--" },
    { value: "LocalBoutique", label: "LocalBoutique" },
    { value: "LocalShop", label: "LocalShop" },
    { value: "WebTool 3", label: "WebTool 3" },
    { value: "NDD", label: "NDD/MAILS" },
    { value: "localagenda", label: "LocalAgenda" },
    { value: "reservit", label: "Reservit" },
    { value: "toolbox", label: "Toolbox" },
    { value: "seo", label: "SEO/SEM" },
    { value: "localetmoi", label: "Local&Moi" },
  ];
  const [categories, setCategories] = useState("");
  const dispatch = useDispatch();

  //Menu toggle
  const addNewKnowledge = () => {
    const toggleMenu = document.querySelector(".add-new-knowledge");
    toggleMenu.classList.toggle("active_knowledge");
  };

  const handleAddKnowledge = async (e) => {
    e.preventDefault();

    if (texte || title || categories) {
      dispatch(
        createKnowledge(userData.id, texte, categories, title, publishOnFeed)
      )
        .then(() => dispatch(getKnwoledge()))
        .then(() => {
          toast.success("Tutoriel publié !");
        })
        .then(() =>
          socket?.emit("sendNotificationAll", {
            senderName: userData.name,
          })
        );
    } else {
      toast.error("Veuillez entrer un message avant de publier");
    }
  };

  return (
    <div className="action_add">
      <ToastContainer />
      <div className="new-knowledge">
        <button className="add-knowlegde" onClick={addNewKnowledge}>
          Ajouter +
        </button>
      </div>
      <div className="add-new-knowledge">
        <button className="close-button" onClick={addNewKnowledge}>
          <i className="fa-regular fa-circle-xmark"></i>
        </button>
        <h3>Ajouter un nouveau Tutoriel</h3>
        <br />
        <br />
        <form action="" onSubmit={handleAddKnowledge}>
          <label htmlFor="name">Titre</label>
          <input
            type="text"
            name="title"
            className="input_signup"
            id="title"
            placeholder="Le titre du Tuto"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            required
          />
          <br />
          <label for="categories">Catégorie:</label>
          <select
            name="categories"
            id="categories"
            onChange={(e) => setCategories(e.target.value)}
          >
            {categoriesConfig.map((category) => (
              <option value={category.value}>{category.label}</option>
            ))}
          </select>
          <br />
          <label for="categories">Description:</label>
          <div className="texte-knowledge">
            <CKEditor
              editor={Editor}
              data={""}
              onChange={(event, editor) => {
                const data = editor.getData();
                setTexte(data);
              }}
            />
          </div>
          <br />
          <div className="checbox">
            <label htmlFor="publishOnFeed">
              Publier aussi sur le fil d'actualité ?
            </label>
            <input
              type="radio"
              name="publishOnFeed"
              value="true"
              checked={publishOnFeed}
              onChange={(e) => setPublishOnFeed(e.target.value === "true")}
            />
          </div>
          <br />
          <br />
          <input
            type="submit"
            className="submit"
            value="Ajouter et publier"
            onClick={addNewKnowledge}
          />
        </form>
      </div>
    </div>
  );
};

export default AddKnowledge;
