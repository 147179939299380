import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nav from "../Components/Nav/Nav";
import Login from "./Login";
import AdminMenu from "../Components/Admin/AdminMenu";
import {
  getBoiteOutils,
  addBoiteOutils,
  deleteBoiteOutils,
} from "../actions/boiteaoutils.actions";
import { useTable, usePagination } from "react-table";

const AdminBoiteAOutils = ({ socket }) => {
  const userData = useSelector((state) => state.userReducer);
  const outilsData = useSelector((state) => state.boiteaoutilReducer);
  const [outilsName, setOutilsName] = useState("");
  const [outilsDescription, setOutilsDescription] = useState("");
  const [outilsImage, setOutilsImage] = useState("");
  const [outilsLink, setOutilsLink] = useState("");
  const [categorie, setOutilsCategorie] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      addBoiteOutils(
        outilsName,
        categorie,
        outilsDescription,
        outilsLink,
        outilsImage
      )
    ).then(() => dispatch(getBoiteOutils()))
      .then(() =>
        socket?.emit("sendNotificationAll", {
          senderName: userData.name,
        })
      );
    setOutilsName("");
    setOutilsDescription("");
  };

  const handleDelete = (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet outil ?")) {
      dispatch(deleteBoiteOutils(id))
        .then(() => {
          dispatch(getBoiteOutils());
        })
        .catch((error) => {
          console.log("Erreur lors de la suppression de l'outil :", error);
        });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nom de l'outil",
        accessor: "name",
        Cell: ({ cell }) => (
          <div className="toolNameCell">
            <img src={cell.row.original.image} alt="Outil" />
            <span>{cell.row.original.name}</span>
          </div>
        ),
      },
      {
        Header: "Catégorie de l'outil",
        accessor: "categorie",
      },
      {
        Header: "Description de l'outil",
        accessor: "description",
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ cell }) => (
          <div className="flexTable">
            <button
              onClick={() => handleDelete(cell.value)}
              className="deleteButton"
            >
              <i className="fa-regular fa-trash-can"></i>
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => {
    if (!Array.isArray(outilsData)) return [];
    return outilsData;
  }, [outilsData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination
  );

  const { pageIndex, pageSize } = state;

  useEffect(() => {
    dispatch(getBoiteOutils());
  }, [dispatch]);

  return (
    <>
      {userData.isAdmin ? (
        <>
          <Nav />
          <div className="mainContainer">
            <AdminMenu />
            <div className="centerBlock_admin">
              <h1>Ajouter/Editer un outil externe</h1>
              <div className="addOutil">
                <form onSubmit={handleSubmit}>
                  <div className="new_outil">
                    <i className="fa-solid fa-circle-plus"></i>
                    <label htmlFor="outilsName">Nom de l'outil:</label>
                    <input
                      type="text"
                      id="name"
                      value={outilsName}
                      onChange={(event) => setOutilsName(event.target.value)}
                    />
                    <label htmlFor="name">Description de l'outil:</label>
                    <input
                      type="text"
                      id="description"
                      value={outilsDescription}
                      onChange={(event) =>
                        setOutilsDescription(event.target.value)
                      }
                    />
                    <label htmlFor="categorie">Catégorie de l'outil:</label>
                    <select
                      id="categorie"
                      value={categorie}
                      onChange={(event) =>
                        setOutilsCategorie(event.target.value)
                      }
                    >
                      <option value="">-- Selectionner une catégorie --</option>
                      <option value="Design">Design</option>
                      <option value="SEO">SEO</option>
                      <option value="NDD">NDD</option>
                      <option value="Utilitaires">Utilitaires</option>
                    </select>

                    <label htmlFor="name">
                      Url de l'image de l'outil (Insérez directement l'URL de
                      l'image) :
                    </label>
                    <input
                      type="text"
                      id="image"
                      value={outilsImage}
                      onChange={(event) => setOutilsImage(event.target.value)}
                    />
                    <label htmlFor="name">Url outil:</label>
                    <input
                      type="text"
                      id="link"
                      value={outilsLink}
                      onChange={(event) => setOutilsLink(event.target.value)}
                    />
                  </div>
                  <button type="submit">Ajouter</button>
                </form>
              </div>

              <div className="allOutils">
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={i}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination">
                  <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    Précédent
                  </button>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    Suivant
                  </button>
                  <span>
                    Page{" "}
                    <strong>
                      {pageIndex + 1} sur {pageOptions.length}
                    </strong>{" "}
                  </span>
                  <span>
                    Aller à la page :{" "}
                    <input
                      type="number"
                      defaultValue={pageIndex + 1}
                      onChange={(e) => {
                        const pageNumber = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(pageNumber);
                      }}
                      style={{ width: "50px" }}
                    />
                  </span>{" "}
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[5, 10, 20].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Afficher {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default AdminBoiteAOutils;
