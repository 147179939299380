import React, { useState } from "react";
import Papa from "papaparse";
import _ from "lodash";

function OxatisConverter() {
  const [originalCsvData, setOriginalCsvData] = useState(null);
  const [newCsvData, setNewCsvData] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      encoding: "ISO-8859-1",
      complete: (results) => {
        setOriginalCsvData(results.data);
      },
    });
  };

  const transformCsvData = () => {
    const newCsvData = _(originalCsvData)
      .map((items) => {
        const category1Parts = items["Category 1"]
          ? items["Category 1"].split(" > ")
          : [];

        const firstWordCategory1 = category1Parts[0] || null;

        const category2Parts = items["Category 2"]
          ? items["Category 2"].split(" > ")
          : [];

        const firstWordCategory2 = category2Parts[0] || null;

        const areFirstWordsEqual = firstWordCategory1 === firstWordCategory2;

        let parentCategory1, subCategory1;

        if (areFirstWordsEqual) {
          // Utiliser la nouvelle logique si les premiers mots sont identiques
          parentCategory1 = firstWordCategory1; // Utiliser le premier mot de la catégorie 1
          subCategory1 = category2Parts.slice(1).join(" > ");
        } else {
          // Utiliser la logique initiale sinon
          parentCategory1 = items["Category 1"];
          subCategory1 = category1Parts[1] || null;
        }

        // Reste de votre code
        const parentCategory2 = category2Parts[0] || null;
        const subCategory2 = category2Parts[1] || null;

        const category3Parts = items["Category 3"]
          ? items["Category 3"].split(" > ")
          : [];

        const parentCategory3 = category3Parts[0] || null;
        const subCategory3 = category3Parts[1] || null;

        return {
          id: items ? items["ID produit"] : null,
          "Référence du produit": items["itemsku"],
          "Nom du produit": items["name"],
          "Description courte": items.description,
          "Description longue": items.longdescription,
          Poids: items["Weight"],
          "Nombre de produits en stock": items["quantityinstock"],
          "Photo 1": items["ZoomImg1"] ? items["ZoomImg1"] : items["Img Big"],
          "Photo 2": items["ZoomImg2"],
          "Photo 3": items["ZoomImg3"],
          "Photo 4": items["ZoomImg4"],
          "Photo 5": items["ZoomImg5"],
          "Photo 6": items["ZoomImg6"],
          "Photo 7": items["ZoomImg7"],
          "Photo 8": items["ZoomImg8"],
          "Photo 9": items["ZoomImg9"],
          "Photo 10": items["ZoomImg10"],
          "Photo 11": [""],
          "Photo 12": [""],
          "Photo 13": [""],
          "Photo 14": [""],
          "Photo 15": [""],
          Etat: ["Affiché"],

          "Nom Marque": items["Brand"] ? items["Brand"] : "sans marque",
          "Catégorie principale parente": parentCategory1,
          "Sous-catégorie principale": subCategory1,
          "Catégorie secondaire 1 parente": parentCategory2,
          "Sous-catégorie secondaire 1": subCategory2,
          "Catégorie secondaire 2 parente": parentCategory3,
          "Sous-catégorie secondaire 2": subCategory3,
          "Prix du produit (TTC hors remise)": items["Price Tax Included"],
          "Taux de tva": items["Tax Rate"],
        };
      })
      .value();

    const csvData = Papa.unparse(newCsvData, {
      delimiter: ";",
      encoding: "ISO-8859-1",
    });

    const blob = new Blob([csvData], { type: "text/csv;charset=ISO-8859-1;" });

    setNewCsvData(blob);
  };

  const handleDownload = () => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const csvData = event.target.result;
      const csvLines = csvData.split("\n");
      const filteredLines = csvLines.slice(0, -1);
      const updatedCsvData = filteredLines.join("\n");

      const blob = new Blob([updatedCsvData], {
        type: "text/csv;charset=ISO-8859-1;",
      });

      setNewCsvData(blob);

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "importWizi.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    reader.readAsText(newCsvData);
  };

  return (
    <>
      <div className="stepone">
        <h4>Transformer un fichier pour un WiziShop </h4>
      </div>
      <div className="steptwo">
        <input type="file" onChange={handleFileUpload} />
        <button className="upload_csv" onClick={transformCsvData}>
          Transformer
        </button>
      </div>
      {newCsvData && (
        <div className="stepthree">
          <h5>Etape 2 : Télécharger le fichier final</h5>
          <button onClick={handleDownload}>
            <i className="fa-solid fa-cloud-arrow-down"></i> Télécharger
          </button>
        </div>
      )}
    </>
  );
}

export default OxatisConverter;
