import React, { useState } from "react";
import Papa from "papaparse";
import _ from "lodash";

function Converter() {
  const [originalCsvData, setOriginalCsvData] = useState(null);
  const [newCsvData, setNewCsvData] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        setOriginalCsvData(results.data);
      },
    });
  };

  const transformCsvData = () => {
    const newCsvData = _(originalCsvData)
      .groupBy("Nom Offre")
      .map((items, NomOffre) => {
        const url = items[0]["Media"];
        const imageName = url ? url.split("/").pop() : "";
        const urlRedirect = items[0]["URL"];
        const urlRedirectUrl = urlRedirect
          ? urlRedirect.split("/").slice(3).join("/")
          : "";
        return {
          image: imageName,
          Catégorie: items[0]["Catégorie"],
          "Nom du produit": NomOffre,
          Description: items[0].Description,
          Reference: items[0].SKU,
          "TVA du produit": items[0]["Taxes applicable"],
          Variations:
            items.length > 1
              ? _.map(
                  items,
                  (item) => `${item["Stock produit"]} ${item.SKU}`
                ).join(", ")
              : "",
          Prix: items[0].Prix,
          "Stock total": items[0]["Stock produit"],
          "Poids (en Kg)": items[0]["Poids (Kg/lbs)"],
          URL: urlRedirectUrl,
        };
      })
      .value();

    const csvData = Papa.unparse(newCsvData, { delimiter: ";" });

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    setNewCsvData(blob);
  };

  const handleDownload = () => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const csvData = event.target.result;
      const csvLines = csvData.split("\n");
      const filteredLines = csvLines.slice(0, -1);
      const updatedCsvData = filteredLines.join("\n");

      const blob = new Blob([updatedCsvData], {
        type: "text/csv;charset=utf-8;",
      });

      setNewCsvData(blob);

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "importLocalBoutique.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    reader.readAsText(newCsvData);
  };

  return (
    <>
      <div className="stepone">
        <h4>Transformer un fichier pour un localBoutique </h4>
      </div>
      <div className="steptwo">
        <input type="file" onChange={handleFileUpload} />
        <button className="upload_csv" onClick={transformCsvData}>
          Transformer
        </button>
      </div>
      {newCsvData && (
        <div className="stepthree">
          <h5>Etape 2 : Télécharger le fichier final</h5>
          <button onClick={handleDownload}>
            <i className="fa-solid fa-cloud-arrow-down"></i> Télécharger
          </button>
        </div>
      )}
    </>
  );
}

export default Converter;
