import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useTable, usePagination } from "react-table";
import QuizzIcone from "../../asset/quizz-icone.png";
import { getQuiz } from "../../actions/quizz.actions";
import { useDispatch, useSelector } from "react-redux";

const UserQuizz = ({ usersData }) => {
  const [quizResults, setQuizResults] = useState([]);
  const [selectedQuizDetails, setSelectedQuizDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const quizzData = useSelector((state) => state.quizFormReducer.quizzes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQuiz());
  }, [dispatch]);

  useEffect(() => {
    const fetchQuizResults = async () => {
      try {
        const response = await axios.get(
          "/api/quizz/get-all-quiz-results/" + usersData
        );
        setQuizResults(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchQuizResults();
  }, [usersData]);

  const allQuizzes = useMemo(() => {
    return quizzData.map((quiz) => ({
      ...quiz,
      hasParticipated: quizResults.some(
        (result) => result?.Quizz?.id === quiz?.id
      ),
    }));
  }, [quizzData, quizResults]);

  const columns = useMemo(
    () => [
      {
        Header: "Nom du quizz",
        accessor: "Quizz",
        Cell: ({ row }) => (
          <div className="flex">
            <img src={QuizzIcone} className="icone" alt="Quizz icone" />
            <span>{row?.original?.nom}</span>
          </div>
        ),
      },
      {
        Header: "Participation",
        accessor: "hasParticipated",
        Cell: ({ value }) => {
          return (
            <>
              {value ? (
                <span className="success-span">A participé au quizz</span>
              ) : (
                <span className="error-span">N'a pas participé au quizz</span>
              )}
            </>
          );
        },
      },
      {
        Header: "Résultat",
        accessor: "score",
        Cell: ({ row }) => {
          return (
            <>
              {row?.original.hasParticipated ? (
                <>
                  <button
                    className="blackButton"
                    onClick={() => {
                      setSelectedQuizDetails(row?.original);
                      setIsModalOpen(true);
                    }}
                  >
                    Détails
                  </button>
                </>
              ) : (
                <button className="blackButton" style={{ cursor: "no-drop" }}>
                  ⛔ Détails
                </button>
              )}
            </>
          );
        },
      },
    ],
    [usersData]
  );

  const data = useMemo(() => {
    if (!Array.isArray(allQuizzes)) return [];
    return allQuizzes;
  }, [allQuizzes]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );

  return (
    <>
      <div className="posthead">
        <div className="post_head_competences">
          <h4>Mes Quizz</h4>
          <table {...getTableProps()} className="quiz-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              Précédent
            </button>{" "}
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} sur {pageOptions.length}
              </strong>{" "}
            </span>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              Suivant
            </button>{" "}
          </div>
        </div>
      </div>
      {isModalOpen && selectedQuizDetails && (
        <div className="modal">
          <div className="modal-content">
            <h2>Détails du Quizz</h2>
            <br />
            <p>
              {" "}
              <b>Nom du quizz:</b> {selectedQuizDetails?.nom}
            </p>
            <br />
            {quizResults
              .filter((result) => {
                return result?.Quizz?.id === selectedQuizDetails?.id;
              })
              .map((details, index) => (
                <React.Fragment key={index}>
                  <p>
                    <b>Score:</b> {details?.score} /{" "}
                    {details?.selectedAnswers?.length}
                  </p>
                  <br />
                  <h3>Réponses</h3>
                  <br />
                  {selectedQuizDetails?.QuizzQuestions?.map(
                    (question, index) => (
                      <div key={index}>
                        <p>
                          <b>
                            {" "}
                            Question {index + 1}: {question?.question}{" "}
                          </b>
                        </p>
                        <p>
                          Réponse à la question:{" "}
                          {details?.selectedAnswers?.[index] ||
                            "Pas de réponse"}
                        </p>
                        <br />
                        <p>
                          La bonne réponse est :{" "}
                          <span
                            style={{ color: "#176638", fontStyle: "italic" }}
                          >
                            {question?.QuizzReponses?.find(
                              (response) => response.estBonneReponse
                            )?.reponse || "Pas de réponse"}
                          </span>
                        </p>
                        <br />
                      </div>
                    )
                  )}
                  <br />
                </React.Fragment>
              ))}
            <br />
            <br />
            <button onClick={() => setIsModalOpen(false)}>Fermer</button>
          </div>
        </div>
      )}
    </>
  );
};

export default UserQuizz;
