import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nav from "../Components/Nav/Nav";
import { UidContext } from "../Components/App.Context";
import { Link, useParams } from "react-router-dom";
import Login from "./Login";
import knowledgeBg from "../asset/knowledge-bg.png";
import { editeKnowledge, getKnwoledge } from "../actions/knowledge.actions";
import Menu from "../Components/Nav/Menu";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  addKnwoledgeFav,
  getKnwoledgeFav,
  removeKnowledgeFav,
} from "../actions/knowledgeFav.actions";
import CardCommentsTuto from "../Components/Knowledge/CardCommentTuto";
import { getComments } from "../actions/commentTuto.actions";

const KnowledgeInfo = ({ socket }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);
  const [knowledgeUpdate, setknowledgeUpdate] = useState(null);
  const [isAllow, setIsAllow] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [showComments, setShowComments] = useState(false);

  const commentsData = useSelector((state) => state.commentTutoReducer);
  const knowledgeFav = useSelector((state) => state.knowledgeFav);
  const userData = useSelector((state) => state.userReducer);
  const knowledge = useSelector((state) => state.knowledgeReducer);
  const uid = useContext(UidContext);
  const { id } = useParams();
  const dispatch = useDispatch();

  const thisKnowledge = Object.values(knowledge).filter((post) => {
    return post.id + "" === id;
  });

  const [knowledgeTitle, setKnowledgeTitle] = useState(
    thisKnowledge[0]?.title || null
  );

  useEffect(() => {
    dispatch(getKnwoledge());
    dispatch(getKnwoledgeFav(id));
    dispatch(getComments());
    setIsLoading(false);
  }, [dispatch, id]);

  const updateKnowledge = () => {
    if (knowledgeUpdate || knowledgeTitle) {
      dispatch(editeKnowledge(id, knowledgeUpdate, knowledgeTitle)).then(() =>
        dispatch(getKnwoledge())
      );
    }
    setIsUpdated(false);
  };

  useEffect(() => {
    if (
      (uid === userData.id && userData.isAdmin) ||
      (thisKnowledge[0]?.usersId === userData.id && userData.isReferent)
    ) {
      setIsAllow(true);
    }
  }, [uid, userData.id, userData.isAdmin, userData.isReferent]);

  useEffect(() => {
    const myFavorite = Object.values(knowledgeFav).find(
      (fav) =>
        fav.userId === userData.id && fav.knowledgeId === thisKnowledge[0]?.id
    );
    myFavorite && setIsFavorite(true);
  }, [uid, knowledgeFav, userData.id]);

  const handleAddFavorite = (e) => {
    e.preventDefault();
    dispatch(
      (isFavorite ? removeKnowledgeFav : addKnwoledgeFav)(
        userData?.id,
        thisKnowledge[0]?.id
      )
    )
      .then(() => dispatch(getKnwoledgeFav(userData.id)))
      .then(() => setIsFavorite(false));
  };

  const numbComm = Object.keys(commentsData).reduce((count, id) => {
    if (commentsData[id].postId === thisKnowledge[0]?.id) {
      return count + 1;
    } else {
      return count;
    }
  }, 0);

  const authorName = thisKnowledge[0]?.user?.id;

  if (isLoading) {
    return <div>Chargement en cours...</div>;
  }
  return (
    <>
      {uid ? (
        <>
          <Nav socket={socket} />
          <div className="mainContainer">
            <Menu />
            <div className="center-Block">
              <div className="knowledge-container">
                <img src={knowledgeBg} alt="" />
                <div className="texte">
                  <h1>Tutoriel</h1>
                  <p>{thisKnowledge[0]?.title}</p>
                </div>
              </div>
              <div className="knowledgerInfo-card">
                <div className="knwoledge-menu">
                  <div className="new-knowledge">
                    <button className="back-knowlegde">
                      <Link to={"/tutoriels"}>
                        <p> Retour </p>
                      </Link>
                    </button>

                    <button
                      className="favorite-info-button"
                      onClick={handleAddFavorite}
                    >
                      <i
                        className={isFavorite ? "fas fa-star" : "far fa-star"}
                      ></i>
                    </button>

                    {isAllow ? (
                      <button
                        className="back-knowlegde"
                        onClick={() => setIsUpdated(!isUpdated)}
                      >
                        Editer
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
              {isUpdated === false && (
                <div className="knowledge-container-more">
                  <h3>{thisKnowledge[0]?.title}</h3>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: thisKnowledge[0]?.texte,
                      }}
                    ></span>
                  </span>
                  <div className="carFooter">
                    <div
                      className="cardfooter-comment"
                      onClick={() => setShowComments(!showComments)}
                    >
                      <i className="far fa-comment-alt"></i>
                      <span>
                        {numbComm}{" "}
                        {numbComm > 1 ? "commentaires" : "commentaire"}
                      </span>
                    </div>
                  </div>
                  {showComments && (
                    <CardCommentsTuto
                      post={thisKnowledge[0]}
                      socket={socket}
                      authorName={authorName}
                    />
                  )}
                </div>
              )}
              {isUpdated && (
                <div className="knowledge-container-more">
                  <p>Titre : </p>
                  <input
                    type="text"
                    value={knowledgeTitle || thisKnowledge[0]?.title}
                    onChange={(e) => {
                      setKnowledgeTitle(e.target.value);
                    }}
                  />
                  <br />
                  <p>Contenu :</p>
                  <CKEditor
                    editor={Editor}
                    data={thisKnowledge[0].texte}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setknowledgeUpdate(data);
                    }}
                  />
                  <div className="button-container">
                    <button className="btn" onClick={updateKnowledge}>
                      Modifier le Tutoriel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default KnowledgeInfo;
