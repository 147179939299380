import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTable, usePagination } from "react-table";
import Nav from "../Components/Nav/Nav";
import Login from "./Login";
import "react-toastify/dist/ReactToastify.css";
import { DateParser } from "../Components/utils/Utils";
import DeleteRequest from "../Components/Formation/DeleteRequest";
import FixedPlanning from "../Components/Formation/FixedPlanning";
import AdminMenu from "../Components/Admin/AdminMenu";
import ImageProfil from "../Components/Profil/ImageProfil";
import AsignFormation from "../Components/Formation/AsignFormation";
import AsignFormRequest from "../Components/Formation/AsignFormRequest";

const AdminFormatonRequest = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalValidation, setShowModalValidation] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("0");

  const userData = useSelector((state) => state.userReducer);
  const requestData = useSelector((state) => state.requestFormationReducer);

  const requestFormations = Object.values(requestData).filter(
    (formation) =>
      formation.formationStatus === "0" || formation.formationStatus === "10"
  );

  const filterOptions = [
    { label: "Toutes les formations", value: "" },
    { label: "Formation à planifier", value: "0" },
    { label: "Formation à valider", value: "10" },
    { label: "Formation terminée", value: "2" },
  ];

  const handleChangeFilter = (e) => {
    setSelectedFilter(e.target.value);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Utilisateur à former",
        accessor: "user",
        Cell: ({ row }) => (
          <div className="flex">
            <ImageProfil id={row?.original?.user?.id} />
            <span>
              {row.original?.user?.firstname} {row?.original?.user?.name}
            </span>
          </div>
        ),
      },
      {
        Header: "Formation à prévoir",
        accessor: "formation",
        Cell: ({ row }) => (
          <span className="badge">
            {" "}
            {row?.original?.formation?.Formation?.formationName} -{" "}
            {row?.original?.formation?.Formation?.formationLvl}{" "}
          </span>
        ),
      },
      {
        Header: "Date souhaitée",
        accessor: "formationDate",
        Cell: ({ row }) => {
          const requestedDate = new Date(row.original?.formationDate);
          const currentDate = new Date();
          const isDateInPast =
            requestedDate < currentDate &&
            row.original.formation.formationStatus === "0";
          const date = isDateInPast
            ? `⚠️ ${DateParser(row?.original?.formationDate)}`
            : DateParser(row?.original?.formationDate);

          return (
            <span style={{ color: isDateInPast ? "red" : "inherit" }}>
              {date}
            </span>
          );
        },
      },
      {
        Header: "Gérer la demande",
        accessor: "gerer",
        Cell: ({ row }) => (
          <div className="flexTable">
            {row.original.formation.formationStatus === "0" ? (
              <button
                className="blackButton"
                onClick={() => {
                  setSelectedRequest(row.original.formation);
                  setShowModal(true);
                }}
              >
                Planifier la formation
              </button>
            ) : null}
            {row.original.formation.formationStatus === "10" ? (
              <button
                className="blockedButton"
                onClick={() => {
                  setSelectedRequest(row.original.formation);
                  setShowModal(true);
                }}
              >
                🗓️ Déjà Planifiée
              </button>
            ) : null}
            {row.original.formation.formationStatus === "10" ? (
              <button
                className="greenButton"
                onClick={() => {
                  setSelectedRequest(row.original.formation);
                  setShowModalValidation(true);
                }}
              >
                ⌛ Valider la formation
              </button>
            ) : null}
            {row.original.formation.formationStatus === "2" ? (
              <button className="blockedButton">✅ Terminée</button>
            ) : null}
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <div className="flexSolo">
            <DeleteRequest formationsId={row?.original?.action?.id} />
          </div>
        ),
      },
    ],
    []
  );

  const filteredData = useMemo(() => {
    if (!Array.isArray(requestData)) return [];
    return requestData
      .filter((request) => {
        if (selectedFilter === "") return true;
        return request.formationStatus === selectedFilter;
      })
      .map((request) => ({
        user: request.user,
        formation: request,
        formationDate: request.formationDate,
        gerer: request,
        action: request,
      }));
  }, [requestData, selectedFilter]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );

  const { pageIndex, pageSize } = state;

  const closeModal = () => {
    setShowModal(false);
  };

  const closeModalValidation = () => {
    setShowModalValidation(false);
  };

  return (
    <>
      {userData.isAdmin ? (
        <>
          <Nav />
          <div className="mainContainer">
            <AdminMenu />
            <div className="centerBlock_admin">
              <div className="useronline-container-admin">
                <div className="requestformation">
                  <>
                    <h3>Bac à sable des demandes de formation</h3>
                    <div className="filterContainer">
                      <label htmlFor="statusFilter">
                        Filtrer par statut :{" "}
                      </label>
                      <select
                        id="statusFilter"
                        value={selectedFilter}
                        onChange={handleChangeFilter}
                      >
                        {filterOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    {requestFormations.length === 0 ? (
                      <div className="nullformation">
                        <p> 😴 Aucune demande pour le moment </p>
                      </div>
                    ) : (
                      <div className="tableFormation">
                        <table
                          {...getTableProps()}
                          className="requestformation_table"
                        >
                          <thead>
                            {headerGroups.map((headerGroup) => (
                              <tr
                                {...headerGroup.getHeaderGroupProps()}
                                key={headerGroup.id}
                              >
                                {headerGroup.headers.map((column) => (
                                  <th
                                    {...column.getHeaderProps()}
                                    key={column.id}
                                  >
                                    {column.render("Header")}
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>
                          <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                              prepareRow(row);
                              return (
                                <tr {...row.getRowProps()} key={row.id}>
                                  {row.cells.map((cell) => (
                                    <td
                                      {...cell.getCellProps()}
                                      key={cell.column.id}
                                    >
                                      {cell.render("Cell")}
                                    </td>
                                  ))}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <div className="pagination">
                          <button
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                          >
                            Précédent
                          </button>
                          <button
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                          >
                            Suivant
                          </button>
                          <span>
                            Page{" "}
                            <strong>
                              {pageIndex + 1} sur {pageOptions.length}
                            </strong>{" "}
                          </span>
                          <span>
                            Aller à la page :{" "}
                            <input
                              type="number"
                              defaultValue={pageIndex + 1}
                              onChange={(e) => {
                                const pageNumber = e.target.value
                                  ? Number(e.target.value) - 1
                                  : 0;
                                gotoPage(pageNumber);
                              }}
                              style={{ width: "50px" }}
                            />
                          </span>{" "}
                          <select
                            value={pageSize}
                            onChange={(e) => {
                              setPageSize(Number(e.target.value));
                            }}
                          >
                            {[5, 10, 20].map((pageSize) => (
                              <option key={pageSize} value={pageSize}>
                                Afficher {pageSize}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                  </>
                </div>
                <FixedPlanning request={filteredData} />
              </div>
              {showModal && (
                <div className="modal">
                  <div className="modal-content">
                    <h2>Assigner cette formation à un Formateur </h2>
                    <AsignFormation
                      request={selectedRequest}
                      closeModal={closeModal}
                    />
                    <div className="modal-buttons">
                      <button type="button" onClick={closeModal}>
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {showModalValidation && (
                <div className="modal">
                  <div className="modal-content formationTab">
                    <AsignFormRequest
                      request={selectedRequest}
                      closeModalValidation={closeModalValidation}
                    />
                    <div className="modal-buttons">
                      <button
                        className="close"
                        type="button"
                        onClick={closeModalValidation}
                      >
                        <i className="fa-regular fa-circle-xmark"></i>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default AdminFormatonRequest;
