import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nav from "../Components/Nav/Nav";
import Login from "./Login";
import { useParams } from "react-router-dom";
import { getFormations } from "../actions/formations.actions";
import CompetenceAssign from "../Components/Formation/CompetenceAssign";
import { isEmpty } from "../Components/utils/Utils";
import DeleteUserFormation from "../Components/Formation/DeleteUserFormation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminMenu from "../Components/Admin/AdminMenu";
import { useTable, usePagination } from "react-table";
import ProgressBar from "@ramonak/react-progress-bar";
import MiniLogo from "../Components/Logo/MiniLogo";

const FormationAssign = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [numOfFormations, setNumOfFormations] = useState(6);
  const userData = useSelector((state) => state.userReducer);
  const usersData = useSelector((state) => state.usersReducer);
  const { id } = useParams();
  const dispatch = useDispatch();
  const usersFormationsData = useSelector(
    (state) => state.usersformationsReducer
  );

  const users = Object.values(usersData).filter((users) => {
    return users.id + "" === id;
  });

  useEffect(() => {
    dispatch(getFormations());
    !isEmpty(userData) && setIsLoading(false);
  }, [dispatch, id, userData]);

  const columns = useMemo(
    () => [
      {
        Header: "Formation",
        accessor: "Formation.formationName",
        Cell: ({ cell }) => (
          <>
            <div className="formation_head_label">
              <MiniLogo />
              <div className="formateur">
                <p className="title">{cell?.value}</p>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Niveau de la formation",
        accessor: "Formation.formationLvl",
      },
      {
        Header: "Assimilation",
        accessor: "formationPercent",
        Cell: ({ cell }) => (
          <div className="progress-bar-wrapper">
            <span className="progress-label">{`${cell?.value}%`}</span>
            <ProgressBar
              completed={cell?.value}
              bgColor={
                cell?.value >= 80
                  ? "#2ecc71"
                  : cell?.value >= 20
                  ? "#f39c12"
                  : "#e74c3c"
              }
              isLabelVisible={false}
              labelColor="#000000"
              height="10px"
              animateOnRender={true}
              labelAlignment="center"
              labelSize="0px"
            />
          </div>
        ),
      },
      {
        Header: "Formateur",
        accessor: (row) => row?.formationFormateur,
        Cell: ({ cell }) => (
          <div className="formation_date">
            <span>Formateur :</span>
            {cell?.value?.split(",").map((formateur, index) => (
              <React.Fragment key={index}>
                <p>{formateur}</p>
              </React.Fragment>
            ))}
          </div>
        ),
      },

      {
        Header: "Date de la formation",
        accessor: (row) => {
          return row?.formationDate?.split(",");
        },
        Cell: ({ cell }) => (
          <div className="formation_date">
            <span>Date :</span>
            {cell?.value?.map((date, index) => (
              <React.Fragment key={index}>
                <p>{date}</p>
              </React.Fragment>
            ))}
          </div>
        ),
      },
      {
        Header: "Action",
        Cell: ({ row }) => <DeleteUserFormation formations={row.original} />,
      },
    ],
    []
  );

  const data = useMemo(() => {
    if (!usersFormationsData) return [];

    return Object.values(usersFormationsData);
  }, [usersFormationsData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 3 },
    },
    usePagination
  );

  const { pageIndex } = state;

  return (
    <>
      {userData.isAdmin ? (
        <>
          <ToastContainer />
          <Nav />
          <div className="mainContainer">
            <AdminMenu />
            <div className="centerBlock_admin">
              <div className="useronline-container-admin">
                <div className="formation_create">
                  <p className="title_form">
                    Les formations acquises de : {users[0].name}{" "}
                    {users[0].firstname}
                  </p>
                  {isLoading ? (
                    <div className="chargement">
                      <i className="fas fa-spinner fa-spin"></i>
                      <span>CHARGEMENT</span>
                    </div>
                  ) : (
                    <div className="formation_aquise">
                      <table {...getTableProps()} className="formation_table">
                        <thead>
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                  {column.render("Header")}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {page.map((row, i) => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()} key={i}>
                                {row.cells.map((cell) => (
                                  <td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                ))}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="pagination">
                        <span>
                          Afficher par :{" "}
                          <select
                            value={numOfFormations}
                            onChange={(e) =>
                              setNumOfFormations(Number(e.target.value))
                            }
                          >
                            {[3, 6, 9].map((pageSize) => (
                              <option key={pageSize} value={pageSize}>
                                {pageSize}
                              </option>
                            ))}
                          </select>
                        </span>{" "}
                        <span>
                          Page <strong>{pageIndex + 1}</strong> sur{" "}
                          {pageOptions.length}
                        </span>
                        <button
                          onClick={() => previousPage()}
                          disabled={!canPreviousPage}
                        >
                          Précédent
                        </button>
                        <button
                          onClick={() => nextPage()}
                          disabled={!canNextPage}
                        >
                          Suivant
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="formation_create">
                  <p className="title_form">
                    Assigner une formation à l'utilisateur : {users[0].name}{" "}
                    {users[0].firstname}
                  </p>
                  <CompetenceAssign />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default FormationAssign;
