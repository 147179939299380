import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createFormation,
  getFormations,
} from "../../actions/formations.actions";
import { toast, ToastContainer } from "react-toastify";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Select from "react-select";

const CreateFormation = () => {
  const [formationName, setformationName] = useState("");
  const [formationLvl, setformationLvl] = useState("");
  const [formationDescription, setformationDescription] = useState("");
  const [formationHours, setformationHours] = useState("");
  const [referents, setReferents] = useState([]);

  const usersData = useSelector((state) => state.usersReducer);

  const dispatch = useDispatch();

  const handleCreateForm = (e) => {
    e.preventDefault();
    if (
      formationName &&
      formationLvl &&
      formationHours &&
      formationDescription
    ) {
      const formationStars = referents.map((referent) => referent.value);

      dispatch(
        createFormation(
          formationName,
          formationLvl,
          formationDescription,
          formationHours,
          formationStars
        ),
        toast.success(`La formation est créée`)
      ).then(() => dispatch(getFormations()));
    }
  };

  return (
    <>
      <ToastContainer />
      <form action="" onSubmit={handleCreateForm}>
        <label htmlFor="formationName">Nom de la formation</label>
        <input
          type="text"
          name="formationName"
          className="input_signup"
          id="formationName"
          placeholder="Exemple: Transfert"
          onChange={(e) => setformationName(e.target.value)}
          value={formationName}
          required
        />
        <br />
        <label htmlFor="formationLvl">Niveau de la formation</label>
        <input
          type="text"
          name="formationLvl"
          className="input_signup"
          id="formationLvl"
          placeholder="Exemple : Niveau 1 ou Niveau 2/4"
          onChange={(e) => setformationLvl(e.target.value)}
          value={formationLvl}
          required
        />
        <br />
        <label htmlFor="formationLvl">Description de la formation</label>
        <div className="formationDescription">
          <CKEditor
            editor={Editor}
            data={" "}
            onChange={(event, editor) => {
              const data = editor.getData();
              setformationDescription(data);
            }}
          />
        </div>
        <br />
        <label htmlFor="formationHours">Durée de formation</label>
        <input
          type="time"
          name="formationHours"
          className="input_signup"
          id="formationHours"
          onChange={(e) => setformationHours(e.target.value)}
          value={formationHours}
          required
        />
        <br />
        <label htmlFor="formationUser">
          Utilisateur(s) Stars pour cette formation :
        </label>
        <Select
          options={usersData.map((user) => ({
            label: user.firstname + " - " + user.name,
            value: user.id,
          }))}
          isMulti
          onChange={
            (selectedUsers) => setReferents(selectedUsers) // Stocker les référents sélectionnés
          }
        />
        <br />
        <input type="submit" className="submit" value="Créer une formation" />
      </form>
    </>
  );
};

export default CreateFormation;
