import axios from "axios";

export const GET_FORMATIONS = "GET_FORMATIONS";
export const CREAT_FORMATIONS = "CREAT_FORMATIONS";
export const DELETE_FORMATION = "DELETE_FORMATION";
export const EDIT_FORMATION = "EDIT_FORMATION";

export const getFormations = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/formation/all/`,
      {
        withCredentials: true,
      }
    );
    dispatch({ type: GET_FORMATIONS, payload: response.data });
  } catch (error) {
    console.error(error);
  }
};

export const createFormation = (
  formationName,
  formationLvl,
  formationDescription,
  formationHours,
  formationStars
) => {
  return async (dispatch) => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/formation/`,
        withCredentials: true,
        data: {
          formationName: formationName,
          formationLvl: formationLvl,
          formationDescription: formationDescription,
          formationHours: formationHours,
          formationStars: formationStars.join(","),
        },
      });

      dispatch({
        type: CREAT_FORMATIONS,
        payload: {
          formationName,
          formationLvl,
          formationDescription,
          formationHours,
          formationStars,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteFormation = (formationsId) => {
  return (dispatch) => {
    return axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/api/formation/${formationsId}`,
      withCredentials: true,
    })
      .then(() => {
        dispatch({ type: DELETE_FORMATION, payload: { formationsId } });
      })
      .catch((error) => console.error(error));
  };
};

export const editFormation = (
  formationsId,
  formationName,
  formationLvl,
  formationDescription,
  formationHours,
  formationStars
) => {
  return (dispatch) => {
    return axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/api/formation/${formationsId}`,
      withCredentials: true,
      data: {
        formationName: formationName,
        formationLvl: formationLvl,
        formationDescription: formationDescription,
        formationHours: formationHours,
        formationStars: formationStars.join(","),
      },
    })
      .then((res) => {
        dispatch({
          type: EDIT_FORMATION,
          payload: {
            formationsId,
            formationName,
            formationLvl,
            formationDescription,
            formationHours,
            formationStars,
          },
        });
      })
      .catch((error) => console.error(error));
  };
};
