import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable, usePagination } from "react-table";
import Nav from "../Components/Nav/Nav";
import Login from "./Login";
import { getFormations } from "../actions/formations.actions";
import CreateFormation from "../Components/Formation/CreateFormation";
import DeleteFormation from "../Components/Formation/DeleteFormation";
import EditFormation from "../Components/Formation/EditFormation";
import AdminMenu from "../Components/Admin/AdminMenu";
import parse from "html-react-parser";

const Formation = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const userData = useSelector((state) => state.userReducer);
  const formationsData = useSelector((state) => state.formationsReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFormations());
  }, [dispatch]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredFormations = useMemo(() => {
    return formationsData.filter((formation) => {
      return formation.formationName
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });
  }, [formationsData, searchTerm]);

  const columns = useMemo(
    () => [
      {
        Header: "Nom de la formation",
        accessor: "formationName",
      },
      {
        Header: "Niveau de la formation",
        accessor: "formationLvl",
        Cell: ({ cell }) => <span className="badge">{cell.value}</span>,
      },
      {
        Header: "Contenu de la formation",
        accessor: "formationDescription",
        Cell: ({ cell }) => parse(cell.value),
      },
      {
        Header: "Durée de la formation",
        accessor: "formationHours",
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="progress_formation">
            <DeleteFormation formations={row.original} />
            <EditFormation formations={row.original} />
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data: filteredFormations,
      initialState: { pageIndex: 0, pageSize: itemsPerPage },
    },
    usePagination
  );

  const { pageIndex, pageSize } = state;

  return (
    <>
      {userData.isAdmin ? (
        <>
          <Nav />
          <div className="mainContainer">
            <AdminMenu />
            <div className="centerBlock_admin">
              <div className="useronline-container-admin">
                <div className="formation_create">
                  <h3>Créer une nouvelle formation : </h3>
                  <CreateFormation />
                </div>
                <div className="searchFormation">
                  <div className="search-icon-container">
                    <i className="fa-solid fa-magnifying-glass search-icon"></i>
                  </div>
                  <input
                    type="text"
                    placeholder="Rechercher une formation"
                    value={searchTerm}
                    onChange={handleChange}
                  />
                </div>
                <div className="formation">
                  <table {...getTableProps()} className="formation_table">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} key={i}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="pagination">
                  <span>
                    Afficher par :{" "}
                    <select
                      value={itemsPerPage}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {[5, 10, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                  </span>{" "}
                  <span>
                    Page{" "}
                    <strong>
                      {pageIndex + 1} sur {pageOptions.length}
                    </strong>{" "}
                  </span>
                  <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    Précédent
                  </button>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    Suivant
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default Formation;
