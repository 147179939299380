import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "moment/locale/fr";

const FixedPlanning = ({ request }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  moment.locale("fr");
  const localizer = momentLocalizer(moment);

  const messages = {
    allDay: "Toute la journée",
    previous: "Précédent",
    next: "Suivant",
    today: "Aujourd'hui",
    month: "Mois",
    week: "Semaine",
    day: "Jour",
    agenda: "Agenda",
    date: "Date",
    time: "Heure",
    event: "Événement",
    noEventsInRange: "Aucun événement dans la plage",
    showMore: (count) => `+ Voir plus (${count})`,
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
  };

  const requestWait = Object.values(request).filter(
    (enCours) => enCours.formation.formationStatus === "10"
  );

  const requestFormations = requestWait;

  return (
    <div className="planning-content">
      <div className="calendar-formateur">
        <h3>Calendrier des formateurs</h3>
        <br />
        <Calendar
          messages={messages}
          localizer={localizer}
          onSelectEvent={handleSelectEvent}
          defaultView="week"
          views={["week"]}
          events={requestFormations.map((request) => {
            const startMoment = moment(request?.formation.formationDate);
            const duration = moment.duration(request?.formation.formationHours);
            const endMoment = startMoment.clone().add(duration).add(1, "hour");

            return {
              title: `${request?.formation.formationFormateur}`,
              desc: `${request?.formation.formationFormateur} a une formation ${request?.formation.Formation?.formationName} - ${request?.formation.Formation?.formationLvl} avec ${request?.user?.firstname} ${request?.user?.name}`,
              start: startMoment.toDate(),
              end: endMoment.toDate(),
            };
          })}
          min={new Date(0, 0, 0, 8, 0)}
          max={new Date(0, 0, 0, 18, 0)}
        />
        {selectedEvent && (
          <div className="calendar-event">
            <h3>{selectedEvent?.title}</h3>
            <p>{selectedEvent?.desc}</p>
            <button onClick={() => setSelectedEvent(null)}>Fermer</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FixedPlanning;
