import { GET_TOOL, EDIT_TOOL } from "../actions/outils.actions";

const initialState = {};

export default function outilsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TOOL:
      return action.payload;
    case EDIT_TOOL:
      const { outilId, name, status } = action.payload;
      return {
        ...state,
        [outilId]: {
          ...state[outilId],
          name,
          status,
        },
      };
    default:
      return state;
  }
}
