import {
  GET_NOTIFICATIONS,
  READ_NOTIFICATIONS,
} from "../actions/notifications.actions";

const initialState = {};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return action.payload;
    case READ_NOTIFICATIONS:
      const updatedState = Object.assign({}, state);
      delete updatedState[action.payload];
      return updatedState;
    default:
      return state;
  }
}
