import {
  CREATE_QUIZZ,
  GET_QUIZZ,
  DELETE_QUIZZ,
} from "../actions/quizz.actions";

const initialState = {
  createdQuiz: null,
  quizzes: [],
};

export default function quizFormReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_QUIZZ:
      return {
        ...state,
        createdQuiz: action.payload,
      };
    case GET_QUIZZ:
      return {
        ...state,
        quizzes: action.payload,
      };
    case DELETE_QUIZZ:
      const updatedQuizzes = state.quizzes.filter(
        (quiz) => quiz.id !== action.payload
      );
      return {
        ...state,
        quizzes: updatedQuizzes,
      };
    default:
      return state;
  }
}
