import React, { useState, useEffect } from "react";
import Routes from "./Components/Roots";
import { UidContext } from "./Components/App.Context";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getUser } from "./actions/user.actions";
import Loading from "./Pages/Loading";
import { io } from "socket.io-client";
import Scroltop from "./Components/Nav/scrolTop";

function App() {
  const [uid, setUid] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetchUser = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user/jwt`,
          { withCredentials: true }
        );
        setUid(res.data.userId);
        setSocket(io(`${process.env.REACT_APP_API_URL}`));
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    fetchUser();

    return () => {
      socket?.disconnect();
    };
  }, []);

  useEffect(() => {
    if (uid) {
      dispatch(getUser(uid));
    }
  }, [uid, dispatch]);

  return (
    <UidContext.Provider value={uid}>
      {loading ? <Loading /> : <Routes socket={socket} />}
      <Scroltop />
    </UidContext.Provider>
  );
}

export default App;
