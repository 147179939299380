import axios from "axios";

export const GET_QUICONTACTER = "GET_QUICONTACTER";
export const ADD_QUICONTACTER = "ADD_QUICONTACTER";
export const DELETE_QUICONTACTER = "DELETE_QUICONTACTER";
export const EDIT_QUICONTACTER = "EDIT_QUICONTACTER";

export const getQuiContacter = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/quicontacter/quicontacter`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: GET_QUICONTACTER,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const createQuiContacter = (categorie, sujet, resolution) => {
  return (dispatch) => {
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/quicontacter/`,
      withCredentials: true,
      data: {
        categorie: categorie,
        sujet: sujet,
        resolution: resolution,
      },
    })
      .then((res) => {
        dispatch({
          type: ADD_QUICONTACTER,
          payload: {
            categorie: categorie,
            sujet: sujet,
            resolution: resolution,
          },
        });
      })
      .catch((error) => console.error(error));
  };
};

export const editeQuiContacter = (EditId, categorie, sujet, resolution) => {
  return (dispatch) => {
    return axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/api/quicontacter/quicontacter/${EditId}`,
      withCredentials: true,
      data: {
        categorie: categorie,
        sujet: sujet,
        resolution: resolution,
      },
    })
      .then((res) => {
        dispatch({
          type: EDIT_QUICONTACTER,
          payload: { categorie, sujet, resolution },
        });
      })
      .catch((error) => console.error(error));
  };
};

export const deleteQuiContacter = (id) => {
  return (dispatch) => {
    return axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/api/quicontacter/quicontacter/${id}`,
      withCredentials: true,
    })
      .then((res) => {
        dispatch({ type: DELETE_QUICONTACTER, payload: { id } });
      })
      .catch((error) => console.error(error));
  };
};
