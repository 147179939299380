import React from "react";

const StatusUpdate = () => {
  return (
    <div className="edited">
      <span>
        <i className="fas fa-exclamation fa-beat"></i> Mis à jour !
      </span>
    </div>
  );
};

export default StatusUpdate;
