import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable, usePagination } from "react-table";
import Nav from "../Components/Nav/Nav";
import Login from "./Login";
import AdminMenu from "../Components/Admin/AdminMenu";
import { getRequestKnowledge } from "../actions/requestKnowledge";
import { DateParser } from "../Components/utils/Utils";
import AsignKnowledge from "../Components/Knowledge/AssigneKnowledge";
import ImageProfil from "../Components/Profil/ImageProfil";
import KnowledgeStatus from "../Components/Knowledge/KnowledgeStatus";

const AdminKnowledge = () => {
  const userData = useSelector((state) => state.userReducer);
  const knowledgeRequest = useSelector(
    (state) => state.requestKnowledgeReducer
  );
  const requestWait = Object.values(knowledgeRequest).filter(
    (knowledge) =>
      knowledge.requestStatus === "0" ||
      knowledge.requestStatus === "1" ||
      knowledge.requestStatus === "2" ||
      knowledge.requestStatus === "3"
  );

  const [showModal, setShowModal] = useState(false);
  const [showModalValidation, setShowModalValidation] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("");

  const dispatch = useDispatch();

  const filterOptions = [
    { label: "Toutes les demandes", value: "" },
    { label: "Tuto à assigner", value: "0" },
    { label: "Tuto à valider", value: "1" },
    { label: "Tuto Terminé", value: "2" },
    { label: "Tuto Annulé", value: "3" },
  ];

  const handleChangeFilter = (e) => {
    setSelectedFilter(e.target.value);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Utilisateurs",
        accessor: "user",
        Cell: ({ row }) => (
          <div className="flex">
            <ImageProfil id={row?.original?.user?.id} />
            <span>
              {row.original?.user?.firstname} {row?.original?.user?.name}
            </span>
          </div>
        ),
      },
      {
        Header: "Statut",
        accessor: "status",
        Cell: ({ row }) => (
          <span className="badge">{row?.original?.nature}</span>
        ),
      },
      {
        Header: "Catégorie",
        accessor: "categorie",
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: ({ value }) => <span>{DateParser(value)}</span>,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <div className="flexTable">
            {row.original.requestStatus === "0" ? (
              <button
                className="blackButton"
                onClick={() => {
                  setSelectedRequest(row.original);
                  setShowModal(true);
                }}
              >
                Voir la demande
              </button>
            ) : null}
            {row.original.requestStatus === "1" ? (
              <button className="blockedButton">Pris en charge</button>
            ) : null}
            {row.original.requestStatus === "1" ? (
              <button
                className="greenButton"
                onClick={() => {
                  setSelectedRequest(row.original);
                  setShowModalValidation(true);
                }}
              >
                ⌛ Finaliser
              </button>
            ) : null}
            {row.original.requestStatus === "2" ? (
              <button className="blockedButton">✅ Terminé</button>
            ) : null}
            {row.original.requestStatus === "3" ? (
              <button className="blockedButton">⛔ Annulé</button>
            ) : null}
          </div>
        ),
      },
    ],
    []
  );

  const filteredData = useMemo(() => {
    if (!Array.isArray(knowledgeRequest)) return [];
    return knowledgeRequest.filter((request) => {
      if (selectedFilter === "") return true;
      return request.requestStatus === selectedFilter;
    });
  }, [knowledgeRequest, selectedFilter]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination
  );

  const { pageIndex, pageSize } = state;

  useEffect(() => {
    dispatch(getRequestKnowledge());
  }, [dispatch]);

  const closeModal = () => {
    setShowModal(false);
  };

  const closeModalValidation = () => {
    setShowModalValidation(false);
  };

  return (
    <>
      {userData?.isAdmin || userData?.isReferent ? (
        <>
          <Nav />
          <div className="mainContainer">
            <AdminMenu />
            <div className="centerBlock_admin">
              <div className="search_admin">
                <h3>Bac à sable des demandes de Tutos</h3>
                <div className="filterContainer">
                  <label htmlFor="statusFilter">Filtrer par statut : </label>
                  <select
                    id="statusFilter"
                    value={selectedFilter}
                    onChange={handleChangeFilter}
                  >
                    {filterOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <ul className="useronline-container-admin userlist_admin requestformation">
                  <>
                    {requestWait.length === 0 ? (
                      <div className="nullformation">
                        <p> 😴 Aucune demande pour le moment </p>
                      </div>
                    ) : (
                      <>
                        <table {...getTableProps()} className="knowledgeTable">
                          <thead>
                            {headerGroups.map((headerGroup) => (
                              <tr
                                {...headerGroup.getHeaderGroupProps()}
                                key={headerGroup.id}
                              >
                                {headerGroup.headers.map((column) => (
                                  <th
                                    {...column.getHeaderProps()}
                                    key={column.id}
                                  >
                                    {column.render("Header")}
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>
                          <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                              prepareRow(row);
                              return (
                                <tr {...row.getRowProps()} key={row.id}>
                                  {row.cells.map((cell) => (
                                    <td
                                      {...cell.getCellProps()}
                                      key={cell.column.id}
                                    >
                                      {cell.render("Cell")}
                                    </td>
                                  ))}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <div className="pagination">
                          <button
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                          >
                            Précédent
                          </button>
                          <button
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                          >
                            Suivant
                          </button>
                          <span>
                            Page <strong>{pageIndex + 1}</strong> sur{" "}
                            <strong>{pageOptions.length}</strong>
                          </span>
                          <span>
                            Aller à la page :{" "}
                            <input
                              type="number"
                              defaultValue={pageIndex + 1}
                              onChange={(e) => {
                                const pageNumber = e.target.value
                                  ? Number(e.target.value) - 1
                                  : 0;
                                gotoPage(pageNumber);
                              }}
                              style={{ width: "50px" }}
                            />
                          </span>{" "}
                          <select
                            value={pageSize}
                            onChange={(e) => {
                              setPageSize(Number(e.target.value));
                            }}
                          >
                            {[5, 10, 20].map((pageSize) => (
                              <option key={pageSize} value={pageSize}>
                                Afficher {pageSize}
                              </option>
                            ))}
                          </select>
                        </div>
                        {showModal && (
                          <div className="modal">
                            <div className="modal-content">
                              <h2>Détails de la demande </h2>
                              <AsignKnowledge
                                request={selectedRequest}
                                closeModal={closeModal}
                              />
                              <div className="modal-buttons">
                                <button
                                  className="close"
                                  type="button"
                                  onClick={closeModal}
                                >
                                  <i className="fa-regular fa-circle-xmark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        {showModalValidation && (
                          <div className="modal">
                            <div className="modal-content formationTab">
                              <h2>Finaliser la demande </h2>
                              <KnowledgeStatus
                                knowledge={selectedRequest}
                                closeModalValidation={closeModalValidation}
                              />
                              <div className="modal-buttons">
                                <button
                                  className="close"
                                  type="button"
                                  onClick={closeModalValidation}
                                >
                                  <i className="fa-regular fa-circle-xmark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default AdminKnowledge;
