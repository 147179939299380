import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nav from "../Components/Nav/Nav";
import Login from "./Login";
import AdminMenu from "../Components/Admin/AdminMenu";
import {
  addOutilExt,
  getOutilExt,
  editeOutilExt,
  deleteOutilExt,
} from "../actions/outilsExt.actions";
import { useTable, usePagination } from "react-table";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const AdminOutilsExt = () => {
  const userData = useSelector((state) => state.userReducer);
  const outilsData = useSelector((state) => state.outilsExtReducer);
  const [outilsName, setOutilsName] = useState("");
  const [outilsEmail, setOutilsEmail] = useState("");
  const [outilsPassword, setOutilsPassword] = useState("");
  const [outilsLink, setOutilsLink] = useState("");
  const [outilsEditId, setOutilsEditId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      addOutilExt(outilsName, outilsEmail, outilsPassword, outilsLink)
    ).then(() => {
      dispatch(getOutilExt());
      setOutilsName("");
    });
  };

  const handleEdit = (outil) => {
    setOutilsEditId(outil.id);
    setOutilsName(outil.name);
    setOutilsEmail(outil.email);
    setOutilsPassword(outil.password);
    setOutilsLink(outil.link);
    setShowModal(true);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    dispatch(
      editeOutilExt(
        outilsEditId,
        outilsName,
        outilsEmail,
        outilsPassword,
        outilsLink
      )
    );
    setOutilsEditId(null);
    setShowModal(false);
  };

  const handleDelete = (outil) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce sujet ?")) {
      dispatch(deleteOutilExt(outil.id))
        .then(() => {
          setOutilsEditId(null);
          dispatch(getOutilExt());
        })
        .catch((error) => {
          console.log("Erreur lors de la suppression de l'outil :", error);
        });
    }
  };

  const handleReorder = async (id, newPosition) => {
    try {
      await axios.post(`/api/outils/nosoutils/Ext/${id}/reorder`, {
        id,
        newPosition,
      });
      dispatch(getOutilExt());
    } catch (error) {
      console.error("Erreur lors de la réorganisation :", error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Nom de l'outil",
        accessor: "name",
      },
      {
        Header: "Email de connexion",
        accessor: "email",
      },
      {
        Header: "Mot de passe de connexion",
        accessor: "password",
      },
      {
        Header: "Url outil",
        accessor: "link",
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ cell }) => (
          <div className="flexTable">
            <>
              <button
                onClick={() => handleEdit(cell.row.original)}
                className="blackButtonTable"
              >
                Modifier
              </button>
              <button
                onClick={() => handleDelete(cell.row.original)}
                className="deleteButton"
              >
                <i className="fa-regular fa-trash-can"></i>
              </button>
            </>
          </div>
        ),
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    if (!Array.isArray(outilsData)) return [];
    const sortedData = [...outilsData].sort((a, b) => a.position - b.position);

    return sortedData;
  }, [outilsData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 20 } },
    usePagination
  );

  useEffect(() => {
    dispatch(getOutilExt());
  }, [dispatch]);

  const handleCloseModal = () => {
    setOutilsEditId(null);
    setShowModal(false);
  };

  return (
    <>
      {userData.isAdmin ? (
        <>
          <Nav />
          <div className="mainContainer">
            <AdminMenu />
            <div className="centerBlock_admin">
              <h1>Ajouter/Editer un outil Coffre Fort</h1>
              <div className="addOutil">
                <form onSubmit={handleSubmit}>
                  <div className="new_outil">
                    <i className="fa-solid fa-circle-plus"></i>
                    <label htmlFor="name">Nom de l'outil:</label>
                    <input
                      type="text"
                      id="name"
                      value={outilsName}
                      onChange={(event) => setOutilsName(event.target.value)}
                    />
                    <label htmlFor="name">Email de connexion:</label>
                    <input
                      type="text"
                      id="name"
                      value={outilsEmail}
                      onChange={(event) => setOutilsEmail(event.target.value)}
                    />
                    <label htmlFor="name">Mot de passe de connexion:</label>
                    <input
                      type="text"
                      id="name"
                      value={outilsPassword}
                      onChange={(event) =>
                        setOutilsPassword(event.target.value)
                      }
                    />
                    <label htmlFor="name">Url outil:</label>
                    <input
                      type="text"
                      id="name"
                      value={outilsLink}
                      onChange={(event) => setOutilsLink(event.target.value)}
                    />
                  </div>
                  <button type="submit">Ajouter</button>
                </form>
              </div>

              <div className="allOutils">
                <DragDropContext
                  onDragEnd={(result) =>
                    handleReorder(result.draggableId, result.destination.index)
                  }
                >
                  <Droppable droppableId="outils">
                    {(provided) => (
                      <table {...getTableProps()} ref={provided.innerRef}>
                        <thead>
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                  {column.render("Header")}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {page.map((row, i) => {
                            prepareRow(row);
                            return (
                              <Draggable
                                key={row.id}
                                draggableId={row.original.id.toString()}
                                index={i}
                              >
                                {(provided) => (
                                  <tr
                                    {...row.getRowProps()}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {row.cells.map((cell) => (
                                      <td {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                      </td>
                                    ))}
                                  </tr>
                                )}
                              </Draggable>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </Droppable>
                </DragDropContext>
                <div className="pagination">
                  <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    Précédent
                  </button>
                  <span>
                    Page{" "}
                    <strong>
                      {pageIndex + 1} sur {pageOptions?.length}
                    </strong>{" "}
                  </span>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    Suivant
                  </button>
                  <span>
                    Aller à la page :{" "}
                    <input
                      type="number"
                      defaultValue={pageIndex + 1}
                      onChange={(e) => {
                        const pageNumber = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(pageNumber);
                      }}
                      style={{ width: "50px" }}
                    />
                  </span>{" "}
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Afficher {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          {showModal && (
            <div className="modal">
              <div className="modal-content">
                <h2>Modifier l'outil externe</h2>
                <form onSubmit={handleUpdate}>
                  <div>
                    <label>Nom de l'outil:</label>
                    <input
                      type="text"
                      value={outilsName}
                      onChange={(event) => setOutilsName(event.target.value)}
                    />
                  </div>
                  <div>
                    <label>Email de connexion:</label>
                    <input
                      type="text"
                      value={outilsEmail}
                      onChange={(event) => setOutilsEmail(event.target.value)}
                    />
                  </div>
                  <div>
                    <label>Mot de passe de connexion:</label>
                    <input
                      type="text"
                      value={outilsPassword}
                      onChange={(event) =>
                        setOutilsPassword(event.target.value)
                      }
                    />
                  </div>
                  <div>
                    <label>Url outil:</label>
                    <input
                      type="text"
                      value={outilsLink}
                      onChange={(event) => setOutilsLink(event.target.value)}
                    />
                  </div>
                  <div className="modal-buttons">
                    <button type="button" onClick={handleCloseModal}>
                      Annuler
                    </button>
                    <button type="submit">Valider</button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default AdminOutilsExt;
