import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Nav from "../Components/Nav/Nav";
import Login from "./Login";
import { getPost } from "../actions/post.actions";
import Card from "../Components/Post/Card";
import { UidContext } from "../Components/App.Context";
import { getComments } from "../actions/comment.actions";
import { getLikes } from "../actions/likes.actions";
import Menu from "../Components/Nav/Menu";

const Post = ({ socket }) => {
  const { id } = useParams();
  const uid = useContext(UidContext);
  const post = useSelector((state) => state.postReducer);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    dispatch(getPost(id));
    dispatch(getComments());
    dispatch(getLikes()).finally(() => setLoading(false));
  }, [dispatch, id]);

  return (
    <>
      {uid ? (
        <>
          <Nav socket={socket} />
          <div className="mainContainer">
            <Menu />
            <div className="center-Block">
              {loading ? (
                <div className="loader">
                  <i className="fas fa-spinner fa-spin"></i> Chargement du
                  Post...
                </div>
              ) : (
                <Card post={post} key={post.id} socket={socket} />
              )}
            </div>
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default Post;
