import React, { useEffect, useState } from "react";
import ThunderCloud from "../../asset/thunder.png";

const Thunder = ({ outil }) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalID = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(intervalID);
  }, []);

  return (
    <div class="container_weather">
      <div class="rainy">
        <div className="body_container">
          <div className="tool_info">
            <p className="outil_name">{outil.name}</p>
            <p>{time.toLocaleString()}</p>
            <p>Orageux</p>
          </div>
          <div className="tool_weather">
            <img src={ThunderCloud} alt="thunder_cloud" />
            <div className="rain"></div>
            <div className="rain2"></div>
          </div>
          <div className="lightning flashit"></div>
        </div>
      </div>
    </div>
  );
};

export default Thunder;
