import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { UidContext } from "../../Components/App.Context";
import ImageProfil from "../Profil/ImageProfil";
import Lottie from "lottie-react";
import Podium from "../../asset/podium.json";
import Fire from "../../asset/fire.json";
import Chrono from "../../asset/chrono.json";
import Thumbs from "../../asset/thumbs.json";

const QuizzModal = ({ quiz, onClose }) => {
  const [currentPage, setCurrentPage] = useState("presentation");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [timer, setTimer] = useState(45);
  const [score, setScore] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [userScore, setUserScore] = useState(null);
  const [quizResults, setQuizResults] = useState([]);
  const [hasAnswered, setHasAnswered] = useState(false);

  const uid = useContext(UidContext);

  useEffect(() => {
    const checkQuizCompletion = async () => {
      try {
        const response = await axios.get("/api/quizz/check-quiz-completion", {
          params: {
            quizId: quiz.id,
            userId: uid,
          },
        });
        setQuizCompleted(response.data.quizCompleted);
        setUserScore(response.data.score);
      } catch (error) {
        console.error(error);
      }
    };
    checkQuizCompletion();
  }, [quiz.id, uid]);

  useEffect(() => {
    const fetchQuizResults = async () => {
      try {
        const response = await axios.get("/api/quizz/get-quiz-results", {
          params: {
            quizId: quiz.id,
          },
        });
        setQuizResults(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchQuizResults();
  }, [quiz.id]);

  useEffect(() => {
    let intervalId;
    if (currentPage === "question" && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(intervalId);
            handleNextQuestion();
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [currentPage, timer]);

  const handleStartQuiz = () => {
    setCurrentPage("question");
    setQuizCompleted(false);
  };

  const handleAnswerSelect = (response) => {
    setSelectedAnswer(response);
    setHasAnswered(true);
  };

  const handleNextQuestion = () => {
    if (!hasAnswered) {
      setSelectedAnswers((prevSelectedAnswers) => {
        const updatedSelectedAnswers = [...prevSelectedAnswers];
        updatedSelectedAnswers[currentQuestionIndex] = "non répondu";
        return updatedSelectedAnswers;
      });
    } else if (selectedAnswer !== null) {
      setSelectedAnswers((prevSelectedAnswers) => {
        const updatedSelectedAnswers = [...prevSelectedAnswers];
        updatedSelectedAnswers[currentQuestionIndex] =
          selectedAnswer.estBonneReponse
            ? quiz.QuizzQuestions[currentQuestionIndex].QuizzReponses.indexOf(
                selectedAnswer
              )
            : "non répondu";
        return updatedSelectedAnswers;
      });
      if (selectedAnswer.estBonneReponse) {
        setScore((prevScore) => prevScore + 1);
      }
    }
    setSelectedAnswer(null);
    setHasAnswered(false);

    if (currentQuestionIndex === quiz.QuizzQuestions.length - 1) {
      setCurrentPage("summary");
    } else {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setTimer(30);
    }
  };

  const handleFinishQuiz = async () => {
    try {
      const formattedSelectedAnswers = selectedAnswers.map(
        (answerIndex, questionIndex) => {
          const currentQuestion = quiz.QuizzQuestions[questionIndex];

          if (answerIndex === "non répondu") {
            return `${questionIndex + 1} - Non répondu ou délai expiré`;
          } else {
            const selectedResponse = currentQuestion.QuizzReponses[answerIndex];
            return `${questionIndex + 1} - ${selectedResponse.reponse}`;
          }
        }
      );
      const response = await axios.post("/api/quizz/quizz-results", {
        quizId: quiz.id,
        userId: uid,
        score,
        selectedAnswers: formattedSelectedAnswers,
      });
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
    onClose();
  };

  const Overlay = ({ children }) => {
    return <div className="overlay">{children}</div>;
  };

  return (
    <Overlay>
      <div className="quiz-modal">
        {quizCompleted ? (
          <div className="quiz-summary">
            <h3>Résultat du Quizz</h3>
            {quizCompleted && userScore !== null && (
              <>
                <div className="scoreUser">
                  <p>
                    💪 Vous avez déjà réalisé ce quizz avec un score de{" "}
                    {userScore}.
                  </p>
                </div>
                <div className="podium">
                  <p>🏆 Score des utilisateurs qui ont réalisé le quizz 🏆</p>
                  <ul>
                    {quizResults
                      .sort((a, b) => b.score - a.score)
                      .map((result, index) => (
                        <li key={index}>
                          <div className="user pyro">
                            {index === 0 ? (
                              <span role="img" aria-label="trophy">
                                🏆
                              </span>
                            ) : index === 1 ? (
                              <span role="img" aria-label="second-place">
                                🥈
                              </span>
                            ) : index === 2 ? (
                              <span role="img" aria-label="third-place">
                                🥉
                              </span>
                            ) : (
                              <>{index + 1} - </>
                            )}
                            <ImageProfil id={result?.user?.id} />{" "}
                            {result?.user?.firstName} {result?.user?.name}:{" "}
                            {result?.score} points
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </>
            )}
            <button onClick={onClose} className="finish">
              Revenir sur l'actualité Skills
            </button>
          </div>
        ) : currentPage === "presentation" ? (
          <div className="quiz-presentation">
            <button class="close-button" onClick={onClose}>
              <i class="fa-regular fa-circle-xmark"></i>
            </button>
            <h3>Vous êtes sur le point de démarrer le Quizz</h3>
            <div className="tutorielQuiz">
              <div className="scoreUser">
                <h3>Les règles du Quizz :</h3>
                <li>👉 Une question</li>
                <li>👉 Plusieurs choix de réponses</li>
                <li>👉 Une seule réponse possible</li>
                <li>👉 30 secondes par question</li>
                <button onClick={handleStartQuiz} className="url_site">
                  <span className="button-content">Démarrer le Quizz</span>
                </button>
              </div>
              <div className="bottomText">
                <Lottie animationData={Podium} loop={true} />
              </div>
            </div>
          </div>
        ) : currentPage === "question" ? (
          <div className="quiz-question">
            <p className={timer <= 10 ? "red-timer timer" : "timer"}>
              <div className="timerClock">
                <Lottie animationData={Chrono} loop={true} /> Temps restant :{" "}
                {timer} secondes
              </div>
            </p>
            <div className="quizQuestion">
              <div className="questionQuiz">
                <p>{quiz.QuizzQuestions[currentQuestionIndex].question}</p>
              </div>
              <div className="response">
                <ul>
                  {quiz.QuizzQuestions[currentQuestionIndex].QuizzReponses.map(
                    (reponse, index) => (
                      <li
                        key={index}
                        className={`theQuestion ${
                          selectedAnswer === reponse ? "selectedAnswer" : ""
                        }`}
                        onClick={() => handleAnswerSelect(reponse)}
                      >
                        {reponse.reponse}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
            <button onClick={handleNextQuestion} className="url_site">
              <span className="button-content">Question suivante </span>
            </button>
          </div>
        ) : (
          <div className="quiz-summary">
            <h3>Résumé du Quizz</h3>
            <p>
              Score final : {score} sur {quiz.QuizzQuestions.length}
            </p>
            <div className="thumbs">
              <Lottie animationData={Thumbs} loop={false} />
            </div>
            <button onClick={handleFinishQuiz} className="finish">
              Terminer
            </button>
            <div className="fire">
              <Lottie animationData={Fire} loop={true} />
            </div>
          </div>
        )}
      </div>
    </Overlay>
  );
};

export default QuizzModal;
