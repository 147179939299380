import axios from "axios";

export const ADD_TOOL_EXT = "ADD_TOOL_EXT";
export const GET_TOOL_EXT = "GET_TOOL_EXT";
export const EDIT_TOOL_EXT = "EDIT_TOOL_EXT";
export const DELETE_TOOL_EXT = "DELETE_TOOL_EXT";

export const addOutilExt = (
  outilsName,
  outilsEmail,
  outilsPassword,
  outilsLink
) => {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/outils/externe`,
      withCredentials: true,
      data: {
        name: outilsName,
        email: outilsEmail,
        password: outilsPassword,
        link: outilsLink,
      },
    })
      .then((res) => {
        dispatch({
          type: ADD_TOOL_EXT,
          payload: res.data,
        });
      })
      .catch((error) => console.error(error));
};

export const getOutilExt = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/outils/nosoutils/Ext`,
      { withCredentials: true }
    );
    dispatch({
      type: GET_TOOL_EXT,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const editeOutilExt = (
  outilsEditId,
  outilsName,
  outilsEmail,
  outilsPassword,
  outilsLink
) => {
  return (dispatch) => {
    return axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/api/outils/nosoutils/Ext/${outilsEditId}`,
      withCredentials: true,
      data: {
        name: outilsName,
        email: outilsEmail,
        password: outilsPassword,
        link: outilsLink,
      },
    })
      .then((res) => {
        dispatch({
          type: EDIT_TOOL_EXT,
          payload: { outilsName, outilsEmail, outilsPassword, outilsLink },
        });
      })
      .catch((error) => console.error(error));
  };
};

export const deleteOutilExt = (outilsId) => {
  return (dispatch) => {
    return axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/api/outils/nosoutils/Ext/${outilsId}`,
      withCredentials: true,
    })
      .then((res) => {
        dispatch({
          type: DELETE_TOOL_EXT,
          payload: outilsId,
        });
      })
      .catch((error) => console.error(error));
  };
};
