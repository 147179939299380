import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFormations, editFormation } from "../../actions/formations.actions";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Select from "react-select";
import { getAllUsersFormation } from "../../actions/usersFormation.actions";

const EditFormation = ({ formations }) => {
  const [formationName, setformationName] = useState("");
  const [formationLvl, setformationLvl] = useState("");
  const [formationDescription, setformationDescription] = useState("");
  const [formationHours, setformationHours] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [starUsers, setStarUsers] = useState([]);
  const dispatch = useDispatch();

  const usersData = useSelector((state) => state.usersReducer);

  //Menu toggle
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleEdite = (e) => {
    e.preventDefault();

    const formationStars = starUsers.map((user) => user.value);

    dispatch(
      editFormation(
        formations.id,
        formationName,
        formationLvl,
        formationDescription,
        formationHours,
        formationStars
      )
    ).then(() => {
      dispatch(getFormations());
    });
  };

  useEffect(() => {
    setformationName(formations.formationName);
    setformationLvl(formations.formationLvl);
    setformationDescription(formations.formationDescription);
    setformationHours(formations.formationHours);
  }, [formations]);

  useEffect(() => {
    dispatch(getAllUsersFormation()).then(() => {
      if (formations && formations.formationStars) {
        let stars = [];
        if (formations.formationStars.includes(",")) {
          stars = formations.formationStars.split(",");
        } else {
          stars.push(formations.formationStars);
        }
        const selectedStarUsers = usersData.filter((user) =>
          stars.includes(String(user.id))
        );
        setStarUsers(
          selectedStarUsers.map((user) => ({
            label: user.firstname + " - " + user.name,
            value: String(user.id),
          }))
        );
      }
    });
  }, []);

  const handleStarUsersChange = (selectedUsers) => {
    setStarUsers(selectedUsers);
  };

  return (
    <>
      <i className="far fa-edit" onClick={toggleMenu}></i>
      <div className={`profil_formation_edit ${isOpen ? "active_edit" : ""}`}>
        <div className="close" onClick={toggleMenu}>
          <i className="far fa-times-circle"></i>
        </div>
        <form action="" onSubmit={handleEdite}>
          <label htmlFor="name">Nom de la formation</label>
          <input
            type="text"
            name="formationName"
            className="input_signup"
            id="formationName"
            placeholder={formations.formationName}
            onChange={(e) => setformationName(e.target.value)}
            value={formationName}
            required
          />
          <br />

          <label htmlFor="formationLvl">Niveau de la formation</label>
          <input
            type="text"
            name="formationLvl"
            className="input_signup"
            id="formationLvl"
            placeholder={formations.formationLvl}
            onChange={(e) => setformationLvl(e.target.value)}
            value={formationLvl}
            required
          />
          <br />
          <label htmlFor="email">Description de la formation</label>
          <div className="formationDescription">
            <CKEditor
              editor={Editor}
              data={formations.formationDescription}
              onChange={(event, editor) => {
                const data = editor.getData();
                setformationDescription(data);
              }}
            />
          </div>
          <br />
          <label htmlFor="formationHours">Durée de formation</label>
          <input
            type="time"
            name="formationHours"
            className="input_signup"
            id="formationHours"
            onChange={(e) => setformationHours(e.target.value)}
            value={formationHours}
            required
          />
          <br />
          <label htmlFor="formationUser">
            Utilisateur(s) Stars pour cette formation :
          </label>
          <Select
            options={usersData.map((user) => ({
              label: user.firstname + " - " + user.name,
              value: String(user.id),
            }))}
            isMulti
            value={starUsers}
            onChange={handleStarUsersChange}
          />
          <br />

          <input
            type="submit"
            className="submit"
            value="Editer"
            onClick={toggleMenu}
          />
        </form>
      </div>
    </>
  );
};

export default EditFormation;
