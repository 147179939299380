import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

const LikeChart = ({ postsData, likesData }) => {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        height: 280,
        id: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#f8ad13", "#f8ad13", "#9C27B0"],
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        show: false,
      },
      grid: {
        show: false,
      },
      markers: {
        size: 6,
      },
      stroke: {
        curve: "smooth",
      },
      fill: {
        type: "gradient",
        colors: ["#f8ad13", "rgba(255,180,0,.12)", "rgba(255,180,0,.12)"],
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      colors: ["rgba(255,180,0,.12)"],
    },
    series: [
      {
        name: "Likes",
        data: [],
      },
    ],
  });

  useEffect(() => {
    const months = [];

    for (let i = 0; i < 4; i++) {
      const month = moment().subtract(i, "months").format("MMM");
      months.push(month);
    }

    const postsInLastFourMonths = Object.values(postsData).filter((post) => {
      const postMonthAndYear = moment(post?.createdAt).format("MMM");
      return months.includes(postMonthAndYear);
    });

    const likesDataByMonth = {};
    const likesArray = Object.values(likesData);

    postsInLastFourMonths.forEach((post) => {
      const postMonth = moment(post?.createdAt).format("MMM");
      const postLikes = likesArray.filter((like) => like?.postId === post?.id);
      const likesCount = postLikes.reduce((acc, like) => acc + like.like, 0);

      if (likesDataByMonth[postMonth]) {
        likesDataByMonth[postMonth] += likesCount;
      } else {
        likesDataByMonth[postMonth] = likesCount;
      }
    });

    const sortedMonths = months.reverse();
    const likesCountData = sortedMonths.map(
      (month) => likesDataByMonth[month] || 0
    );

    setChartData((prevData) => ({
      ...prevData,
      options: {
        ...prevData.options,
        xaxis: {
          ...prevData.options.xaxis,
          categories: sortedMonths,
        },
      },
      series: [
        {
          ...prevData.series[0],
          data: likesCountData,
        },
      ],
    }));
  }, [postsData, likesData]);

  return (
    <div className="chart">
      <ReactApexChart
        options={chartData?.options}
        series={chartData?.series}
        type="area"
        height="250"
      />
    </div>
  );
};

export default LikeChart;
