import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useParams } from "react-router-dom";

const NewPassForm = () => {
  const [password, setPassword] = useState("");
  const { token } = useParams();

  let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
  let testPassword = strongRegex.test(password);

  const handleLogin = (e) => {
    e.preventDefault();
    if (!testPassword) {
      toast.error(
        "Le mot de passe doit comprendre 8 caractères dont 2 chiffres. Ainsi qu'une lettre majuscule et minuscule."
      );
    } else {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/user/new-password`,
        withCredentials: true,
        data: {
          password,
          token,
        },
      })
        .then(() => {
          toast.success(
            "Mot de passe modifié, vous pouvez maintenant vous connecter"
          );
        })
        .catch(() => toast.error("Merci de verifier vos identifiants"));
    }
  };

  return (
    <>
      <ToastContainer />
      <form action="" onSubmit={handleLogin} id="form_signup">
        <label htmlFor="password">Votre nouveau Mot de passe</label>
        <input
          type="password"
          name="password"
          className="input_signup"
          id="password"
          placeholder="Entrez votre mot de passe"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          required
        />
        <div className="password-error"></div>
        <br />
        <input type="submit" className="submit" value="Modifier" />
      </form>
    </>
  );
};

export default NewPassForm;
