import axios from "axios";

export const GET_BOITEOUTILS = "GET_BOITEOUTILS";
export const ADD_BOITEOUTILS = "ADD_BOITEOUTILS";
export const DELETE_BOITEOUTILS = "DELETE_BOITEOUTILS";

export const getBoiteOutils = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/boiteaoutils/`,
      { withCredentials: true }
    );
    dispatch({
      type: GET_BOITEOUTILS,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const addBoiteOutils = (name, categorie, description, link, image) => {
  return (dispatch) => {
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/boiteaoutils/`,
      withCredentials: true,
      data: {
        name: name,
        categorie: categorie,
        description: description,
        link: link,
        image: image,
      },
    })
      .then((res) => {
        dispatch({
          type: ADD_BOITEOUTILS,
          payload: {
            name: name,
            categorie: categorie,
            description: description,
            link: link,
            image: image,
          },
        });
      })
      .catch((error) => console.error(error));
  };
};

export const deleteBoiteOutils = (id) => {
  return (dispatch) => {
    return axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/api/boiteaoutils/${id}`,
      withCredentials: true,
    })
      .then((res) => {
        dispatch({ type: DELETE_BOITEOUTILS, payload: { id } });
      })
      .catch((error) => console.error(error));
  };
};
