import { useSelector } from "react-redux";
import SignupForm from "../Components/Log/SignupForm";
import Nav from "../Components/Nav/Nav";
import Login from "./Login";
import AdminMenu from "../Components/Admin/AdminMenu";

const Signup = () => {
  const userData = useSelector((state) => state.userReducer);

  return (
    <>
      {userData.isAdmin ? (
        <>
          <Nav />
          <div className="mainContainer">
            <AdminMenu />
            <div className="centerBlock_admin">
              <div className="useronline-container-admin">
                <div className="requestformation">
                  <>
                    <h2>Créer un utilisateur </h2>
                    <br />
                    <br />
                    <SignupForm />
                  </>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default Signup;
