import React, { useEffect, useState } from 'react';
import axios from 'axios';


const ImagePost = ({ id }) => {
    const [imagePostUrl, setImagePostUrl] = useState(null);

    useEffect(() => {
        // Envoyez une requête HTTP GET au backend pour récupérer l'URL de l'image de l'utilisateur
        axios.get(`${process.env.REACT_APP_API_URL}/api/message/image/${id}`)
            .then(
                response => setImagePostUrl(response.data)
            );
    }, [id]);

    return imagePostUrl ? <img class="card-img" src={imagePostUrl} alt="Image du Post " /> : <p>Chargement...</p>;
};

export default ImagePost;