import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

function Crop() {
  const [image, setImage] = useState();
  const [cropData, setCropData] = useState("#");
  const [template, setTemplate] = useState("template1");
  const cropperRef = useRef(null);

  const handleImport = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        setImage(reader.result);
      };
    }
  };

  const handleCrop = () => {
    if (cropperRef.current) {
      const croppedImage = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();
      setCropData(croppedImage);
    }
  };

  const handleTemplate1 = () => {
    setTemplate("template1");
    cropperRef.current?.cropper?.setAspectRatio(1920 / 400);
  };

  const handleTemplate2 = () => {
    setTemplate("template2");
    cropperRef.current?.cropper?.setAspectRatio(1);
  };

  return (
    <div>
      <div className="import_img">
        <input
          class="buttonDownload"
          type="file"
          accept="image/*"
          onChange={handleImport}
        />
      </div>
      <br />
      {image ? (
        <>
          <div className="crop_container">
            <div className="img_select">
              <Cropper
                src={image}
                style={{ height: 400, width: "100%" }}
                initialAspectRatio={1920 / 400}
                ref={cropperRef}
              />
            </div>
            <div className="img_button">
              <button onClick={handleTemplate1}>1920 x 400</button>
              <button onClick={handleTemplate2}>500 x 500</button>
              <button onClick={handleCrop}>Découper l'image</button>
            </div>
            <div className="box">
              <h3>
                <span>Résultat</span>
              </h3>
              {template === "template1" ? (
                <div className="img_result">
                  {cropData != "#" ? (
                    <img
                      src={cropData}
                      alt="cropped"
                      height={400}
                      width={1920}
                    />
                  ) : null}
                </div>
              ) : (
                <div className="img_result">
                  {cropData != "#" ? (
                    <img
                      src={cropData}
                      alt="cropped"
                      height={500}
                      width={500}
                    />
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}
      <br style={{ clear: "both" }} />
    </div>
  );
}

export default Crop;
