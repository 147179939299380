import axios from "axios";

export const GET_KNOWLEDGE_FAVORIS = "GET_KNOWLEDGE_FAVORIS";
export const ADD_KNOWLEDGE_FAVORIS = "GET_KNOWLEDGE_FAVORIS";
export const REMOVE_KNOWLEDGE_FAV = "REMOVE_KNOWLEDGE_FAV";

export const getKnwoledgeFav = (userId) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/knowledge/favorites/${userId}`
    );
    dispatch({ type: GET_KNOWLEDGE_FAVORIS, payload: response.data });
  } catch (error) {
    console.error(error);
  }
};

export const addKnwoledgeFav = (userId, knowledgeId) => {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/knowledge/favorites/${userId}`,
      withCredentials: true,
      data: { userId: userId, knowledgeId: knowledgeId },
    })
      .then((res) => {
        dispatch({ type: ADD_KNOWLEDGE_FAVORIS, payload: { knowledgeId } });
      })
      .catch((error) => console.error(error));
};

export const removeKnowledgeFav = (userId, knowledgeId) => {
  return (dispatch) =>
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/api/knowledge/favorites/${userId}/${knowledgeId}`,
      withCredentials: true,
      data: { userId: userId, knowledgeId: knowledgeId },
    })
      .then((res) => {
        dispatch({
          type: REMOVE_KNOWLEDGE_FAV,
          payload: { userId, knowledgeId },
        });
      })
      .catch((error) => console.error(error));
};
