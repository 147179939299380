import axios from "axios";

export const GET_USERSFORMATIONS = "GET_USERSFORMATIONS";
export const GET_ALLUSERSFORMATIONS = "GET_ALLUSERSFORMATIONS";
export const CREAT_USERSFORMATIONS = "CREAT_USERSFORMATIONS";
export const DELETE_USERSFORMATIONS = "DELETE_USERSFORMATIONS";

export const getUsersFormations = (userId) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/formation/${userId}`,
        {
          withCredentials: true,
        }
      );
      dispatch({ type: GET_USERSFORMATIONS, payload: res.data });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getAllUsersFormation = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/user/formation/stats/all`,
      { withCredentials: true }
    );
    dispatch({ type: GET_ALLUSERSFORMATIONS, payload: res.data });
  } catch (err) {
    console.error(err);
  }
};

export const creatUsersFormations =
  (userId, formationId, formationPercent, formationDate, formationFormateur) =>
  async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/formation/formationAssign/${userId}`,
        {
          formationId,
          formationPercent,
          formationDate,
          formationFormateur,
        },
        { withCredentials: true }
      );
      dispatch({
        type: CREAT_USERSFORMATIONS,
        payload: {
          formationId,
          formationPercent,
          formationDate,
          formationFormateur,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

export const deleteUserFormation = (formationsId) => {
  return (dispatch) => {
    return axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/api/formation/formationAssign/${formationsId}`,
      withCredentials: true,
    })
      .then((res) => {
        dispatch({ type: DELETE_USERSFORMATIONS, payload: { formationsId } });
      })
      .catch((error) => console.error(error));
  };
};
