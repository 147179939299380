import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addComment, getComments } from "../../actions/comment.actions";
import { UidContext } from "../App.Context";
import ImageProfil from "../Profil/ImageProfil";
import { DateParser, isEmpty } from "../utils/Utils";
import UpdateComment from "./UpdateComment";
import { MentionsInput, Mention } from "react-mentions";
import parse from "html-react-parser";

const CardComments = ({ post, socket, authorName }) => {
  const [commentaire, setComment] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const uid = useContext(UidContext);
  const usersData = useSelector((state) => state.usersReducer);
  const userData = useSelector((state) => state.userReducer);
  const commentsData = useSelector((state) => state.commentReducer);
  const dispatch = useDispatch();

  const getUserByFullName = (username) => {
    const user = usersData.find(
      (user) => user.name.toLowerCase() === username.toLowerCase()
    );
    const userfirstname = usersData.find(
      (user) => user.firstname.toLowerCase() === username.toLowerCase()
    );
    const full = user + userfirstname;
    return full
      ? { id: user.id, name: user.name, firstname: user.firstname }
      : null;
  };

  const parseMention = (text) => {
    const mentionRegex = /@\[([^\]]+)\]\(\d+\)/g;
    let updatedText = text;

    const matches = text.match(mentionRegex);
    if (matches) {
      matches.forEach((match) => {
        const fullName = match.match(/@\[([^\]]+)\]/)[1];

        const user = getUserByFullName(fullName);
        if (user) {
          const link = `<a href="/userprofil/${user.id}"><strong class="blue-mention">@${user.firstname} ${user.name}</strong></a>`;
          updatedText = updatedText.replace(match, `${link} `);
        }
      });
    }
    return updatedText;
  };

  const handleComment = (e) => {
    e.preventDefault();

    if (commentaire) {
      const formattedComment = parseMention(commentaire);

      // Extract the tagged usernames from the comment
      const taggedUsernames = [];
      const mentionRegex = /@\[([^\]]+)\]\(\d+\)/g;
      let match;
      while ((match = mentionRegex.exec(formattedComment))) {
        const fullName = match[1];
        const user = getUserByFullName(fullName);
        if (user) {
          taggedUsernames.push(user.firstname + " " + user.name);
        }
      }

      console.log(taggedUsernames);

      dispatch(addComment(formattedComment, post.id, userData.id))
        .then(() => {
          dispatch(getComments());
          taggedUsernames.forEach((username) => {
            // Send a notification to each tagged user
            const taggedUser = getUserByFullName(username);
            if (taggedUser) {
              socket?.emit("sendNotification", {
                senderName: userData.name,
                receiverName: taggedUser.name,
                receiverId: taggedUser.id,
              });
            }
          });
        })
        .then(() => setComment(""));
    }
  };

  const commDuPost = Object.values(commentsData).filter((comment) => {
    return comment.postId === post.id;
  });

  useEffect(() => {
    const checkAdmin = () => {
      if (uid === userData.id && userData.isAdmin) {
        setIsAdmin(true);
      }
    };
    checkAdmin();
  }, [uid, userData.id, userData.isAdmin]);

  return (
    <div className="comments-container">
      {commDuPost.map((comment) => {
        return (
          <div
            key={comment.id}
            className={
              comment.author === comment.userId
                ? "comment-container user"
                : "comment-container"
            }
          >
            <div className="left-part">
              <ImageProfil
                id={usersData
                  .map((user) => {
                    if (user.id === comment.userId) return user.id;
                    else return null;
                  })
                  .join("")}
              />
            </div>
            <div className="right-part">
              <div className="comment-header">
                <h3>
                  {!isEmpty(usersData[0]) &&
                    usersData
                      .map((user) => {
                        if (user.id === comment.userId)
                          return user.firstname + " " + user.name;
                        else return null;
                      })
                      .join("")}
                  {comment?.author === comment?.userId ? (
                    <span className="author"> Auteur </span>
                  ) : null}
                </h3>
                <span>{DateParser(comment.createdAt)}</span>
              </div>
              <p className="comment-content">
                {parse(parseMention(comment.commentaire))}
              </p>
              {isAdmin || userData.id === comment.userId ? (
                <UpdateComment comment={comment} postId={post.id} />
              ) : null}
            </div>
          </div>
        );
      })}
      <div className="commentForm">
        <div className="commentForm-picture">
          <ImageProfil id={userData.id} />
        </div>
        <div className="commentForm-form">
          <form action="" onSubmit={handleComment} className="comment-form">
            <MentionsInput
              className="custom-comment-form"
              value={commentaire}
              onChange={(event, newValue, newPlainTextValue, mentions) =>
                setComment(newValue)
              }
              placeholder="Poster un commentaire..."
            >
              <Mention
                trigger="@"
                data={usersData.map((user) => ({
                  id: user.id,
                  display: user.name,
                }))}
                renderSuggestion={(
                  suggestion,
                  search,
                  highlightedDisplay,
                  index,
                  focused
                ) => (
                  <div className={`user${focused ? " focused" : ""}`}>
                    {highlightedDisplay}
                  </div>
                )}
              />
            </MentionsInput>
            <br />
            <input
              type="submit"
              value="Commenter"
              className="inputCommentaire"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default CardComments;
