import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersFormation } from "../../actions/usersFormation.actions";
import ImageProfil from "../Profil/ImageProfil";
import { Link } from "react-router-dom";
import { TagCloud } from "react-tagcloud";

const CompetenceUsers = () => {
  const [query, setQuery] = useState("transfert niveau 4");
  const [results, setResults] = useState([]);
  const [currentKeywords, setCurrentKeywords] = useState([]);

  const data = [
    { value: "Code externe", count: 25 },
    { value: "Transfert niveau 4", count: 18 },
    { value: "Mail", count: 38 },
    { value: "QR code", count: 30 },
    { value: "Vivawallet", count: 22 },
    { value: "Stripe", count: 7 },
    { value: "Pixel Facebook", count: 11 },
    { value: "SSL", count: 17 },
    { value: "Vignette grisée", count: 15 },
  ];

  const dispatch = useDispatch();
  const usersFormationsData = useSelector(
    (state) => state.usersformationsReducer
  );

  useEffect(() => {
    dispatch(getAllUsersFormation());
  }, [dispatch]);

  const search = (searchQuery) => {
    if (searchQuery === "") {
      setResults([]);
      return;
    }

    const matchingUsers = Object.values(usersFormationsData).filter(
      (userFormation) => {
        const formationName = userFormation?.Formation?.formationName;
        const formationDescription =
          userFormation?.Formation?.formationDescription;
        const formationLvl = userFormation?.Formation?.formationLvl;
        const formationComplete = `${formationName} ${formationLvl}`;

        const isMatching =
          (formationLvl &&
            formationLvl.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (formationDescription &&
            formationDescription
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (formationComplete &&
            formationComplete
              .toLowerCase()
              .includes(searchQuery.toLowerCase()));

        return isMatching;
      }
    );

    matchingUsers.sort((a, b) => {
      const aStars = a.Formation.formationStars?.split(",") || [];
      const bStars = b.Formation.formationStars?.split(",") || [];
      const aIsStar = aStars.includes(a.user.id.toString());
      const bIsStar = bStars.includes(b.user.id.toString());

      if (aIsStar && !bIsStar) {
        return -1;
      } else if (!aIsStar && bIsStar) {
        return 1;
      } else if (aIsStar && bIsStar) {
        const aIndex = aStars.indexOf(a.user.id.toString());
        const bIndex = bStars.indexOf(b.user.id.toString());

        if (aIndex === 0 && bIndex !== 0) {
          return -1;
        } else if (aIndex !== 0 && bIndex === 0) {
          return 1;
        }
      }

      return 0;
    });

    setResults(matchingUsers.slice(0, 5));
  };

  useEffect(() => {
    search(query.trim());
  }, [query, usersFormationsData]);

  const getRandomKeywords = () => {
    const shuffledData = [...data].sort(() => Math.random() - 0.5);
    const selectedKeywords = shuffledData.slice(0, 6);
    setCurrentKeywords(selectedKeywords);
  };

  const replaceRandomKeyword = () => {
    const unusedKeywords = data.filter(
      (keyword) => !currentKeywords.map((k) => k.value).includes(keyword.value)
    );
    const randomIndex = Math.floor(Math.random() * unusedKeywords.length);
    const newKeyword = unusedKeywords[randomIndex];
    const randomKeywordIndex = Math.floor(
      Math.random() * currentKeywords.length
    );
    const updatedKeywords = currentKeywords.map((keyword, index) => {
      if (index === randomKeywordIndex) {
        return newKeyword;
      }
      return keyword;
    });
    setCurrentKeywords(updatedKeywords);
  };

  useEffect(() => {
    getRandomKeywords();
  }, []);

  useEffect(() => {
    const interval = setInterval(replaceRandomKeyword, 7000);
    return () => {
      clearInterval(interval);
    };
  }, [currentKeywords]);
  return (
    <>
      <div className="rightHelp">
        <div className="items">
          <h3>Besoin d'aide sur un sujet précis ?</h3>
          <div className="random">
            <TagCloud
              tags={currentKeywords}
              shuffle={true}
              minSize={12}
              maxSize={32}
              colorOptions={{ luminosity: "random" }}
              style={{ fontFamily: "sans-serif" }}
            />
          </div>
          <p>Trouver qui peut m'aider sur un sujet précis</p>
          <div className="searchUserCompetence">
            <input
              type="text"
              className="searchbar_post"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Rechercher un sujet..."
            />
          </div>

          <div className="useronline-container">
            {results.map((userFormation) => (
              <Link
                to={`/userprofil/${userFormation?.user?.id}`}
                key={userFormation?.id}
              >
                <div className="user-online">
                  <div className="left-side">
                    <div>
                      <ImageProfil id={userFormation?.user?.id} />
                    </div>
                  </div>
                  <div className="right-side">
                    <span>
                      {userFormation?.user?.firstname}{" "}
                      {userFormation?.user?.name}
                    </span>
                    <span className="service">
                      {userFormation?.user?.service}
                      {(
                        userFormation?.Formation?.formationStars?.split(",") ||
                        []
                      ).includes(userFormation?.user?.id?.toString()) && (
                        <span className="star-icon">⭐</span>
                      )}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompetenceUsers;
