import React, { useState } from "react";
import { useSelector } from "react-redux";
import Nav from "../Components/Nav/Nav";
import Login from "./Login";
import RequestFormationStatus from "../Components/Formation/RequestFormationStatus";
import { DateParser } from "../Components/utils/Utils";
import DeleteRequest from "../Components/Formation/DeleteRequest";
import FixedPlanning from "../Components/Formation/FixedPlanning";
import FormationMenu from "../Components/Formation/FormationMenu";
import AdminMenu from "../Components/Admin/AdminMenu";

const AdminFormationRequestTermine = () => {
  const [numOfFormations, setNumOfFormations] = useState(5);

  const userData = useSelector((state) => state.userReducer);
  const requestData = useSelector((state) => state.requestFormationReducer);

  const requestFormations = Object.values(requestData).filter((formation) => {
    return formation.formationStatus === "2";
  });

  return (
    <>
      {userData.isAdmin ? (
        <>
          <Nav />
          <FixedPlanning />
          <div className="mainContainer">
            <AdminMenu />
            <div className="centerBlock_admin">
              <div className="useronline-container-admin">
                <div className="requestformation">
                  <>
                    <FormationMenu active="termine" />

                    {requestFormations
                      .slice(0, numOfFormations)
                      .map((request) => {
                        return (
                          <div
                            className="requestformation_container"
                            key={request.id}
                          >
                            <div className="resquestformation_body">
                              <p>
                                Formation :{" "}
                                <span className="formaName">
                                  {request.Formation.formationName +
                                    " - " +
                                    request.Formation.formationLvl}
                                </span>{" "}
                              </p>
                            </div>
                            <div className="resquestformation_body">
                              <p>
                                Pour :{" "}
                                <span className="formaName">
                                  {request.user.firstname} {request.user.name}
                                </span>
                              </p>
                            </div>
                            <div className="resquestformation_body">
                              <RequestFormationStatus
                                formation={request}
                                formationId={request.id}
                              />
                            </div>
                            <div className="requestformation_date">
                              <p>Date souhaitée :</p>
                              <p>
                                <span className="formaName">
                                  {DateParser(request.formationDate)}
                                </span>
                              </p>
                            </div>
                            <div className="progress_formation">
                              <DeleteRequest formations={request} />
                            </div>
                          </div>
                        );
                      })}
                  </>
                </div>
                <div className="see_more">
                  {numOfFormations < requestFormations.length && (
                    <button
                      onClick={() => setNumOfFormations(numOfFormations + 6)}
                    >
                      Voir plus
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default AdminFormationRequestTermine;
