import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getRequestFormation } from "../../actions/requestFormation.actions";
import { getRequestKnowledge } from "../../actions/requestKnowledge";

const AdminMenu = () => {
  const [isReferent, setIsReferent] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const dispatch = useDispatch();
  const requestFormatonData = useSelector(
    (state) => state.requestFormationReducer
  );
  const knowledgeRequest = useSelector(
    (state) => state.requestKnowledgeReducer
  );
  const userData = useSelector((state) => state.userReducer);

  const formationWait = Object.values(requestFormatonData).filter(
    (formation) => {
      return formation.formationStatus === "0";
    }
  );
  const requestWait = Object.values(knowledgeRequest).filter((knowledge) => {
    return knowledge.requestStatus === "0";
  });

  const rows = formationWait.length;
  const rowsRequest = requestWait.length;

  useEffect(() => {
    dispatch(getRequestFormation());
    dispatch(getRequestKnowledge());
  }, [dispatch]);

  useEffect(() => {
    const checkAdmin = () => {
      if (userData.isAdmin) {
        setIsAdmin(true);
      } else if (userData.isReferent) {
        setIsReferent(true);
      }
    };
    checkAdmin();
  }, [userData.isAdmin, userData.isReferent]);

  return (
    <div className="leftBlock">
      <div className="left_menu">
        {isAdmin ? (
          <>
            <ul className="li">
              <i className="fa-solid fa-house"></i>
              <Link to={"/dashboard"}>Dashboard</Link>
            </ul>
            <ul className="li">
              <i className="fa-regular fa-address-card"></i>
              <Link to={"/adminUsers"}>Gérer les utilisateurs</Link>
            </ul>
            <ul className="li">
              <i className="far fa-plus-square"></i>
              <Link to={"/formation"}>Gérer les formations</Link>
            </ul>
            <ul className="li">
              <i className="fa-brands fa-stack-overflow"></i>
              <Link to={"/requestFormation"}>Demandes de formations</Link>
              <span className="numberRow">{rows}</span>
            </ul>
          </>
        ) : null}
        {isReferent || isAdmin ? (
          <ul className="li">
            <i className="far fa-newspaper"></i>
            <Link to={"/requestKnowledge"}>Demandes de Tutoriels</Link>
            <span className="numberRow"> {rowsRequest}</span>
          </ul>
        ) : null}
        {isAdmin ? (
          <ul className="li">
            <i className="far fa-chart-bar"></i>
            <Link to={"/alluserformation"}>Statistiques</Link>
          </ul>
        ) : null}
        {isAdmin ? (
          <ul className="li">
            <i className="fas fa-cloud-sun"></i>
            <Link to={"/nosoutils"}>Statut des outils</Link>
          </ul>
        ) : null}
        {isAdmin ? (
          <ul className="li">
            <i className="fa-solid fa-toolbox"></i>
            <Link to={"/nosoutilsExt"}>Coffre Fort</Link>
          </ul>
        ) : null}
        {isAdmin ? (
          <ul className="li">
            <i className="fa-solid fa-screwdriver-wrench"></i>
            <Link to={"/boiteaoutils"}>Boite à outils</Link>
          </ul>
        ) : null}
        {isAdmin ? (
          <ul className="li">
            <i className="fa-solid fa-headset"></i>
            <Link to={"/quicontacter"}>Qui Contacter </Link>
          </ul>
        ) : null}
        {isAdmin ? (
          <ul className="li">
            <i className="fa-solid fa-clipboard-question"></i>
            <Link to={"/createQuizz"}>Quizz</Link>
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default AdminMenu;
