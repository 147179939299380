import { combineReducers } from "redux";
import userReducer from "./user.reducer";
import usersReducer from "./Users.reducer";
import postReducer from "./post.reducer";
import commentReducer from "./comment.reducer";
import commentTutoReducer from "./commentTuto.reducer";
import likesReducer from "./likes.reducer";
import formationsReducer from "./formations.reducer";
import usersformationsReducer from "./usersFormation.reducer";
import requestFormationReducer from "./requestFormation.reducer";
import knowledgeReducer from "./knowledge.reducer";
import knowledgeFav from "./knowledgeFav.reducer";
import notificationReducer from "./notifications.reducer";
import requestKnowledgeReducer from "./requestKnowledge.reducer";
import scheduledPostReducer from "./scheduledPost.reducer";
import outilsReducer from "./outils.reducer";
import outilsExtReducer from "./outilsExt.reducer";
import boiteaoutilReducer from "./boiteaoutil.reducer";
import quicontacterReducer from "./quicontacter.reducer";
import quizFormReducer from "./quizz.reducer"

export default combineReducers({
  userReducer,
  usersReducer,
  postReducer,
  commentReducer,
  likesReducer,
  formationsReducer,
  usersformationsReducer,
  requestFormationReducer,
  knowledgeReducer,
  notificationReducer,
  knowledgeFav,
  requestKnowledgeReducer,
  scheduledPostReducer,
  outilsReducer,
  outilsExtReducer,
  boiteaoutilReducer,
  commentTutoReducer,
  quicontacterReducer,
  quizFormReducer
});
