import React, { useContext, useState, useRef, useEffect } from "react";
import Nav from "../Components/Nav/Nav";
import Login from "./Login";
import Menu from "../Components/Nav/Menu";
import Converter from "../Components/utils/Converter";
import { UidContext } from "../Components/App.Context";
import Crop from "../Components/utils/Crop";
import CardTools from "../Components/utils/CardTools";
import Video from "../asset/Loading.mp4";
import IframeYoutube from "../Components/utils/iframeYoutube";
import ImgToTxt from "../Components/utils/imgToTxt";
// import OpenAi from "../Components/utils/Openai";
import { useDispatch, useSelector } from "react-redux";
import { getBoiteOutils } from "../actions/boiteaoutils.actions";
import OxatisConverter from "../Components/utils/OxatisConverter";
import WebpConverter from "../Components/utils/WebpConverter";

const Convertisseur = ({ socket }) => {
  const uid = useContext(UidContext);
  const outilsData = useSelector((state) => state.boiteaoutilReducer);
  const [isOpenConverter, setIsOpenConverter] = useState(false);
  const [isOpenConverterOxatis, setIsOpenConverterOxatis] = useState(false);
  const [isOpenCrop, setIsOpenCrop] = useState(false);
  const [isOpenYoutube, setIsOpenYoutube] = useState(false);
  const [isOpenTextConvert, setIsOpenTextConvert] = useState(false);
  const [isOpenExt, setIsOpenExt] = useState(false);
  // const [isOpenOpenAI, setIsOpenOpenAI] = useState(false);
  const [isOpenOpenWEBP, setIsOpenOpenWEBP] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showCardTools, setShowCardTools] = useState(false);
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const dispatch = useDispatch();

  const contentRef = useRef(null);
  const toggleConverter = () => {
    setIsOpenConverter(!isOpenConverter);
  };

  const toggleConverterOxatis = () => {
    setIsOpenConverterOxatis(!isOpenConverterOxatis);
  };

  const toggleCrop = () => {
    setIsOpenCrop(!isOpenCrop);
  };

  const toggleOpenWEBP = () => {
    setIsOpenOpenWEBP(!isOpenOpenWEBP);
  };

  const toggleYoutube = () => {
    setIsOpenYoutube(!isOpenYoutube);
  };

  const toggleTextConvert = () => {
    setIsOpenTextConvert(!isOpenTextConvert);
  };

  // const toggleOpenAI = () => {
  //   setIsOpenOpenAI(!isOpenOpenAI);
  // };

  const toggleExt = (e) => {
    e.stopPropagation();
    if (contentRef.current.contains(e.target)) {
      return;
    }

    setIsOpenExt(!isOpenExt);
  };

  const handleShowCredentials = (e) => {
    e.preventDefault();

    setIsButtonDisabled(true);
    setIsLoading(true);

    setTimeout(() => {
      if (passwordInput === "Production") {
        setIsPasswordCorrect(true);
        setMessage("Ouverture du Coffre !");
        setShowVideo(true);
        setTimeout(() => {
          setShowCardTools(true);
          setIsButtonDisabled(false);
          setIsLoading(false);
        }, 7000);
      } else {
        setMessage("Mauvaise réponse");
        setIsButtonDisabled(false);
        setIsLoading(false);
      }
    }, 3000);
  };

  const handleCloseModal = () => {
    setIsOpenExt(false);
  };

  const handleButtonClick = (category) => {
    setQuery(category);
  };

  useEffect(() => {
    dispatch(getBoiteOutils());
  }, [dispatch]);

  return (
    <>
      {uid ? (
        <>
          <Nav socket={socket} />
          <div className="mainContainer">
            <Menu />
            <div className="center-Block">
              <div
                className="toolsMainContainer img-banner"
                onClick={toggleExt}
              >
                <div
                  className={`crop_img ${isOpenExt ? "active_crop_img" : ""}`}
                >
                  <div
                    ref={contentRef}
                    className="information"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExt();
                    }}
                  >
                    {isPasswordCorrect ? (
                      <>
                        {showCardTools ? (
                          <div
                            className={`CardTools animate-from-left-to-right`}
                          >
                            <CardTools isOpenExt={isOpenExt} />
                          </div>
                        ) : (
                          <>
                            {showVideo ? (
                              <div className="video-container">
                                <video src={Video} autoPlay muted />
                              </div>
                            ) : null}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="close" onClick={handleCloseModal}>
                          <i className="far fa-times-circle"></i>
                        </div>
                        <div className="col-left">
                          <h1 class="main-title">
                            Pour dévérouiller le coffre
                          </h1>
                          <div class="text-container info">
                            <div class="text-header">
                              <h2 class="text-title">
                                Résoudre l'énigme suivante :
                              </h2>
                            </div>
                            <div class="text-body">
                              <ol>
                                <li>1 - Je suis un service de Local.fr</li>
                                <li>
                                  2 - Je suis dirigé par un célébre nom du
                                  Tennis
                                </li>
                                <li>
                                  3 - Ma première lettre commence par une
                                  majuscule
                                </li>
                                <li>4 - Je suis, je suis ?</li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        <div className="col-right">
                          <div className="coffre">
                            <div className="formPass">
                              <form onSubmit={handleShowCredentials}>
                                <input
                                  className="lock-password"
                                  type="password"
                                  placeholder="**********"
                                  value={passwordInput}
                                  onChange={(e) =>
                                    setPasswordInput(e.target.value)
                                  }
                                  required
                                />
                                <button
                                  type="submit"
                                  className="check-pass"
                                  disabled={isButtonDisabled}
                                >
                                  {isLoading ? (
                                    <i className="fa-solid fa-fingerprint fa-fade"></i>
                                  ) : (
                                    "Valider"
                                  )}
                                </button>
                                {message && (
                                  <p
                                    className={
                                      message === "Mauvaise réponse"
                                        ? "error-message"
                                        : "success-message"
                                    }
                                  >
                                    {message}
                                  </p>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="tools-menu">
                <span className="titre">Outils Skills</span>
              </div>

              <div className="toolsMainContainer">
                <div className="tools_img">
                  <div className="tools_info">
                    <i className="far fa-image icone"></i>
                    <div className="tools_name" onClick={toggleCrop}>
                      Redimensionner une image
                    </div>
                  </div>
                  <div className="tool-desc" onClick={toggleCrop}>
                    <p>
                      Redimmensionner une image directement sous plusieurs
                      formats pré-définis
                    </p>
                  </div>
                  <div
                    className={`crop_img ${
                      isOpenCrop ? "active_crop_img" : ""
                    }`}
                  >
                    <div className="close" onClick={toggleCrop}>
                      <i className="far fa-times-circle"></i>
                    </div>
                    <h3>Redimensionner une image</h3>
                    <div className="content_crop">
                      <Crop />
                    </div>
                  </div>
                </div>
                <div className="tools_img">
                  <div className="tools_info" onClick={toggleTextConvert}>
                    <i className="fa-solid fa-wand-magic-sparkles icone"></i>
                    <div className="tools_name">
                      Extraire le texte d'une image
                    </div>
                  </div>
                  <div className="tool-desc" onClick={toggleTextConvert}>
                    <p>
                      Vous pouvez extraire le texte d'une image simplement,
                      utile pour les codes AUTH & Clés API
                    </p>
                  </div>
                  <div
                    className={`crop_img ${
                      isOpenTextConvert ? "active_overlay" : ""
                    }`}
                  >
                    <div className="close" onClick={toggleTextConvert}>
                      <i className="far fa-times-circle"></i>
                    </div>
                    <h3>Extraire le texte d'une image</h3>
                    <div className="content">
                      <ImgToTxt />
                    </div>
                  </div>
                </div>
                <div className="tools_img">
                  <div className="tools_info" onClick={toggleOpenWEBP}>
                    <i className="fa-solid fa-repeat icone"></i>
                    <div className="tools_name">
                      Convertir des images au format WEBP
                    </div>
                  </div>
                  <div className="tool-desc" onClick={toggleOpenWEBP}>
                    <p>Convertir des images au format WEBP</p>
                  </div>
                  <div
                    className={`crop_img ${
                      isOpenOpenWEBP ? "active_overlay" : ""
                    }`}
                  >
                    <div className="close" onClick={toggleOpenWEBP}>
                      <i className="far fa-times-circle"></i>
                    </div>
                    <h3> Convertir des images au format WEBP</h3>
                    <div className="content">
                      <WebpConverter />
                    </div>
                  </div>
                </div>
                <div className="tools_img">
                  <div className="tools_info" onClick={toggleYoutube}>
                    <i className="fab fa-youtube icone"></i>
                    <div className="tools_name">Générer une iframe YouTube</div>
                  </div>
                  <div className="tool-desc" onClick={toggleYoutube}>
                    <p>
                      Paramétrer l'intégration d'une iFrame YouTube simplement
                      et rapidement
                    </p>
                  </div>
                  <div
                    className={`crop_img ${
                      isOpenYoutube ? "active_overlay" : ""
                    }`}
                  >
                    <div className="close" onClick={toggleYoutube}>
                      <i className="far fa-times-circle"></i>
                    </div>
                    <h3>Générer une iframe YouTube</h3>
                    <div className="content">
                      <IframeYoutube />
                    </div>
                  </div>
                </div>
                <div className="tools_img">
                  <div className="tools_info" onClick={toggleConverter}>
                    <i class="fa-solid fa-file-csv icone"></i>
                    <div className="tools_name">
                      Transformer un fichier pour un LocalBoutique
                    </div>
                  </div>
                  <div className="tool-desc" onClick={toggleConverter}>
                    <p>
                      Transformer le fichier excel des produits d'un Toolbox
                      vers un LocalBoutique
                    </p>
                  </div>
                  <div
                    className={`crop_img ${
                      isOpenConverter ? "active_overlay" : ""
                    }`}
                  >
                    <div className="close" onClick={toggleConverter}>
                      <i className="far fa-times-circle"></i>
                    </div>
                    <h3>Transformer un fichier pour un localBoutique</h3>
                    <div className="content">
                      <Converter />
                    </div>
                  </div>
                </div>
                <div className="tools_img">
                  <div className="tools_info" onClick={toggleConverterOxatis}>
                    <i class="fa-solid fa-file-csv icone"></i>
                    <div className="tools_name">
                      Transformer un fichier pour un WiziShop
                    </div>
                  </div>
                  <div className="tool-desc" onClick={toggleConverterOxatis}>
                    <p>
                      Transformer le fichier excel des produits d'un Oxatis vers
                      un WiziShop
                    </p>
                  </div>
                  <div
                    className={`crop_img ${
                      isOpenConverterOxatis ? "active_overlay" : ""
                    }`}
                  >
                    <div className="close" onClick={toggleConverterOxatis}>
                      <i className="far fa-times-circle"></i>
                    </div>
                    <h3>Transformer un fichier pour un localBoutique</h3>
                    <div className="content">
                      <OxatisConverter />
                    </div>
                  </div>
                </div>

                {/* <div className="tools_img">
                  <div className="tools_info" onClick={toggleOpenAI}>
                    <i className="fa-solid fa-wand-magic-sparkles icone"></i>
                    <div className="tools_name">Aide à la rédaction</div>
                  </div>
                  <div className="tool-desc" onClick={toggleOpenAI}>
                    <p>
                      Aide à la rédaction d'une fiche produit boutique /
                      ecommerce
                    </p>
                  </div>
                  <div
                    className={`crop_img ${
                      isOpenOpenAI ? "active_overlay" : ""
                    }`}
                  >
                    <div className="close" onClick={toggleOpenAI}>
                      <i className="far fa-times-circle"></i>
                    </div>
                    <h3>Aide à la rédaction</h3>
                    <div className="content">
                      <OpenAi />
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="tools-menu">
                <span className="titre">Boite à outils</span>
                <div className="filtre">
                  <label htmlFor="categories-select">
                    Filtrer par catégories :
                  </label>
                  <select
                    id="categories-select"
                    onChange={(e) => handleButtonClick(e.target.value)}
                  >
                    <option value="">Toutes les catégories</option>
                    <option value="NDD">NDD/Mails</option>
                    <option value="Design">Design</option>
                    <option value="Utilitaires">Utilitaires</option>
                    <option value="SEO">SEO</option>
                  </select>
                </div>
              </div>
              <div className="toolsMainContainer_outils">
                {Object.values(outilsData)
                  .filter(
                    (outil) =>
                      outil.name.includes(query) ||
                      outil.categorie.includes(query)
                  )
                  .map((outil) => {
                    return (
                      <div className="tools_img">
                        <a href={outil?.link} target="_blank" rel="noreferrer">
                          <div className="hover-overlay">
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                          </div>
                          <div className="tools_info" key={outil.id}>
                            <div className="tools_name">
                              <img src={outil?.image} alt="image_outil" />
                            </div>
                            <div className="tools_name">{outil?.name}</div>
                          </div>
                          <div className="tool-desc">
                            <p>{outil?.description}</p>
                          </div>
                        </a>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default Convertisseur;
