import axios from "axios";
export const GET_LIKE = "GET_LIKE";
export const LIKE_POST = "LIKE_POST";
export const UNLIKE_POST = "UNLIKE_POST";

export const getLikes = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/message/like/all`,
      { withCredentials: true }
    );
    dispatch({
      type: GET_LIKE,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const likePost = (postId, userId) => {
  return async (dispatch) => {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/message/like/${postId}`,
      withCredentials: true,
    })
      .then((res) => {
        dispatch({ type: LIKE_POST, payload: { postId, userId } });
      })
      .catch((error) => console.error(error));
  };
};

export const unlikePost = (postId, userId) => {
  return async (dispatch) => {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/message/unlike/${postId}`,
      withCredentials: true,
    })
      .then((res) => {
        dispatch({ type: UNLIKE_POST, payload: { postId, userId } });
      })
      .catch((error) => console.error(error));
  };
};
