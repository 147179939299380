import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../../Components/Logo/Logo";
import Logout from "../Log/Logout";
import ImageProfil from "../Profil/ImageProfil";
import Notification from "../Notification/notification";

const Nav = ({ socket }) => {
  const [query, setQuery] = useState("");

  const searchToggle = () => {
    const toggleSearch = document.querySelector(".userlist");
    toggleSearch.classList.toggle("active");
  };

  const userData = useSelector((state) => state.userReducer);
  const usersData = useSelector((state) => state.usersReducer);

  const menuToggle = () => {
    const toggleMenu = document.querySelector(".profil_menu");
    toggleMenu.classList.toggle("active");
  };

  useEffect(() => {
    socket?.emit("newUser", userData.name + " " + userData.firstname);
  }, [socket, userData.name]);

  return (
    <div className="Navheader">
      <div className="logo">
        <Logo />
      </div>
      <div className="search">
        <div className="search-icon-container">
          <i className="fa-solid fa-magnifying-glass search-icon"></i>
        </div>
        <input
          type="text"
          placeholder=" Rechercher un utilisateur"
          className="searchbar"
          onChange={(e) => setQuery(e.target.value)}
          onClick={searchToggle}
        />
        <ul className="userlist">
          <div className="close" onClick={searchToggle}>
            <i className="far fa-times-circle"></i>
          </div>
          {Array.isArray(usersData) &&
            usersData
              .filter(
                (users) =>
                  users.firstname.toLowerCase().includes(query) ||
                  users.name.toLowerCase().includes(query)
              )
              .slice(0, 3)
              .map((users) => (
                <li className="liste" key={users.id}>
                  <Link to={`/userprofil/${users.id}`} key={users.id}>
                    <div className="img">
                      <ImageProfil id={users.id} />
                    </div>
                    <div className="name">
                      {users.firstname} {users.name}
                    </div>
                  </Link>
                </li>
              ))}
        </ul>
      </div>

      <div className="action">
        <Notification user={userData.id} socket={socket} />
        <p>
          Bienvenue <span>{userData.firstname}</span> 👋
        </p>{" "}
        <div className="profil" onClick={menuToggle}>
          <ImageProfil id={userData.id} />
        </div>
        <div className="profil_menu">
          <h3>
            {userData.firstname} {userData.name} <br />
            <span> {userData.service}</span>
          </h3>

          <ul>
            <li>
              <Link to={`/userprofil/${userData.id}`}>Mon profil</Link>
            </li>

            {userData.isAdmin ? (
              <li>
                <Link to={`/dashboard`}>Administration</Link>
              </li>
            ) : null}

            <Logout />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Nav;
