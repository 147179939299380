import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import {
  editRequestStatus,
  getRequestKnowledge,
} from "../../actions/requestKnowledge";

const KnowledgeStatus = ({ knowledge, closeModalValidation }) => {
  const formationStatusConfig = [
    { value: "", label: "Modifier le statut..." },
    { value: "0", label: "🔔 A traiter..." },
    { value: "2", label: "✅ Terminé" },
    { value: "3", label: "⛔ Annulé" },
  ];
  const [requestStatus, setrequestStatus] = useState("");
  const dispatch = useDispatch();

  const knowledgeID = knowledge?.id;

  async function colorSelect(e) {
    e.preventDefault();
    if (requestStatus) {
      try {
        await dispatch(editRequestStatus(knowledgeID, requestStatus));
        dispatch(getRequestKnowledge());
        toast.success("Mise à jour");
        closeModalValidation();
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="validationKnowledge">
        <h3>Statut de la formation</h3>
        <p>
          Veuiller selectionner une option pour cette demande afin de la
          finaliser :
        </p>
        <br />
        <ul>
          <li>A traiter : Le remettre dans la file bac à sable</li>
          <li>Terminé : Vous avez publié la demande du tuto sur skills</li>
          <li>Annulé : La demande n'est pas réalisable ou déjà existante</li>
        </ul>
      </div>
      <form className="formStatus" onSubmit={colorSelect}>
        <select
          className="tessst"
          name="color-select"
          id="color-select"
          onChange={(e) => setrequestStatus(e.target.value)}
        >
          {formationStatusConfig.map((status) => (
            <option value={status.value}>{status.label}</option>
          ))}
        </select>
        <br />
        <input type="submit" className="test" value="Modifier" />
      </form>
    </>
  );
};

export default KnowledgeStatus;
