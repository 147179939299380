import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nav from "../Components/Nav/Nav";
import { UidContext } from "../Components/App.Context";
import Login from "./Login";
import Card from "../Components/Knowledge/Card";
import { getKnwoledge } from "../actions/knowledge.actions";
import { isEmpty } from "../Components/utils/Utils";
import AddKnowledge from "../Components/Knowledge/AddKnowledge";
import Menu from "../Components/Nav/Menu";
import { getKnwoledgeFav } from "../actions/knowledgeFav.actions";
import { addRequestKnowledge } from "../actions/requestKnowledge";
import Swal from "sweetalert2";
import CardFav from "../Components/Knowledge/CardFav";
import Lottie from "lottie-react";
import Brain from "../asset/brain.json";
import Student from "../asset/knowledge.png";

const Knowledge = ({ socket }) => {
  const [isAllow, setIsAllow] = useState(false);
  const [query, setQuery] = useState("");
  const [loadPost, setLoadPost] = useState(true);
  const [numberOfPostsToShow, setNumberOfPostsToShow] = useState(12);
  const [favoris, setFavoris] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [categorie, setCategorie] = useState("");
  const [texte, setTexte] = useState("");
  const [nature, setNature] = useState("");
  const [isFavoris, setIsFavoris] = useState(false);

  const userData = useSelector((state) => state.userReducer);
  const uid = useContext(UidContext);
  const dispatch = useDispatch();

  const knowledge = useSelector((state) => state.knowledgeReducer);
  const knowledgeFav = useSelector((state) => state.knowledgeFav);

  const handleButtonClick = (category) => {
    setQuery(category);
  };

  useEffect(() => {
    if (loadPost) {
      dispatch(getKnwoledge());
      setLoadPost(false);
    }
  }, [loadPost, dispatch]);

  useEffect(() => {
    if ((uid === userData.id && userData.isAdmin) || userData.isReferent) {
      setIsAllow(true);
    }
  }, [uid, userData]);

  useEffect(() => {
    dispatch(getKnwoledgeFav(userData.id));
    setFavoris(true);
  }, [dispatch, uid, userData.id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      categorie,
      texte,
      nature,
      userId: uid,
    };
    if (data) {
      dispatch(addRequestKnowledge(data));
      setShowDialog(false);
      Swal.fire("La demande est envoyée !");
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setNumberOfPostsToShow((prevValue) => prevValue + 5);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {uid ? (
        <>
          <Nav socket={socket} />
          <div className="mainContainer">
            <Menu />
            <div className="center-Block">
              <div className="knowledge-container">
                <div className="brain">
                  <Lottie animationData={Brain} loop={true} />
                </div>
                <div className="student">
                  <img src={Student} alt="" />
                </div>
                <div className="search-container">
                  <div className="search-icon-container">
                    <i className="fa-solid fa-magnifying-glass search-icon"></i>
                  </div>
                  <input
                    type="text"
                    placeholder="Rechercher un tuto ?"
                    className="searchbar_knowledge"
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </div>
              </div>
              <div className="knowledger-card">
                <div className="knwoledge-menu">
                  <span className="titre">Liste des Tutoriels</span>
                  <div
                    className="request_knowledge"
                    onClick={() => setShowDialog(true)}
                  >
                    Vous souhaitez un Tutoriel ?
                  </div>
                  {isAllow ? (
                    <AddKnowledge socket={socket} userData={userData} />
                  ) : null}
                </div>
                <div className="filtre">
                  <label htmlFor="categories-select">
                    Filtrer par catégories :
                  </label>
                  <select
                    id="categories-select"
                    onChange={(e) => handleButtonClick(e.target.value)}
                  >
                    <option value="">Toutes les catégories</option>
                    <option value="ndd">NDD/Mails</option>
                    <option value="localboutique">LocalBoutique</option>
                    <option value="localshop">LocalShop</option>
                    <option value="webtool 3">WebTool 3</option>
                    <option value="localagenda">LocalAgenda</option>
                    <option value="reservit">Reservit</option>
                    <option value="toolbox">Toolbox</option>
                    <option value="seo">SEO/SEM</option>
                    <option value="localetmoi">Local&Moi</option>
                  </select>
                  <div className="favSwitch">
                    <p>Afficher mes favoris :</p>
                    <div class="switch">
                      <input
                        type="checkbox"
                        id="favoris-switch"
                        checked={isFavoris}
                        onChange={() => setIsFavoris(!isFavoris)}
                      />
                      <span class="slider"></span>
                    </div>
                  </div>
                </div>
                <>
                  {showDialog ? (
                    <div className="dialog">
                      <div className="dialog-content">
                        <button
                          className="close-button"
                          onClick={() => setShowDialog(false)}
                        >
                          &times;
                        </button>
                        <h3>Demande de Tutoriel</h3>
                        <form onSubmit={handleSubmit}>
                          <label htmlFor="categories-select">
                            Catégories :
                          </label>
                          <select
                            id="categories-select"
                            onChange={(e) => setCategorie(e.target.value)}
                          >
                            <option value="">
                              Veuillez sélectionner la catégorie
                            </option>
                            <option value="ndd">NDD/Mails</option>
                            <option value="localboutique">LocalBoutique</option>
                            <option value="localshop">LocalShop</option>
                            <option value="webtool 3">WebTool 3</option>
                            <option value="localagenda">LocalAgenda</option>
                            <option value="reservit">Reservit</option>
                            <option value="toolbox">Toolbox</option>
                            <option value="seo">SEO/SEM</option>
                            <option value="localetmoi">Local&Moi</option>
                          </select>
                          <label htmlFor="categories-select">
                            Nature de la demande :
                          </label>
                          <select
                            id="nature-select"
                            onChange={(e) => setNature(e.target.value)}
                          >
                            <option value="">
                              Veuillez sélectionner la nature
                            </option>
                            <option value="Nouveau">Nouveau Tutoriel</option>
                            <option value="Mise à jour">
                              Mise à jour d'un tutoriel
                            </option>
                          </select>
                          <label htmlFor="description">
                            Description de la demande:
                          </label>
                          <textarea
                            id="description"
                            required
                            onChange={(e) => setTexte(e.target.value)}
                            value={texte}
                          />
                          <button className="submit_request" type="submit">
                            Envoyer la demande
                          </button>
                        </form>
                      </div>
                    </div>
                  ) : null}
                </>
              </div>
              <div className="thread-container">
                <li>
                  {isFavoris
                    ? !isEmpty(knowledgeFav[0]) &&
                      knowledgeFav.map((post) => (
                        <CardFav post={post} key={post.id} />
                      ))
                    : !isEmpty(knowledge[0]) &&
                      knowledge
                        .filter(
                          (post) =>
                            post.title.toLowerCase().includes(query) ||
                            post.categories.toLowerCase().includes(query)
                        )
                        .slice(0, numberOfPostsToShow)
                        .map((post) => <Card post={post} key={post.id} />)}
                </li>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default Knowledge;
