import React from "react";

const Loading = () => {
  return (
    <div className="loadingBackground">
      <div className="loading_content">
        <h1>
          <span className="skills">Skills</span>
          <span className="manager">Manager</span>
        </h1>
        <p>Chargement en cours...</p>
        <div class="loading-bar"></div>
      </div>
    </div>
  );
};

export default Loading;
