import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getRequestFormation,
  asignFormateur,
} from "../../actions/requestFormation.actions";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import { atcb_action } from "add-to-calendar-button";
import "add-to-calendar-button/assets/css/atcb.css";
import moment from "moment";

const AsignFormation = ({ request, closeModal }) => {
  const [formationDate, setformationDate] = useState(request?.formationDate);
  const [formationFormateur, setformationFormateur] = useState(
    request?.formationFormateur
  );
  const [showAssignButton, setShowAssignButton] = useState(false);
  const [isCalendarClicked, setIsCalendarClicked] = useState(false);

  const subformationId = request.id;
  const formationStatus = "10";

  const dispatch = useDispatch();

  const handleCreateForm = (e) => {
    e.preventDefault();
    dispatch(
      asignFormateur(
        subformationId,
        formationFormateur,
        formationDate,
        formationStatus
      )
    ).then(
      () => dispatch(getRequestFormation()),
      Swal.fire(
        "La formation est assignée au formateur!",
        "Avez-vous pensé à l'assigner dans le calendrier ?",
        "success"
      )
    );
    closeModal();
  };

  let momentDate = moment.utc(formationDate);
  let momentDuration = moment.duration(request?.Formation?.formationHours);
  momentDate.add(momentDuration);

  useEffect(() => {
    if (isCalendarClicked) {
      const timer = setTimeout(() => {
        setShowAssignButton(true);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isCalendarClicked]);

  return (
    <>
      <div className="accordion-content">
        <div className="Requestformation_container">
          <form action="" onSubmit={handleCreateForm}>
            <br />
            <label htmlFor="formationDate">Date de la formation</label>
            <input
              type="datetime-local"
              name="formationDate"
              className="input_signup"
              id="formationDate"
              defaultValue={formationDate}
              onChange={(e) => setformationDate(e.target.value)}
              required
            />
            <br />
            <label htmlFor="formationHour">Durée de formation</label>
            <input
              type="text"
              name="formationHour"
              className="input_signup"
              id="formationHour"
              value={request.Formation.formationHours}
              disabled="disabled"
            />
            <br />
            <label htmlFor="formationFormateur">
              Formateur pour cette formation (ex: Prénom NOM)
            </label>
            <input
              type="text"
              name="formationFormateur"
              className="input_signup"
              id="formationFormateur"
              defaultValue={formationFormateur || ""}
              onChange={(e) => setformationFormateur(e.target.value)}
              required
            />
            <br />
            {showAssignButton && (
              <input
                type="submit"
                className={
                  showAssignButton ? "submit top" : "submit top hidden"
                }
                value="Assigner la formation au Formateur"
              />
            )}
          </form>
          <br />

          <form
            className="calendar_assignation"
            onSubmit={(e) => {
              e.preventDefault();
              atcb_action({
                name:
                  "Formation " +
                  request.Formation.formationName +
                  " " +
                  request.Formation.formationLvl +
                  " " +
                  request.user.firstname +
                  " " +
                  request.user.name,
                description: `Formation ${request.Formation.formationName} - ${request.Formation.formationLvl} de ${request.user.firstname} ${request.user.name}`,
                startDate: formationDate,
                endDate: momentDate.format(),
                options: ["MicrosoftTeams"],
                timeZone: "Europe/Berlin",
                iCalFileName: "Reminder-Event",
              });
              setIsCalendarClicked(true);
            }}
          >
            <input
              type="submit"
              className="calendar"
              value="&#x1F4C6; Ajouter la formation dans le calendrier"
            />
          </form>
          <br />
        </div>
      </div>
    </>
  );
};

export default AsignFormation;
