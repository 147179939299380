import React, { useState } from "react";
import { useSelector } from "react-redux";
import ImageProfil from "../../Components/Profil/ImageProfil";
import RequestFormation from "../../Components/Formation/RequestFormation";
import { Link } from "react-router-dom";
import Logout from "../Log/Logout";
import Trending from "../../Pages/Trending";
import userProd from "../../asset/userProd.png";
// import Bonnet from "../../asset/bonnet-noel.png";

const Menu = () => {
  const userData = useSelector((state) => state.userReducer);

  const [showTrending, setShowTrending] = useState(false);
  const [showAssistantesModal, setShowAssistantesModal] = useState(false);

  const handleEquipeProductionClick = () => {
    setShowTrending(!showTrending);
  };

  const closeAssistantesModal = () => {
    setShowAssistantesModal(false);
  };

  return (
    <div className="leftBlock">
      <div className="left_Profil">
        <div className="profil_picture">
          {/* <div className="neige-event-profil">
            <img className="image-neige" src={Bonnet} alt="neige" />
          </div> */}
          <ImageProfil id={userData.id} />
        </div>
        <div className="profil_infos">
          <span>
            {userData.firstname} {userData.name}
          </span>{" "}
          <br />
          <p>@{userData.service}</p>
        </div>
      </div>
      <div className="left_menu">
        <ul className="li">
          <i className="fa-solid fa-house"></i>
          <Link to={`/home`}>Fil d'actualités</Link>
        </ul>
        <ul className="li">
          <i className="fa-regular fa-address-card"></i>
          <Link to={`/userprofil/${userData.id}`}>Mes compétences</Link>
        </ul>
        <ul className="li">
          <i className="fa-solid fa-brain"></i>
          <Link to={`/tutoriels`}>Les tutoriels</Link>
        </ul>
        <ul className="li">
          <i className="fa-solid fa-gear"></i>
          <Link to={`/convertisseur`}>Mes outils</Link>
        </ul>
        <ul className="li">
          <i className="fa-solid fa-arrow-right-from-bracket"></i>
          <Logout />
        </ul>
        {userData.isAdmin ? (
          <div className="admin">
            <div className="admin__button">
              <Link to={`/dashboard`}>Administration</Link>
            </div>
          </div>
        ) : null}
        {userData.isCoach ? <RequestFormation /> : null}
        {userData.isReferent ? (
          <div className="admin">
            <div className="admin__button">
              <Link to={`/requestKnowledge`}>Référent Tutoriels</Link>
            </div>
          </div>
        ) : null}

        <div className="admin">
          <div
            className="admin__button"
            onClick={() => setShowAssistantesModal(true)}
          >
            Carte des assistantes
          </div>
        </div>

        <div className="equipe_prod">
          <div className="userProdimg">
            <img src={userProd} alt="" />
          </div>
          <div className="equipe__button" onClick={handleEquipeProductionClick}>
            {showTrending ? <p>Cacher</p> : <p>Afficher l'équipe Production</p>}
          </div>
        </div>
        {showTrending && (
          <div className="trendingPro">
            <Trending />
          </div>
        )}
        {showAssistantesModal && (
          <div className="modal">
            <div
              className="modal-content full-width-modal"
              style={{ position: "relative" }}
            >
              <button className="close-button" onClick={closeAssistantesModal}>
                <i className="fa-regular fa-circle-xmark"></i>
              </button>
              <h2>Carte des assistantes</h2>
              <div style={{ width: "auto" }}>
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "56.25%",
                    paddingTop: 0,
                    height: 0,
                  }}
                >
                  <iframe
                    title="Genially sans titre"
                    frameBorder="0"
                    width="1200px"
                    height="675px"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    src="https://view.genial.ly/65aa7383cb64fc0014c305af"
                    type="text/html"
                    allowscriptaccess="always"
                    allowfullscreen="true"
                    scrolling="yes"
                    allownetworking="all"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;
