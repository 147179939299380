import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nav from "../Components/Nav/Nav";
import Login from "./Login";
import { getAllUsersFormation } from "../actions/usersFormation.actions";
import { isEmpty } from "../Components/utils/Utils";
import { CSVLink } from "react-csv";
import AdminMenu from "../Components/Admin/AdminMenu";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  PieChart,
  Pie,
  Cell,
} from "recharts";

import { getUsers } from "../actions/users.actions";

const AllUserFormation = () => {
  const [loading, setLoading] = useState(true);
  const [numOfFormations, setNumOfFormations] = useState(5);
  const [selectedFormation, setSelectedFormation] = useState("");
  const [chartData, setChartData] = useState([]);

  const userData = useSelector((state) => state.userReducer);
  const usersData = useSelector((state) => state.usersReducer);
  const FormationsData = useSelector((state) => state.usersformationsReducer);
  const dataFormations = Object.values(FormationsData).filter((formation) => {
    return selectedFormation
      ? formation.Formation.formationName === selectedFormation
      : formation;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsersFormation()).then(() => {
      setLoading(false);
      createChartData();
    });
    dispatch(getUsers());
  }, [dispatch]);

  const headers = [
    { label: "Nom", key: "Nom" },
    { label: "Prenom", key: "Prenom" },
    { label: "Formation", key: "Formation" },
    { label: "Niveau", key: "Niveau" },
    { label: "Date", key: "Date" },
    { label: "Formateur", key: "Formateur" },
  ];

  let data = [];

  dataFormations.forEach((users) => {
    if (
      !selectedFormation ||
      users?.Formation?.formationName === selectedFormation
    ) {
      data.push({
        Nom: users?.user?.name,
        Prenom: users?.user?.firstname,
        Formation: users?.Formation?.formationName,
        Niveau: users?.Formation?.formationLvl,
        Date: users?.formationDate,
        Formateur: users?.formationFormateur,
      });
    }
  });

  const handleFormationChange = (event) => {
    const selectedFormation = event.target.value;
    setSelectedFormation(selectedFormation);
    createChartData();
  };

  const createChartData = () => {
    const formations = Array.from(
      new Set(dataFormations.map((users) => users?.Formation?.formationName))
    );

    const data = formations.map((formation) => {
      const usersFormed = dataFormations.filter(
        (user) =>
          (!selectedFormation ||
            user?.Formation?.formationName === selectedFormation) &&
          user?.Formation?.formationName === formation &&
          user?.Formation?.formationLvl
      ).length;

      const totalUsers = usersData?.length;

      const usersNotFormed = totalUsers - usersFormed;

      return {
        name: formation,
        Formés: usersFormed,
        "Non formés": usersNotFormed,
      };
    });

    setChartData(data);
  };

  const createPieChartData = () => {
    const formateurs = Array.from(
      new Set(dataFormations.map((users) => users?.formationFormateur))
    );

    const pieChartData = formateurs.map((formateur) => {
      const formationsRealisees = dataFormations.filter(
        (user) =>
          (!selectedFormation ||
            user?.Formation?.formationName === selectedFormation) &&
          user?.formationFormateur === formateur &&
          user?.Formation?.formationLvl
      ).length;

      return {
        name: formateur,
        value: formationsRealisees,
      };
    });

    return pieChartData;
  };

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#AF19FF"];

  return (
    <>
      {userData.isAdmin ? (
        <>
          {loading ? (
            <div>Chargement en cours...</div>
          ) : (
            <>
              <Nav />
              <div className="mainContainer">
                <AdminMenu />
                <div className="centerBlock_admin">
                  <h1>Utilisateurs Formés</h1>
                  <div className="telecharger">
                    <CSVLink
                      data={data}
                      headers={headers}
                      filename={"skillsManager-export.csv"}
                    >
                      Télécharger en CSV
                    </CSVLink>
                  </div>
                  <div className="select-formation">
                    <label htmlFor="formation-select">
                      Filtrer par formation:
                    </label>
                    <select
                      id="formation-select"
                      onChange={handleFormationChange}
                    >
                      <option value="">Toutes les formations</option>
                      {FormationsData.map((formation) => (
                        <option
                          key={formation?.id}
                          value={formation?.Formation?.formationName}
                        >
                          {formation?.Formation?.formationName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="graphique">
                    <BarChart width={400} height={400} data={chartData}>
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Legend />
                      <Tooltip />
                      <Bar
                        dataKey="Formés"
                        stackId="formation"
                        fill="#36A2EB"
                      />
                      <Bar
                        dataKey="Non formés"
                        stackId="formation"
                        fill="#FF6384"
                      />
                    </BarChart>

                    <PieChart width={400} height={400}>
                      <Pie
                        data={createPieChartData()}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        fill="#8884d8"
                        label
                      >
                        {createPieChartData().map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </div>

                  <table id="table-to-xls">
                    <thead>
                      <tr>
                        <th>Nom</th>
                        <th>Prénom</th>
                        <th>Formation</th>
                        <th>Niveau</th>
                        <th>Date</th>
                        <th>Formateur</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(dataFormations[0]) &&
                        dataFormations.map((users) => {
                          return (
                            <tr key={users?.id}>
                              <td>{users?.user && users?.user?.name}</td>
                              <td>{users?.user && users?.user?.firstname}</td>
                              <td>{users?.Formation?.formationName}</td>
                              <td>{users?.Formation?.formationLvl}</td>
                              <td>{users?.formationDate}</td>
                              <td>{users?.formationFormateur}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <div className="see_more">
                    {numOfFormations < dataFormations.length && (
                      <button
                        onClick={() => setNumOfFormations(numOfFormations + 6)}
                      >
                        Voir plus
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default AllUserFormation;
