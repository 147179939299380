import React, { useState } from "react";
import { useSelector } from "react-redux";
import Nav from "../Components/Nav/Nav";
import Login from "./Login";
import { DateParser } from "../Components/utils/Utils";
import AdminMenu from "../Components/Admin/AdminMenu";
import KnowledgeMenu from "../Components/Knowledge/knowledgeMenu";
import KnowledgeStatus from "../Components/Knowledge/KnowledgeStatus";

const AdminKnowledgeAnnule = () => {
  const [numOfFormations, setNumOfFormations] = useState(5);
  const [activeCurrentIndex, setActiveCurrentIndex] = useState();

  const userData = useSelector((state) => state.userReducer);
  const knowledgeRequest = useSelector(
    (state) => state.requestKnowledgeReducer
  );

  const requestWait = Object.values(knowledgeRequest).filter((knowledge) => {
    return knowledge.requestStatus === "3";
  });

  const toggleShowAccordion = (id) => {
    setActiveCurrentIndex(id);
  };

  return (
    <>
      {userData.isAdmin || userData.isReferent ? (
        <>
          <Nav />
          <div className="mainContainer">
            <AdminMenu />
            <div className="centerBlock_admin">
              <div className="useronline-container-admin">
                <div className="requestformation">
                  <>
                    <KnowledgeMenu active="annule" />

                    {requestWait
                      .slice(0, numOfFormations)
                      .map((request, id) => {
                        return (
                          <div
                            className="accordion-item"
                            key={id}
                            onClick={() => toggleShowAccordion(id)}
                          >
                            <div className="requestknowledge" key={request.id}>
                              <div className="resquestknowledge_body">
                                <p>
                                  De :{" "}
                                  <span className="formaName">
                                    {request.user.firstname} {request.user.name}
                                  </span>
                                </p>
                              </div>
                              <div className="resquestknowledge_body">
                                <p>
                                  Assignée à :{" "}
                                  <span className="formaName">
                                    {request.referent.firstname}{" "}
                                    {request.referent.name}
                                  </span>
                                </p>
                              </div>
                              <div className="resquestknowledge_body">
                                {request.nature}
                              </div>
                              <div className="requestknowledge_cat">
                                <p>Catégorie:</p>
                                <span className="formaName">
                                  {request.categorie}
                                </span>
                              </div>
                              <div className="requestknowledge_date">
                                <p>Date de la demande :</p>
                                <p>
                                  <span className="formaName">
                                    {DateParser(request.createdAt)}
                                  </span>
                                </p>
                              </div>
                            </div>
                            {activeCurrentIndex === id && (
                              <div className="resquestknowledge_content">
                                <p>
                                  Demande :{" "}
                                  <span className="formaName">
                                    {request.texte}
                                  </span>
                                </p>
                                <div className="resquestformation_body">
                                  <KnowledgeStatus knowledge={request} />
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </>
                </div>
                <div className="see_more">
                  {numOfFormations < requestWait.length && (
                    <button
                      onClick={() => setNumOfFormations(numOfFormations + 6)}
                    >
                      Voir plus
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default AdminKnowledgeAnnule;
