import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuiContacter } from "../../actions/quicontacter.actions";
import parse from "html-react-parser";
import Lottie from "lottie-react";
import Question from "../../asset/question.json";
import Interogation from "../../asset/interogation.json";
import Computer from "../../asset/computer.png";

const QuiContacterForm = () => {
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [selectedSujet, setSelectedSujet] = useState("");
  const [solution, setSolution] = useState("");

  const dispatch = useDispatch();

  const quicontacter = useSelector((state) => state.quicontacterReducer);

  useEffect(() => {
    dispatch(getQuiContacter());
  }, [dispatch]);

  const handleCategorieChange = (e) => {
    const selectedCategorie = e.target.value;
    setSelectedCategorie(selectedCategorie);
    setSelectedSujet("");
    setSolution("");
  };

  const handleSujetChange = (e) => {
    const selectedSujet = e.target.value;
    setSelectedSujet(selectedSujet);
    const selectedSolution = quicontacter.find(
      (item) =>
        item.categorie === selectedCategorie && item.sujet === selectedSujet
    );
    const solution = selectedSolution ? selectedSolution.resolution : "";
    setSolution(solution);
  };

  return (
    <div>
      <div className="information_bloc">
        <div className="bloc">
          <Lottie animationData={Question} loop={true} />
        </div>
        <div className="computer">
          <img src={Computer} alt="" />
        </div>
        <div className="rightBloc">
          <div className="interogation">
            <Lottie animationData={Interogation} loop={false} />
          </div>
          <div className="content_quicontacter">
            <label htmlFor="categorie">Thématique:</label>
            <select
              id="categorie"
              value={selectedCategorie}
              onChange={handleCategorieChange}
            >
              <option value="">Sélectionnez une thématique</option>
              {Array.isArray(quicontacter) && quicontacter.length > 0 ? (
                [...new Set(quicontacter.map((item) => item.categorie))].map(
                  (categorie) => (
                    <option key={categorie} value={categorie}>
                      {categorie}
                    </option>
                  )
                )
              ) : (
                <option disabled>Aucune catégorie disponible</option>
              )}
            </select>

            <label htmlFor="sujet">Sujet:</label>
            <select
              id="sujet"
              value={selectedSujet}
              onChange={handleSujetChange}
              disabled={!selectedCategorie}
            >
              <option value="">Sélectionnez un sujet</option>
              {Array.isArray(quicontacter) && quicontacter.length > 0 ? (
                quicontacter
                  .filter((item) => item.categorie === selectedCategorie)
                  .map((item) => (
                    <option key={item.id} value={item.sujet}>
                      {item.sujet}
                    </option>
                  ))
              ) : (
                <option disabled>Aucun sujet disponible</option>
              )}
            </select>
          </div>
          {solution ? (
            <div className="solution">
              <div className="solutionContent">
                <span>A qui m'adresser et comment :</span>
                <p>{parse(solution)}</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default QuiContacterForm;
