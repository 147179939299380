import React, { useState, useEffect } from "react";
import axios from "axios";
import ImageProfil from "../Profil/ImageProfil";

const AdminQuizzResult = ({ quiz, onClose }) => {
  const [quizResults, setQuizResults] = useState([]);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    const fetchQuizResults = async () => {
      try {
        const response = await axios.get("/api/quizz/get-quiz-results", {
          params: {
            quizId: quiz,
          },
        });
        setQuizResults(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchQuizResults();
  }, [quiz]);

  const Overlay = ({ children }) => {
    return <div className="overlay">{children}</div>;
  };

  const handleToggleResults = () => {
    setShowResults(!showResults);
  };

  return (
    <Overlay>
      <div className="quiz-modal">
        <div className="quiz-summary">
          <h3>Résultat du Quizz</h3>
          <>
            <div className="podium">
              <p>🏆 Score des utilisateurs qui ont réalisé le quizz 🏆</p>
              <ul>
                {quizResults
                  .sort((a, b) => b.score - a.score)
                  .map((result, index) => (
                    <li key={index}>
                      <div className="user">
                        {index === 0 ? (
                          <span role="img" aria-label="trophy">
                            🏆
                          </span>
                        ) : index === 1 ? (
                          <span role="img" aria-label="second-place">
                            🥈
                          </span>
                        ) : index === 2 ? (
                          <span role="img" aria-label="third-place">
                            🥉
                          </span>
                        ) : (
                          <>{index + 1} - </>
                        )}
                        <ImageProfil id={result?.user?.id} />{" "}
                        {result?.user?.firstName} {result?.user?.name}:{" "}
                        {result?.score} points
                      </div>
                      <>
                        {showResults && (
                          <div className="answer">
                            {result?.selectedAnswers.map((answer, index) => (
                              <p key={index}>{answer}</p>
                            ))}
                          </div>
                        )}
                      </>
                      <button onClick={handleToggleResults}>
                        {showResults
                          ? "Cacher les réponses"
                          : "Afficher les réponses"}
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
          </>
          <button onClick={onClose} className="finish">
            Revenir sur l'admin
          </button>
        </div>
      </div>
    </Overlay>
  );
};

export default AdminQuizzResult;
