import React from "react";
import { Link } from "react-router-dom";

const FormationMenu = (props) => {
  return (
    <div className="progressbar-wrapper">
      <ul className="progressbar">
        <li
          className={
            props.active === "requestFormation" ||
            props.active === "planifie" ||
            props.active === "termine" ||
            props.active === "annule"
              ? "active"
              : ""
          }
        >
          <Link to={"/requestFormation"}>
            <p>A traiter </p>
          </Link>
        </li>
        <li
          className={
            props.active === "planifie" ||
            props.active === "termine" ||
            props.active === "annule"
              ? "active"
              : ""
          }
        >
          <Link to={"/planifie"}>
            <p>Planifiée </p>
          </Link>
        </li>
        <li
          className={
            props.active === "termine" || props.active === "annule"
              ? "active"
              : ""
          }
        >
          <Link to={"/termine"}>
            <p>Terminée </p>
          </Link>
        </li>
        <li className={props.active === "annule" ? "active-red" : ""}>
          {" "}
          <Link to={"/annule"}>
            <p>Annulée </p>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default FormationMenu;
