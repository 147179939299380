import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const TutoCategorieChart = ({ tutosData }) => {
  const tuto = Object.values(tutosData);

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        type: "donut",
        height: 280,
        toolbar: {
          show: false,
        },
      },
      colors: ["#ff7000", "#ffac4a", "#ffe9c9", "#f9c87c", "#f97432"],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        colors: ["#fff"],
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: "right",
      },
      labels: [],
    },
    series: [],
  });

  useEffect(() => {
    const categoryCounts = {};
    tuto.forEach((tuto) => {
      const { categories } = tuto;
      if (categoryCounts[categories]) {
        categoryCounts[categories]++;
      } else {
        categoryCounts[categories] = 1;
      }
    });

    const categories = Object.keys(categoryCounts);
    const tutoCounts = Object.values(categoryCounts);

    setChartData((prevData) => ({
      ...prevData,
      options: {
        ...prevData.options,
        labels: categories,
      },
      series: tutoCounts,
    }));
  }, [tutosData]);

  return (
    <div className="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="donut"
        height="250"
      />
    </div>
  );
};

export default TutoCategorieChart;
