import axios from "axios";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const READ_NOTIFICATIONS = "READ_NOTIFICATIONS";

export const getNotifications = (userId) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/notification/${userId}`,
      {
        withCredentials: true,
      }
    );
    dispatch({ type: GET_NOTIFICATIONS, payload: response.data.reverse() });
  } catch (error) {
    console.error(error);
  }
};

export const readNotification = (notificationId) => {
  return async (dispatch) => {
    try {
      await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/api/notification/${notificationId}`,
        withCredentials: true,
      });
      dispatch({
        type: READ_NOTIFICATIONS,
        payload: notificationId,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
