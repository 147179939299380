import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nav from "../Components/Nav/Nav";
import Login from "./Login";
import AdminMenu from "../Components/Admin/AdminMenu";
import {
  createQuiContacter,
  getQuiContacter,
  editeQuiContacter,
  deleteQuiContacter,
} from "../actions/quicontacter.actions";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import parse from "html-react-parser";
import { useTable, usePagination } from "react-table";
import { ToastContainer, toast } from "react-toastify";

const AdminQuiContacter = () => {
  const userData = useSelector((state) => state.userReducer);
  const quiContacterData = useSelector((state) => state.quicontacterReducer);
  const [categorie, setcategorie] = useState("");
  const [sujet, setSujet] = useState("");
  const [resolution, setResolution] = useState("");
  const [EditId, setEditId] = useState(null);
  const [editData, setEditData] = useState({
    categorie: "",
    sujet: "",
    resolution: "",
  });

  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const handleEdit = (outil) => {
    setEditId(outil.id);
    setEditData({
      categorie: outil.categorie,
      sujet: outil.sujet,
      resolution: outil.resolution,
    });
    setShowModal(true);
    toast.success("Mise à jour");
  };

  const handleCancelEdit = () => {
    setEditId(null);
    setEditData({
      categorie: "",
      sujet: "",
      resolution: "",
    });
    setShowModal(false);
  };

  const refreshData = () => {
    dispatch(getQuiContacter());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await dispatch(createQuiContacter(categorie, sujet, resolution));
    refreshData();
    setcategorie("");
    toast.success("Mise à jour");
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    dispatch(
      editeQuiContacter(
        EditId,
        editData.categorie,
        editData.sujet,
        editData.resolution
      )
    );
    handleCancelEdit();
    refreshData();
  };

  const handleDelete = (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce sujet ?")) {
      dispatch(deleteQuiContacter(id))
        .then(() => {
          refreshData();
          toast.success("Mise à jour");
        })
        .catch((error) => {
          console.log("Erreur lors de la suppression de l'outil :", error);
        });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Catégorie",
        accessor: "categorie",
      },
      {
        Header: "Thématique",
        accessor: "sujet",
      },
      {
        Header: "Résolution",
        accessor: "resolution",
        Cell: ({ cell }) => <div>{parse(cell.value)}</div>,
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ cell }) => (
          <div className="flexTable">
            <>
              <button
                onClick={() => handleEdit(cell.row.original)}
                className="blackButtonTable"
              >
                Modifier
              </button>
              <button
                onClick={() => handleDelete(cell.value)}
                className="deleteButton"
              >
                <i className="fa-regular fa-trash-can"></i>
              </button>
            </>
          </div>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => {
    if (!Array.isArray(quiContacterData)) return [];
    return quiContacterData;
  }, [quiContacterData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination
  );

  const { pageIndex, pageSize } = state;

  useEffect(() => {
    dispatch(getQuiContacter());
  }, [dispatch]);

  return (
    <>
      {userData.isAdmin ? (
        <>
          <ToastContainer />
          <Nav />
          <div className="mainContainer">
            <AdminMenu />
            <div className="centerBlock_admin">
              <h1>Ajouter/Editer un sujet</h1>
              <div className="addOutil">
                <form onSubmit={handleSubmit}>
                  <div className="new_outil">
                    <i className="fa-solid fa-circle-plus"></i>
                    <label htmlFor="name">
                      Catégorie: (Webtool, LocalBoutique, OVH ...)
                    </label>
                    <input
                      type="text"
                      id="name"
                      value={categorie}
                      onChange={(event) => setcategorie(event.target.value)}
                    />
                    <label htmlFor="name">
                      Thématique: (Bugs, KO, amélioration, ralentissement)
                    </label>
                    <input
                      type="text"
                      id="name"
                      value={sujet}
                      onChange={(event) => setSujet(event.target.value)}
                    />
                    <label htmlFor="name">
                      Résolution: (Ajoutez la description de la solution)
                    </label>
                    <div className="formationDescription">
                      <CKEditor
                        editor={Editor}
                        data={" "}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setResolution(data);
                        }}
                      />
                    </div>
                  </div>
                  <button type="submit">Ajouter</button>
                </form>
              </div>

              <div className="allOutils">
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={i}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination">
                  <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    Précédent
                  </button>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    Suivant
                  </button>
                  <span>
                    Page{" "}
                    <strong>
                      {pageIndex + 1} sur {pageOptions.length}
                    </strong>{" "}
                  </span>
                  <span>
                    Aller à la page :{" "}
                    <input
                      type="number"
                      defaultValue={pageIndex + 1}
                      onChange={(e) => {
                        const pageNumber = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(pageNumber);
                      }}
                      style={{ width: "50px" }}
                    />
                  </span>{" "}
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[5, 10, 20].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Afficher {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {showModal && (
            <div className="modal">
              <div className="modal-content">
                <h2>Modifier le sujet</h2>
                <form onSubmit={handleUpdate}>
                  <div>
                    <label>Catégorie:</label>
                    <input
                      type="text"
                      value={editData.categorie}
                      onChange={(e) =>
                        setEditData({
                          ...editData,
                          categorie: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label>Thématique:</label>
                    <input
                      type="text"
                      value={editData.sujet}
                      onChange={(e) =>
                        setEditData({
                          ...editData,
                          sujet: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label>Résolution:</label>
                    <CKEditor
                      editor={Editor}
                      data={editData.resolution}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setEditData({
                          ...editData,
                          resolution: data,
                        });
                      }}
                    />
                  </div>
                  <div className="modal-buttons">
                    <button type="button" onClick={handleCancelEdit}>
                      Annuler
                    </button>
                    <button type="submit">Valider</button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default AdminQuiContacter;
