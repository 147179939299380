import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../../Pages/Home";
import Login from "../../Pages/Login";
import Trending from "../../Pages/Trending";
import Signup from "../../Pages/Signup";
import UserProfil from "../../Pages/UserProfil";
import Formation from "../../Pages/Formation";
import AdminUsers from "../../Pages/AdminUsers";
import FormationAssign from "../../Pages/FormationAssign";
import AdminFormatonRequest from "../../Pages/AdminFormatonRequest";
import AdminFormationRequestPlanifie from "../../Pages/AdminFormationRequestPlanifie";
import AdminFormationRequestTermine from "../../Pages/AdminFormationRequestTermine";
import AdminFormationRequestAnnule from "../../Pages/AdminFormationRequestAnnule";
import AllUserFormation from "../../Pages/AllUserFormation";
import ForgotPassword from "../../Pages/ForgotPassword";
import NewPassword from "../../Pages/NewPassword";
import Knowledge from "../../Pages/Knowledge";
import KnowledgeInfo from "../../Pages/KnowledgeInfo";
import Post from "../../Pages/Post";
import AdminKnowledge from "../../Pages/AdminKnowledge";
import AdminKnowledgeAssign from "../../Pages/AdminKnowledgeAssigne";
import AdminKnowledgeTermine from "../../Pages/AdminKnowledgeTermine";
import AdminKnowledgeAnnule from "../../Pages/AdminKnowledgeAnnule";
import AdminOutils from "../../Pages/AdminOutils";
import Convertisseur from "../../Pages/Convertisseur";
import AdminOutilsExt from "../../Pages/AdminOutilsExt";
import AdminBoiteAOutils from "../../Pages/AdminBoiteAOutils";
import AdminQuiContacter from "../../Pages/AdminQuiContacter";
import AdminQuizz from "../../Pages/AdminQuizz";
import AdminDashBoard from "../Admin/AdminDashBoard";

const index = ({ socket }) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home socket={socket} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/reset/:token" element={<NewPassword />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/userprofil/:id"
          element={<UserProfil socket={socket} />}
        />
        <Route path="/trending" element={<Trending />} />
        <Route path="/formation" element={<Formation />} />
        <Route path="/adminUsers" element={<AdminUsers />} />
        <Route path="/dashboard" element={<AdminDashBoard />} />
        <Route path="/formationAssign/:id" element={<FormationAssign />} />
        <Route path="/requestFormation" element={<AdminFormatonRequest />} />
        <Route path="/planifie" element={<AdminFormationRequestPlanifie />} />
        <Route path="/termine" element={<AdminFormationRequestTermine />} />
        <Route path="/annule" element={<AdminFormationRequestAnnule />} />
        <Route path="/alluserformation" element={<AllUserFormation />} />
        <Route path="/nosoutils" element={<AdminOutils />} />
        <Route path="/nosoutilsExt" element={<AdminOutilsExt />} />
        <Route
          path="/boiteaoutils"
          element={<AdminBoiteAOutils socket={socket} />}
        />
        <Route path="/quicontacter" element={<AdminQuiContacter />} />
        <Route path="/createQuizz" element={<AdminQuizz />} />
        <Route path="/tutoriels" element={<Knowledge socket={socket} />} />
        <Route
          path="/tutoriels/:id"
          element={<KnowledgeInfo socket={socket} />}
        />
        <Route path="/requestKnowledge/" element={<AdminKnowledge />} />
        <Route path="/assignee" element={<AdminKnowledgeAssign />} />
        <Route path="/reqestTermine" element={<AdminKnowledgeTermine />} />
        <Route path="/requestAnnule" element={<AdminKnowledgeAnnule />} />
        <Route path="/post/:id" element={<Post socket={socket} />} />
        <Route
          path="/convertisseur"
          element={<Convertisseur socket={socket} />}
        />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};

export default index;
