import React, { useState } from "react";
import { useSelector } from "react-redux";
import Nav from "../Components/Nav/Nav";
import Login from "./Login";
import { DateParser } from "../Components/utils/Utils";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminMenu from "../Components/Admin/AdminMenu";
import KnowledgeMenu from "../Components/Knowledge/knowledgeMenu";
import KnowledgeStatus from "../Components/Knowledge/KnowledgeStatus";

const AdminKnowledgeAssign = () => {
  const userData = useSelector((state) => state.userReducer);
  const [activeCurrentIndex, setActiveCurrentIndex] = useState();

  const knowledgeRequest = useSelector(
    (state) => state.requestKnowledgeReducer
  );

  const requestWait = Object.values(knowledgeRequest).filter((knowledge) => {
    return knowledge.requestStatus === "1";
  });

  const toggleShowAccordion = (id) => {
    setActiveCurrentIndex(id);
  };

  return (
    <>
      <ToastContainer />
      {userData.isAdmin || userData.isReferent ? (
        <>
          <Nav />
          <div className="mainContainer">
            <AdminMenu />
            <div className="centerBlock_admin">
              <div className="useronline-container-admin">
                <div className="requestformation">
                  <>
                    <KnowledgeMenu active="assignee" />
                    {requestWait.map((request, id) => {
                      return (
                        <div
                          className="accordion-item"
                          key={id}
                          onClick={() => toggleShowAccordion(id)}
                        >
                          <div
                            className="requestknowledge"
                            key={request.id}
                            title="Voir la demande"
                          >
                            <div className="resquestknowledge_body">
                              <p>
                                De :{" "}
                                <span className="formaName">
                                  {request.user.firstname} {request.user.name}
                                </span>
                              </p>
                            </div>
                            <div className="resquestknowledge_body">
                              <p>
                                Assignée à :{" "}
                                <span className="formaName">
                                  {request.referent.firstname}{" "}
                                  {request.referent.name}
                                </span>
                              </p>
                            </div>
                            <div className="resquestknowledge_body">
                              {request.nature}
                            </div>
                            <div className="requestknowledge_cat">
                              <p>Catégorie:</p>
                              <span className="formaName">
                                {request.categorie}
                              </span>
                            </div>
                            <div className="requestknowledge_date">
                              <p>Date de la demande :</p>
                              <p>
                                <span className="formaName">
                                  {DateParser(request.createdAt)}
                                </span>
                              </p>
                            </div>
                          </div>
                          {activeCurrentIndex === id && (
                            <div className="resquestknowledge_content">
                              <p>
                                Demande :{" "}
                                <span className="formaName">
                                  {request.texte}
                                </span>
                              </p>
                              <div className="resquestformation_body">
                                <KnowledgeStatus knowledge={request} />
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default AdminKnowledgeAssign;
