import React from "react";
import loginLogo from "../../asset/loginlogo.png";
import { Link } from "react-router-dom";

const LoginLogo = () => {
  return (
    <>
      <Link to="/">
        <img src={loginLogo} alt="logo" />
      </Link>
    </>
  );
};

export default LoginLogo;
