import React from "react";
import share from "../../asset/share.gif";

const Share = () => {
  return (
    <>
      <img src={share} alt="poster" />
    </>
  );
};

export default Share;
