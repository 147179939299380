import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nav from "../Components/Nav/Nav";
import Login from "./Login";
import AdminMenu from "../Components/Admin/AdminMenu";
import { createQuiz, getQuiz, deleteQuizz } from "../actions/quizz.actions";
import AdminQuizzResult from "../Components/Quizz/AdminQuizzResult";
import { ToastContainer, toast } from "react-toastify";
import { useTable, usePagination } from "react-table";

const AdminQuizz = () => {
  const userData = useSelector((state) => state.userReducer);
  const quizzData = useSelector((state) => state.quizFormReducer.quizzes);
  const dispatch = useDispatch();

  const [nom, setNom] = useState("");
  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");
  const [questions, setQuestions] = useState([{ question: "", reponses: [] }]);
  const [selectedQuizId, setSelectedQuizId] = useState(null);

  const handleQuestionChange = (event, index) => {
    const { name, value } = event.target;
    const updatedQuestions = [...questions];
    updatedQuestions[index][name] = value;
    setQuestions(updatedQuestions);
  };

  const handleBonneReponseChange = (event, questionIndex, reponseIndex) => {
    const { checked } = event.target;
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].reponses[reponseIndex].estBonneReponse =
      checked;
    setQuestions(updatedQuestions);
  };

  const handleReponseChange = (event, questionIndex, reponseIndex) => {
    const { name, value } = event.target;
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].reponses[reponseIndex][name] = value;
    setQuestions(updatedQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { question: "", reponses: [] }]);
  };

  const handleAddReponse = (questionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].reponses.push({
      reponse: "",
      estBonneReponse: false,
    });
    setQuestions(updatedQuestions);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const quizData = {
      nom,
      dateDebut,
      dateFin,
      questions,
    };

    const overlappingQuiz = Object.values(quizzData).find(
      (quiz) =>
        (quiz.dateDebut <= dateFin && quiz.dateFin >= dateDebut) ||
        (dateDebut <= quiz.dateFin && dateFin >= quiz.dateDebut)
    );

    if (overlappingQuiz) {
      alert(
        "Impossible de créer ce quizz car il se chevauche avec un quizz existant."
      );
    } else {
      dispatch(createQuiz(quizData));
    }
  };

  const handleDelete = (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce quizz ?")) {
      dispatch(deleteQuizz(id))
        .then(() => {
          dispatch(getQuiz());
        })
        .catch((error) => {
          console.log("Erreur lors de la suppression du quizz :", error);
        });
    }
  };

  const handleRemoveReponse = (questionIndex, reponseIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].reponses.splice(reponseIndex, 1);
    setQuestions(updatedQuestions);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nom du quizz",
        accessor: "nom",
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ value }) => (
          <div className="flexTable">
            <button
              onClick={() => handleShowResults(value)}
              className="blackButtonTable"
            >
              Voir les résultats
            </button>
            <button
              className="deleteButton"
              onClick={() => handleDelete(value)}
            >
              <i className="fa-regular fa-trash-can"></i>
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => {
    if (!Array.isArray(quizzData)) return [];
    return quizzData;
  }, [quizzData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination
  );

  const { pageIndex, pageSize } = state;

  useEffect(() => {
    dispatch(getQuiz());
  }, [dispatch]);

  const handleShowResults = (quizId) => {
    setSelectedQuizId(quizId);
  };

  return (
    <>
      {userData.isAdmin ? (
        <>
          <>
            <Nav />
            <div className="mainContainer">
              <ToastContainer />
              <AdminMenu />
              <div className="centerBlock_admin">
                <h1>Ajouter un Quizz</h1>
                <div className="addOutil">
                  <form className="quizzForm" onSubmit={handleSubmit}>
                    <div className="topForm">
                      <label>Nom du quizz</label>
                      <input
                        type="text"
                        value={nom}
                        onChange={(event) => setNom(event.target.value)}
                        required
                      />
                      <label>Date de début</label>
                      <input
                        type="date"
                        value={dateDebut}
                        onChange={(event) => setDateDebut(event.target.value)}
                        required
                      />
                      <label>Date de fin</label>
                      <input
                        type="date"
                        value={dateFin}
                        onChange={(event) => setDateFin(event.target.value)}
                        required
                      />
                      <button type="button" onClick={handleAddQuestion}>
                        Ajouter une question
                      </button>
                    </div>
                    <div className="middlemiddle">
                      <div className="middleForm">
                        {questions.map((question, questionIndex) => (
                          <div key={questionIndex}>
                            <label>Question {questionIndex + 1}</label>
                            <input
                              type="text"
                              name="question"
                              className="questionInput"
                              value={question.question}
                              onChange={(event) =>
                                handleQuestionChange(event, questionIndex)
                              }
                              required
                            />
                            {question.reponses.map((reponse, reponseIndex) => (
                              <div
                                className="middleResponse"
                                key={reponseIndex}
                              >
                                <label>Réponse {reponseIndex + 1}</label>
                                <input
                                  type="text"
                                  name="reponse"
                                  value={reponse.reponse}
                                  onChange={(event) =>
                                    handleReponseChange(
                                      event,
                                      questionIndex,
                                      reponseIndex
                                    )
                                  }
                                  required
                                />
                                <label>
                                  Bonne réponse ?
                                  <input
                                    type="checkbox"
                                    name="estBonneReponse"
                                    checked={reponse.estBonneReponse}
                                    onChange={(event) =>
                                      handleBonneReponseChange(
                                        event,
                                        questionIndex,
                                        reponseIndex
                                      )
                                    }
                                  />
                                </label>
                                {question.reponses.length > 1 && (
                                  <button
                                    type="button"
                                    className="delete-question"
                                    onClick={() =>
                                      handleRemoveReponse(
                                        questionIndex,
                                        reponseIndex
                                      )
                                    }
                                  >
                                    Supprimer cette réponse
                                  </button>
                                )}
                              </div>
                            ))}
                            <button
                              type="button"
                              onClick={() => handleAddReponse(questionIndex)}
                            >
                              Ajouter une réponse
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="bottomForm">
                      <button type="submit">✅ Valider le Quizz</button>
                    </div>
                  </form>
                </div>
                <h2>Quizz créés :</h2>
                <table
                  {...getTableProps()}
                  className="useronline-container-admin userlist_admin"
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={i}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination">
                  <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    Précédent
                  </button>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    Suivant
                  </button>
                  <span>
                    Page{" "}
                    <strong>
                      {pageIndex + 1} sur {pageOptions.length}
                    </strong>{" "}
                  </span>
                  <span>
                    Aller à la page :{" "}
                    <input
                      type="number"
                      defaultValue={pageIndex + 1}
                      onChange={(e) => {
                        const pageNumber = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(pageNumber);
                      }}
                      style={{ width: "50px" }}
                    />
                  </span>{" "}
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[5, 10, 20].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Afficher {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
                {selectedQuizId !== null && (
                  <AdminQuizzResult
                    quiz={selectedQuizId}
                    onClose={() => setSelectedQuizId(null)}
                  />
                )}
              </div>
            </div>
          </>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default AdminQuizz;
