import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable, usePagination } from "react-table";
import Nav from "../Components/Nav/Nav";
import Login from "./Login";
import { Link } from "react-router-dom";
import ImageProfil from "../Components/Profil/ImageProfil";
import AdminMenu from "../Components/Admin/AdminMenu";
import { standByUser } from "../actions/user.actions";
import { getUsers } from "../actions/users.actions";

const AdminUsers = () => {
  const userData = useSelector((state) => state.userReducer);
  const usersData = useSelector((state) => state.usersReducer);
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();

  const handleStandBy = (event, userId) => {
    event.preventDefault();
    dispatch(standByUser(userId)).then(() => {
      dispatch(getUsers());
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Utilisateur",
        accessor: "profileImage",
        Cell: ({ cell }) => (
          <div className="flex">
            <ImageProfil id={cell.row.original.id} />
            <span>
              {cell.row.original.firstname} {cell.row.original.name}
            </span>
            <span>{cell.row.original.isActif ? "Actif" : "En pause"}</span>
          </div>
        ),
      },
      {
        Header: "Service",
        accessor: "service",
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ cell }) => (
          <div className="actionButton">
            <Link to={{ pathname: `/formationAssign/${cell.row.original.id}` }}>
              <button className="blackButton">Ajouter une formation</button>
            </Link>
            <button
              className={
                cell.row.original.isActif ? "blackButton" : "greenButton"
              }
              onClick={(event) => handleStandBy(event, cell.row.original.id)}
            >
              {cell.row.original.isActif
                ? "Mettre en pause ⏸️"
                : "Mettre en ligne ▶️"}
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => {
    if (!Array.isArray(usersData)) return [];
    return usersData.filter(
      (users) =>
        users.firstname.toLowerCase().includes(query) ||
        users.name.toLowerCase().includes(query)
    );
  }, [usersData, query]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination
  );

  const { pageIndex, pageSize } = state;

  const searchToggle = () => {
    const toggleSearch = document.querySelector(".userlist_admin");
    toggleSearch.classList.toggle("active");
  };

  return (
    <>
      {userData.isAdmin ? (
        <>
          <Nav />
          <div className="mainContainer">
            <AdminMenu />
            <div className="centerBlock_admin">
              <Link to={"/signup"}>
                <button className="create_user">Créer un utilisateur</button>
              </Link>
              <div className="search_admin">
                <div className="searchAdmin">
                  <div className="search-icon-container">
                    <i className="fa-solid fa-magnifying-glass search-icon"></i>
                  </div>
                  <input
                    type="text"
                    placeholder=" Rechercher un utilisateur"
                    className="searchbar_admin"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onClick={searchToggle}
                  />
                </div>

                <table
                  {...getTableProps()}
                  className="useronline-container-admin userlist_admin"
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={i}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination">
                  <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    Précédent
                  </button>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    Suivant
                  </button>
                  <span>
                    Page{" "}
                    <strong>
                      {pageIndex + 1} sur {pageOptions.length}
                    </strong>{" "}
                  </span>
                  <span>
                    Aller à la page :{" "}
                    <input
                      type="number"
                      defaultValue={pageIndex + 1}
                      onChange={(e) => {
                        const pageNumber = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(pageNumber);
                      }}
                      style={{ width: "50px" }}
                    />
                  </span>{" "}
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[5, 10, 20].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Afficher {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default AdminUsers;
