import React, { useEffect, useState } from "react";
import axios from "axios";

const ImageProfil = ({ id }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    // Envoyez une requête HTTP GET au backend pour récupérer l'URL de l'image de l'utilisateur
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/user/avatar/${id}`)
      .then((response) => setImageUrl(response.data));
  }, [id]);

  return imageUrl ? <img src={imageUrl} alt="Avatar" /> : <p>Chargement...</p>;
};

export default ImageProfil;
