import React from "react";
import { useDispatch } from "react-redux";
import {
  getRequestFormation,
  deleteRequestFormation,
} from "../../actions/requestFormation.actions";

const DeleteRequest = ({ formationsId }) => {
  const dispatch = useDispatch();

  const handleDelete = (e) => {
    dispatch(deleteRequestFormation(formationsId)).then(() =>
      dispatch(getRequestFormation())
    );
  };

  return (
    <button className="deleteButton">
      <i
        onClick={() => {
          if (window.confirm("Voulez-vous supprimer cette formation ?")) {
            handleDelete();
          }
        }}
        className="far fa-trash-alt poub"
      ></i>
    </button>
  );
};

export default DeleteRequest;
