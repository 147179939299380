import React, { useState, useEffect } from "react";
import Tesseract from "tesseract.js";

const ImageToText = () => {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let worker;

    const initializeWorker = async () => {
      worker = await Tesseract.createWorker({
        // Assurez-vous d'utiliser les URL correctes pour les fichiers de langue
        languagePath: "https://tessdata.projectnaptha.com/4.0.0",
        corePath: "https://tessdata.projectnaptha.com/4.0.0",
        workerPath: "https://tessdata.projectnaptha.com/4.0.0/worker.js",
        logger: (m) => console.log(m),
      });

      await worker.load();
      await worker.loadLanguage("fra");
      await worker.initialize("fra");

      const handleProgress = (message) => {
        const newProgress = message.progress * 100;
        setProgress(newProgress);
      };

      worker.on.progress = handleProgress;
    };

    initializeWorker();

    return async () => {
      if (worker) {
        await worker.terminate();
      }
    };
  }, []);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    setLoading(true);

    const {
      data: { text },
    } = await Tesseract.recognize(file, "fra");

    setText(text);
    setLoading(false);
  };

  return (
    <div className="texttoimg">
      <input type="file" onChange={handleImageUpload} className="parameters" />

      {progress > 0 && (
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progress}%` }}></div>
        </div>
      )}

      {loading ? (
        <>
          <div className="loading-text parameters">Extraction en cours...</div>
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progress}%` }}></div>
          </div>
        </>
      ) : (
        <>
          {text.length > 1 && (
            <div className="result-text parameters">{text}</div>
          )}
        </>
      )}
    </div>
  );
};

export default ImageToText;
