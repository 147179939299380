import {
  DELETE_COMMENT_TUTO,
  EDIT_COMMENT_TUTO,
  GET_COMMENTS_TUTO,
} from "../actions/commentTuto.actions";

const initialState = {};

export default function commentTutoReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMMENTS_TUTO:
      return action.payload;
    case EDIT_COMMENT_TUTO:
      return state.map((comment) => {
        if (comment.id === action.payload.commentId) {
          return {
            ...comment,
            commentaire: action.payload.commentaire,
          };
        } else return comment;
      });
    case DELETE_COMMENT_TUTO:
      return state.filter((comment) => comment.id !== action.payload.commentId);
    default:
      return state;
  }
}
