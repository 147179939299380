import axios from "axios";

export const GET_SCHEDULLED_POSTS = "GET_SCHEDULLED_POSTS";

export const getScheduledPosts = (numb) => (dispatch) =>
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/message/?scheduledDate__isnull=false`,
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      const currentDate = new Date();
      const newArray = res.data
        .filter((post) => new Date(post.scheduledDate) > currentDate)
        .sort((a, b) => new Date(a.scheduledDate) - new Date(b.scheduledDate))
        .slice(0, numb);
      dispatch({ type: GET_SCHEDULLED_POSTS, payload: newArray });
    })
    .catch((error) => console.error(error));
