import {
  GET_TOOL_EXT,
  EDIT_TOOL_EXT,
  DELETE_TOOL_EXT,
} from "../actions/outilsExt.actions";

const initialState = {};

export default function outilsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TOOL_EXT:
      return action.payload;
    case EDIT_TOOL_EXT:
      const {
        outilsEditId,
        outilsName,
        outilsEmail,
        outilsPassword,
        outilsLink,
      } = action.payload;
      return {
        ...state,
        [outilsEditId]: {
          ...state[outilsEditId],
          outilsName,
          outilsEmail,
          outilsPassword,
          outilsLink,
        },
      };
    case DELETE_TOOL_EXT:
      const deletedToolId = action.payload;
      return {
        ...state,
        outils: state.outils.filter((tool) => tool.id !== deletedToolId),
      };
    default:
      return state;
  }
}
