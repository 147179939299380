import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLikes, likePost, unlikePost } from "../../actions/likes.actions";
import ImageProfil from "../Profil/ImageProfil";

const Like = ({ post, socket, authorName }) => {
  const [liked, setLiked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [likesForCurrentPost, setLikesForCurrentPost] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const likesData = useSelector((state) => state.likesReducer);
  const userData = useSelector((state) => state.userReducer);
  const usersData = useSelector((state) => state.usersReducer);

  const dispatch = useDispatch();

  //Menu toggle
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // on récupère tous les likes du post
  const likesDuPost = Object.values(likesData).filter((like) => {
    return like.postId === post.id;
  });

  const like = likesDuPost.length;

  const [numblikes, setNumbLikes] = useState(like);

  useEffect(() => {
    setLoading(true);
    setNumbLikes(likesDuPost.length);
    setLoading(false);
  }, [likesData]);

  useEffect(() => {
    setLoading(true);
    const likedByCurrentUser = Object.values(likesData).find(
      (like) =>
        like.userId === userData.id &&
        like.postId === post.id &&
        like.like !== null
    );
    if (likedByCurrentUser) setLiked(true);
    setLoading(false);
  }, [likesData, userData]);

  useEffect(() => {
    setLoading(true);
    const likesForCurrentPost = Object.values(likesData).filter(
      (like) => like.postId === post.id
    );
    setLikesForCurrentPost(likesForCurrentPost);
    setLoading(false);
  }, [likesData, post.id]);

  const handleLike = async () => {
    setLoading(true);
    if (liked === false) {
      try {
        await dispatch(likePost(post.id, userData.id));
        setLiked(true);
        setNumbLikes(numblikes + 1);
        dispatch(getLikes());
        socket.emit("sendNotification", {
          senderName: userData.name,
          receiverName: authorName,
          receiverId: post.author,
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await dispatch(unlikePost(post.id, userData.id));
        setLiked(false);
        setNumbLikes(numblikes - 1);
        dispatch(getLikes());
      } catch (error) {
        console.log(error);
      }
    }
    setLoading(false);
  };

  // afficher les photos des utilisateurs qui ont liké
  const usersWhoLiked = likesForCurrentPost.map((like) => {
    const user = usersData.find((user) => user.id === like.userId);
    if (!user) return null;
    return (
      <div onClick={toggleMenu} key={user.id}>
        <ImageProfil id={user.id} key={user.id} />
      </div>
    );
  });

  return (
    <>
      <div onClick={() => handleLike(1)}>
        <div className="placement">
          {loading ? (
            <i className="fas fa-spinner fa-spin"></i>
          ) : liked ? (
            <i className="fa-solid fa-thumbs-up red"></i>
          ) : (
            <>
              <span>Ce post a-t-il été utile ? | </span>
              <i className="fa-regular fa-thumbs-up"></i>
            </>
          )}
        </div>
      </div>
      <>
        <div className="placement">
          <p> {numblikes}</p>
          <p className="usersLiked">
            {loading ? (
              <p className="usersLiked">
                <i className="fas fa-spinner fa-spin"></i>
              </p>
            ) : (
              <p className="usersLiked">
                {usersWhoLiked.slice(0, 3)}
                {numblikes > 3 ? "..." : null}
              </p>
            )}
          </p>
        </div>
        <div className={`profil_like_edit ${isOpen ? "active_edit_like" : ""}`}>
          <div className="close" onClick={toggleMenu}>
            <i className="far fa-times-circle"></i>
          </div>
          <h3>Réactions</h3>
          <div className="content_like">
            {likesForCurrentPost.map((like) => {
              const user = usersData.find((user) => user.id === like.userId);
              if (!user) return null;
              return (
                <li className="userLikeListe" key={user.id}>
                  <ImageProfil id={user.id} />
                  <span className="heart_like">👍</span>
                  <div className="info_user">
                    <span>{user.name} </span>
                    <span> {user.firstname}</span>
                    <p>{user.service}</p>
                  </div>
                </li>
              );
            })}
          </div>
        </div>
      </>
    </>
  );
};

export default Like;
