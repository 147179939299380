import React, { useEffect, useState } from "react";

const Sun = ({ outil }) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalID = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(intervalID);
  }, []);

  return (
    <div class="container_weather">
      <div class="sun">
        <div className="body_container">
          <div className="tool_info">
            <p className="outil_name">{outil.name}</p>
            <p>{time.toLocaleString()}</p>
            <p>Grand soleil</p>
          </div>
          <div className="tool_weather">
            <span class="sun-rays"></span>
            <span class="sun"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sun;
