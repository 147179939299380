import axios from "axios";

export const ADD_TOOL = "ADD_TOOL";
export const GET_TOOL = "GET_TOOL";
export const EDIT_TOOL = "EDIT_TOOL ";

export const addOutil = (outilsName) => {
  return (dispatch) =>
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/outils/`,
      withCredentials: true,
      data: { name: outilsName },
    })
      .then((res) => {
        dispatch({
          type: ADD_TOOL,
          payload: res.data,
        });
      })
      .catch((error) => console.error(error));
};

export const getOutil = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/outils/nosoutils`,
      { withCredentials: true }
    );
    dispatch({
      type: GET_TOOL,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const editeOutil = (outilId, name, status) => {
  return (dispatch) => {
    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/api/outils/${outilId}`,
      withCredentials: true,
      data: { name, status },
    })
      .then((res) => {
        dispatch({
          type: EDIT_TOOL,
          payload: { outilId, name, status },
        });
      })
      .catch((error) => console.error(error));
  };
};
