import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nav from "../../Components/Nav/Nav";
import Login from "../../Pages/Login";
import AdminMenu from "../../Components/Admin/AdminMenu";
import { getKnwoledge } from "../../actions/knowledge.actions";
import { getPosts } from "../../actions/post.actions";
import moment from "moment";
import { getComments } from "../../actions/comment.actions";
import { getLikes } from "../../actions/likes.actions";
import UserImg from "../../asset/totalUser.png";
import UserTuto from "../../asset/knowledge.png";
import LikeChart from "./LikeChart";
import TutoCategorieChart from "./TutoCategorieChart";
import OnlineUsers from "./OnlineUsers";
import ImageProfil from "../Profil/ImageProfil";
const AdminDashBoard = () => {
  const userData = useSelector((state) => state.userReducer);
  const usersData = useSelector((state) => state.usersReducer);
  const tutosData = useSelector((state) => state.knowledgeReducer);
  const postsData = useSelector((state) => state.postReducer);
  const commentairesData = useSelector((state) => state.commentReducer);
  const likesData = useSelector((state) => state.likesReducer);
  const dispatch = useDispatch();

  const totalUsers = Object.values(usersData).length;
  const totalTuto = Object.values(tutosData).length;
  const totalPosts = Object.values(postsData).length;
  const totalCommenaites = Object.values(commentairesData).length;
  const totalLikes = Object.values(likesData).length;

  const totalPostsMonthly = Object.values(postsData).filter((post) => {
    const currentMonthAndYear = moment().format("MM-YYYY");

    const postMonthAndYear = moment(post?.createdAt).format("MM-YYYY");
    return postMonthAndYear === currentMonthAndYear;
  });

  const usersInactiveFor5Days = Object.values(usersData).filter((user) => {
    return (
      (moment(user.lastLogin).isBefore(moment().subtract(5, "days")) ||
        user.lastLogin === null) &&
      user.firstname !== "Ancien"
    );
  });

  useEffect(() => {
    dispatch(getKnwoledge());
    dispatch(getPosts());
    dispatch(getComments());
    dispatch(getLikes());
  }, [dispatch]);

  return (
    <>
      {userData.isAdmin ? (
        <>
          <Nav />
          <div className="mainContainer">
            <AdminMenu />
            <div className="centerBlockDashboard">
              <div className="adminWidget">
                <div className="smallWidget">
                  <p>Utilisateurs</p>
                  <br />
                  <p className="sousTexte">
                    <span>{totalUsers}</span>
                  </p>
                  <div className="image">
                    <img src={UserImg} alt="" />
                  </div>
                </div>
                <div className="smallWidget">
                  <p>Tutoriels</p>
                  <br />
                  <p className="sousTexte">
                    <span>{totalTuto}</span>
                  </p>
                  <div className="image">
                    <img src={UserTuto} alt="" />
                  </div>
                </div>
                <div className="bigWidget">
                  <p>Statistiques Post</p>
                  <p className="sousTexte">
                    Avec un total de <span>{totalPostsMonthly?.length}</span>{" "}
                    post ce mois 😎
                  </p>
                  <div className="extension">
                    <div className="extensionPart">
                      <div className="icon">
                        <i className="fa-solid fa-arrow-trend-up"></i>
                      </div>
                      <div className="monthStats">
                        <p>Post total</p>
                        <span>{totalPosts}</span>
                      </div>
                    </div>
                    <div className="extensionPart">
                      <div className="icon">
                        <i className="fa-regular fa-message"></i>
                      </div>
                      <div className="monthStats">
                        <p>Commentaire total</p>
                        <span>{totalCommenaites}</span>
                      </div>
                    </div>
                    <div className="extensionPart">
                      <div className="icon">
                        <i className="fa-regular fa-thumbs-up"></i>
                      </div>
                      <div className="monthStats">
                        <p>Like total</p>
                        <span>{totalLikes}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bigWidget">
                  <p>Statistiques des Likes</p>
                  <LikeChart postsData={postsData} likesData={likesData} />
                </div>
                <div className="bigWidget">
                  <p>Répartition des tutoriels</p>
                  <TutoCategorieChart tutosData={tutosData} />
                </div>
                <div className="bigWidget">
                  <p>Utilisateurs en ligne</p>
                  <OnlineUsers userData={userData} />
                </div>
                <div className="bigWidget">
                  <p>Utilisateurs inactifs depuis 5 jours</p>
                  {usersInactiveFor5Days.length === 0 ? (
                    <p className="sousTexte">
                      Tous les utilisateurs sont actifs. 😎
                    </p>
                  ) : (
                    <ul className="usersOnlineDashboard">
                      {usersInactiveFor5Days.map((user) => (
                        <li key={user.id} className="userOnline_list">
                          <ImageProfil id={user.id} /> {user.firstname}{" "}
                          {user.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default AdminDashBoard;
