import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import Sun from "./Sun";
import Thunder from "./Thunder";
import Wind from "./Wind";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getOutil } from "../../actions/outils.actions";

const Weather = () => {
  const [isLoading, setIsLoading] = useState("true");
  const outilsData = useSelector((state) => state.outilsReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOutil());
    setIsLoading(false);
  }, [dispatch]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <>
      {isLoading ? (
        <i className="fas fa-spinner spin"></i>
      ) : (
        <Slider {...settings}>
          {Object.values(outilsData).map((outil) => {
            let weatherComponent;
            switch (outil.status) {
              case "ok":
                weatherComponent = <Sun outil={outil} />;
                break;
              case "thunder":
                weatherComponent = <Thunder outil={outil} />;
                break;
              case "wind":
                weatherComponent = <Wind outil={outil} />;
                break;
              default:
                weatherComponent = null;
            }
            return <div key={outil.id}>{weatherComponent}</div>;
          })}
        </Slider>
      )}
    </>
  );
};

export default Weather;
