import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nav from "../Components/Nav/Nav";
import Login from "./Login";
import AdminMenu from "../Components/Admin/AdminMenu";
import { addOutil, editeOutil, getOutil } from "../actions/outils.actions";

const AdminOutils = () => {
  const userData = useSelector((state) => state.userReducer);
  const outilsData = useSelector((state) => state.outilsReducer);
  const [outilsName, setOutilsName] = useState("");
  const [outilsStatus, setOutilsStatus] = useState(
    Object.fromEntries(
      Object.values(outilsData).map((outil) => [outil.id, outil.status])
    )
  );
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(addOutil(outilsName)).then(dispatch(getOutil()));
    setOutilsName("");
  };

  const handleStatusChange = (outilId, event) => {
    setOutilsStatus({
      ...outilsStatus,
      [outilId]: event.target.value,
    });
  };

  const handleOutilSubmit = (outilId, name) => {
    dispatch(editeOutil(outilId, name, outilsStatus[outilId]));
  };

  useEffect(() => {
    dispatch(getOutil());
  }, [dispatch]);

  return (
    <>
      {userData.isAdmin ? (
        <>
          <>
            <Nav />
            <div className="mainContainer">
              <AdminMenu />
              <div className="centerBlock_admin">
                <h1>La météo de nos outils</h1>
                <div className="addOutil">
                  <form onSubmit={handleSubmit}>
                    <div className="new_outil">
                      <i className="fa-solid fa-circle-plus"></i>
                      <label htmlFor="name">Nom de l'outil:</label>
                      <input
                        type="text"
                        id="name"
                        value={outilsName}
                        onChange={(event) => setOutilsName(event.target.value)}
                      />
                    </div>
                    <button type="submit">Ajouter</button>
                  </form>
                </div>

                <div className="allOutils">
                  {Object.values(outilsData).map((outil) => {
                    return (
                      <div className="outils" key={outil.id}>
                        <div className="leftPart">
                          <span>{outil.name}</span>
                        </div>
                        <div className="rightPart">
                          <select
                            value={outilsStatus[outil.id]}
                            onChange={(event) =>
                              handleStatusChange(outil.id, event)
                            }
                          >
                            <option value="">--Choisissez un statut--</option>
                            <option value="ok">Grand soleil</option>
                            <option value="wind">Perturbations</option>
                            <option value="thunder">Orageux</option>
                          </select>
                          <button
                            onClick={() =>
                              handleOutilSubmit(outil.id, outil.name)
                            }
                          >
                            Modifier
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default AdminOutils;
