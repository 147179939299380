import axios from "axios";

export const GET_KNOWLEDGE = "GET_KNOWLEDGE";
export const KNOWLEDGE_POST = "KNOWLEDGE_POST";
export const DELETE_KNOWLEDGE = "DELETE_KNOWLEDGE";
export const EDITE_KNOWLEDGE = "EDITE_KNOWLEDGE";

export const getKnwoledge = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/knowledge/`,
      {
        withCredentials: true,
      }
    );
    const array = response.data;
    const newArray = array.reverse();
    dispatch({ type: GET_KNOWLEDGE, payload: newArray });
  } catch (error) {
    console.error(error);
  }
};

export const createKnowledge = (
  usersId,
  texte,
  categories,
  title,
  publishOnFeed
) => {
  return (dispatch) => {
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/knowledge/`,
      withCredentials: true,
      data: { usersId, texte, categories, title, publishOnFeed },
    })
      .then((res) => {
        dispatch({
          type: KNOWLEDGE_POST,
          payload: { usersId, texte, categories, title, publishOnFeed },
        });
      })
      .catch((error) => console.error(error));
  };
};

export const deleteKnowledge = (knwoledgeId) => {
  return (dispatch) => {
    return axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/api/knowledge/${knwoledgeId}`,
      withCredentials: true,
    })
      .then((res) => {
        dispatch({ type: DELETE_KNOWLEDGE, payload: { knwoledgeId } });
      })
      .catch((error) => console.error(error));
  };
};

export const editeKnowledge = (knowledgeId, texte, title) => {
  return (dispatch) => {
    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/api/knowledge/${knowledgeId}`,
      withCredentials: true,
      data: { texte, title },
    })
      .then((res) => {
        dispatch({
          type: EDITE_KNOWLEDGE,
          payload: { knowledgeId, texte, title },
        });
      })
      .catch((error) => console.error(error));
  };
};
