import axios from "axios";
import { toast } from "react-toastify";

export const GET_USER = "GET_USER";
export const UPLOAD_PICTURE = "UPLOAD_PICTURE";
export const PROFIL_EDIT = "PROFIL_EDIT";
export const STANDBYUSER = "STANDBYUSER";

export const getUser = (uid) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/user/${uid}`,
      { withCredentials: true }
    );
    dispatch({ type: GET_USER, payload: res.data });
  } catch (error) {
    console.error(error);
  }
};

export const uploadPicture = (data, id) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/api/user/avatar/${id}`,
        withCredentials: true,
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      });

      dispatch({
        type: UPLOAD_PICTURE,
        payload: res.data.media,
      });
      window.location.reload();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const updateProfil = (
  userId,
  firstname,
  name,
  birthday,
  email,
  password,
  service,
  coach
) => {
  return async (dispatch) => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/user/${userId}`,
        withCredentials: true,

        data: { firstname, name, birthday, email, password, service, coach },
      });

      dispatch({
        type: PROFIL_EDIT,
        payload: { firstname, name, birthday, email, password, service, coach },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const standByUser = (userId) => {
  return async (dispatch) => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/api/user/${userId}`,
        withCredentials: true,
      });

      dispatch({
        type: STANDBYUSER,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
