import { useSelector } from "react-redux";
import Nav from "../Components/Nav/Nav";
import { UidContext } from "../Components/App.Context";
import Thread from "../Components/Thread";
import NewPostForm from "../Components/Post/NewPostForm";
import { useContext, useState } from "react";
import Login from "./Login";
import Menu from "../Components/Nav/Menu";
import CompetenceUsers from "../Components/Formation/CompetenceUsers";
import Weather from "../Components/Weather/Weather";
import QuiContacterForm from "../Components/QuiContacter/QuiContacterForm";
import Think from "../asset/think.png";
import HelpStars from "../asset/helpStars.png";
// import backgroundImage from "../asset/starwarsbg.jpeg";
// import NeigeRight from "../asset/neige-right.png";
// import NeigeLeft from "../asset/neige-left.png";

const Home = ({ socket }) => {
  const userData = useSelector((state) => state.userReducer);
  const uid = useContext(UidContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenHelp, setIsOpenHelp] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggle_help = () => {
    setIsOpenHelp(!isOpenHelp);
  };

  return (
    <>
      {uid && userData.isActif ? (
        <>
          <Nav socket={socket} />
          <div className="mainContainer">
            <Menu />
            <div className="centerBlock">
              {userData.isAdmin || userData.isReferent ? (
                <NewPostForm socket={socket} />
              ) : null}
              <Thread socket={socket} />
            </div>
            <div className="rightBlock">
              <div className="left_menu_weather">
                <Weather />
              </div>
              <div className="right-block-container" onClick={toggle_help}>
                {/* <div className="neige-bloc-right">
                  <img className="image-neige" src={NeigeRight} alt="neige" />
                </div> */}
                <div className="help_stars">
                  <p>Besoin d'aide sur un sujet précis ?</p>
                  <button>Voir qui peut m'aider</button>
                </div>
                <div className="helpImg">
                  <img src={HelpStars} alt="" />
                </div>
                <div className={`crop_img ${isOpenHelp ? "active_help" : ""}`}>
                  <div className="close" onClick={toggle_help}>
                    <i className="far fa-times-circle"></i>
                  </div>
                  <div
                    className="content_help"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <CompetenceUsers />
                  </div>
                </div>
              </div>
              <div className="quiContacter" onClick={toggle}>
                <div className="thinkContact">
                  {/* <div className="neige-bloc-left">
                    <img className="image-neige" src={NeigeLeft} alt="neige" />
                  </div> */}
                  <div className="thinkContant">
                    <p>Qui contacter en cas de problème ?</p>
                    <button>Voir les thématiques</button>
                  </div>
                  <div className="contactImg">
                    <img src={Think} alt="" />
                  </div>
                </div>
                <div className={`crop_img ${isOpen ? "active_contact" : ""}`}>
                  <div className="close" onClick={toggle}>
                    <i className="far fa-times-circle"></i>
                  </div>
                  <h3>Qui contacter en cas de problème ?</h3>
                  <div
                    className="content_crop"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <QuiContacterForm />
                  </div>
                </div>
              </div>

              {/* <div className="bg-starwars" style={divStyle}>
                <div className="text-starwars">
                  <p>CHALLENGE truspilot</p>
                </div>
                <div className="sous-titre-starwars">
                  <p>[Résultats définitifs]</p>
                </div>
                <button className="button-starswars">
                  {" "}
                  <a
                    href="https://view.genial.ly/6569ecebd0d3fd00145bcfa2/presentation-challenge-avis-resultats-definitifs"
                    target="_blank"
                  >
                    Voir les Résultats{" "}
                  </a>
                </button>
              </div> */}
            </div>
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default Home;
