import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOutilExt } from "./../../actions/outilsExt.actions";
import Login from "./../../Pages/Login";
import { UidContext } from "./../App.Context";
import { Link } from "react-router-dom";

const CardTools = () => {
  const uid = useContext(UidContext);
  const outilsData = useSelector((state) => state.outilsExtReducer);
  const [activeOutil, setActiveOutil] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOutilExt());
  }, [dispatch]);

  const handleOutilClick = (outil) => {
    setActiveOutil(outil);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredOutils = Object.values(outilsData).filter((outil) => {
    return outil.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const sortedOutils = [...filteredOutils].sort(
    (a, b) => a.position - b.position
  );

  return (
    <>
      {uid ? (
        <>
          <div className="close">
            <Link to={"/"}>
              <i className="far fa-times-circle"></i>{" "}
            </Link>
          </div>
          <div className="searchBar">
            <input
              type="text"
              placeholder="Rechercher un outil par nom..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div className="allOutilsExt">
            {sortedOutils.map((outil) => {
              return (
                <div className="outils" key={outil.id}>
                  <div className="leftPart">
                    <div onClick={() => handleOutilClick(outil)}>
                      <div className="outil_content">
                        <i className="fa-solid fa-lock-open"></i>
                        <span className="outil_name"> {outil.name}</span>
                      </div>
                    </div>
                  </div>

                  {activeOutil && activeOutil.id === outil.id ? (
                    <div className="bottomPart">
                      {outil.email && (
                        <p>
                          {" "}
                          <i className="fa-regular fa-envelope"></i> Email :{" "}
                          <span> {outil.email}</span>
                          <span>
                            <i
                              className="fa-solid fa-copy"
                              onClick={() => handleCopy(outil.email)}
                            ></i>
                          </span>
                        </p>
                      )}
                      {outil.password && (
                        <p>
                          {" "}
                          <i className="fa-solid fa-key"></i> Mot de passe :{" "}
                          <span> {outil.password}</span>
                          <span>
                            <i
                              className="fa-solid fa-copy"
                              onClick={() => handleCopy(outil.password)}
                            ></i>
                          </span>
                        </p>
                      )}
                      {outil.link && (
                        <Link
                          to={outil.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button class="url_site">
                            <span class="button-content">
                              Aller sur le site{" "}
                            </span>
                          </button>
                        </Link>
                      )}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default CardTools;
