import React from "react";

const StatusTuto = () => {
  return (
    <div className="tuto">
      <span>
        <i className="fas fa-brain fa-beat"></i> Tutoriel !
      </span>
    </div>
  );
};

export default StatusTuto;
