import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import {
  editRequestKnowledge,
  getRequestKnowledge,
} from "../../actions/requestKnowledge";
import "bs-stepper/dist/css/bs-stepper.min.css";
import Stepper from "bs-stepper";
import Finish from "../../asset/finish_form.png";
import Lottie from "lottie-react";
import Fleche from "../../asset/fleche.json";
import ImageProfil from "../Profil/ImageProfil";

const AsignKnowledge = ({ request, closeModal }) => {
  const [referent, setReferent] = useState("");

  const knowledgeId = request.id;
  const requestStatus = "1";
  const usersData = useSelector((state) => state.usersReducer);

  const referentUsers = Object.values(usersData).filter((users) => {
    return users.isReferent || users.isAdmin;
  });

  const dispatch = useDispatch();

  const handleCreateForm = (e) => {
    e.preventDefault();
    dispatch(editRequestKnowledge(knowledgeId, requestStatus, referent)).then(
      () => dispatch(getRequestKnowledge()),
      Swal.fire("Le tutoriel est assigné au Référent !")
    );
    closeModal();
  };

  const [stepper, setStepper] = useState(null);

  useEffect(() => {
    const bsStepper = new Stepper(document.querySelector(".bs-stepper"));
    setStepper(bsStepper);

    return () => bsStepper.destroy();
  }, []);

  return (
    <>
      <div className="accordion-content">
        <div className="Requestformation_container">
          <div className="bs-stepper">
            <div className="bs-stepper-header">
              <div className="step" data-target="#step1">
                <button className="step-trigger">Détails</button>
              </div>
              <div className="line"></div>
              <div className="step" data-target="#step2">
                <button className="step-trigger">Assignation</button>
              </div>
              <div className="line"></div>
              <div className="step" data-target="#step3">
                <button className="step-trigger">Validation</button>
              </div>
            </div>
            <div className="bs-stepper-content">
              <div id="step1" className="content">
                <div className="detailsUser">
                  <div className="flex">
                    <ImageProfil id={request?.user?.id} />
                    <p>
                      {request?.user?.firstname} {request?.user?.name}
                    </p>
                    <span className="badge">{request.categorie} </span>
                  </div>
                </div>
                <div className="textTutoRequest">
                  <p>Demande :</p>
                  <br />
                  <p>{request?.texte}</p>
                </div>
                <br />
                <div className="bs-stepper-action">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => stepper && stepper.next()}
                  >
                    Suivant
                  </button>
                </div>
              </div>
              <form action="" onSubmit={handleCreateForm}>
                <div id="step2" className="content">
                  <label htmlFor="formationFormateur">
                    Référent pour ce Tutoriel
                  </label>
                  <select
                    name="referentSelect"
                    id="referentSelect"
                    onChange={(e) => setReferent(e.target.value)}
                    required
                  >
                    <option value="">Choisissez un référent</option>
                    {referentUsers.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.firstname} {user.name}
                      </option>
                    ))}
                  </select>
                  <br />

                  <div className="bs-stepper-action">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => stepper && stepper.previous()}
                    >
                      Précédent
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => stepper && stepper.next()}
                    >
                      Suivant
                    </button>
                  </div>
                </div>
                <div id="step3" className="content">
                  <div className="texteFinish">
                    <div className="finishText">
                      <p>Félicitation!</p>
                      <p>
                        Vous êtes à un clic pour assigner le tutoriel au
                        référent.
                      </p>
                    </div>
                    <div className="finishImg">
                      <img src={Finish} alt="" />
                    </div>
                    <div className="fleche">
                      <Lottie animationData={Fleche} loop={true} />
                    </div>
                  </div>

                  <div className="bs-stepper-action">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => stepper && stepper.previous()}
                    >
                      Précédent
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Assigner le tutoriel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AsignKnowledge;
