import React, { useState } from "react";
import Konami from "react-konami-code";
import Pacman from "react-pacman";

const Konam = () => {
  const [showTetris, setShowTetris] = useState(false);

  const handleEasterEgg = () => {
    setShowTetris(true);
  };

  const closeTetris = () => {
    setShowTetris(false);
  };

  return (
    <div>
      <Konami action={handleEasterEgg} />
      {showTetris && (
        <div className="tetris-popup">
          <button onClick={closeTetris} className="close-buttons">
            X
          </button>
          <Pacman />
        </div>
      )}
    </div>
  );
};

export default Konam;
