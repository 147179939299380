import React, { useState, useEffect } from "react";

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    setIsVisible(window.pageYOffset > 100);
    const windowHeight =
      window.innerHeight || document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    const scrollPercent = (windowBottom / docHeight) * 100;
    setScrollProgress(scrollPercent);
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {isVisible && (
        <div className="scroll-to-top-container">
          <div
            className="scroll-progress"
            style={{ width: `${scrollProgress}%` }}
            data-scroll={Math.floor(scrollProgress)}
          ></div>
          <button onClick={handleClick} className="scroll-to-top-button">
            <i className="fas fa-arrow-up"></i>
          </button>
        </div>
      )}
    </>
  );
}

export default ScrollToTopButton;
