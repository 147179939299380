import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "../Components/utils/Utils";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import { Link } from "react-router-dom";
import ImageProfil from "../Components/Profil/ImageProfil";

const Trending = () => {
  const usersData = useSelector((state) => state.usersReducer);
  const [showModal, setShowModal] = useState(false);

  const services = [
    "Coach",
    "Ordonnancement",
    "Planification",
    "Graphiste",
    "E-commerce",
    "Référent technique",
    "Traffic manager",
    "Chargé(e) de projet web",
  ].map((name) => ({
    name,
    data: usersData?.filter((user) => user.service === name) ?? [],
  }));

  const Service = ({ data }) => (
    <div className="useronline-container">
      {!isEmpty(data[0]) &&
        data.map((users) => {
          return (
            <Link to={`/userprofil/${users.id}`} key={users.id}>
              <div className="user-online">
                <div className="left-side">
                  <div>
                    <ImageProfil id={users.id} />
                  </div>
                </div>
                <div className="right-side">
                  <span>
                    {users.firstname} {users.name}
                  </span>
                  <span className="service">{users.service}</span>
                </div>
              </div>
            </Link>
          );
        })}
    </div>
  );

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="head-trending">
        <h3>Équipe Production</h3>
      </div>
      <div className="items">
        <Accordion atomic={true}>
          {services.map((service) => (
            <AccordionItem key={service.name} title={service.name}>
              <Service data={service.data} />
            </AccordionItem>
          ))}
        </Accordion>
      </div>
      <div className="orga">
        <button className="blackButton" onClick={() => setShowModal(true)}>
          Organigramme
        </button>
      </div>

      {showModal && (
        <div className="modal">
          <div
            className="modal-content full-width-modal"
            style={{ position: "relative" }}
          >
            <button className="close-button" onClick={closeModal}>
              <i className="fa-regular fa-circle-xmark"></i>
            </button>
            <h2>Organigramme</h2>
            <div style={{ width: "auto" }}>
              <div
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  paddingTop: 0,
                  height: 0,
                }}
              >
                <iframe
                  title="Genially sans titre"
                  frameBorder="0"
                  width="1200px"
                  height="675px"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  src="https://view.genial.ly/64c4131cffd9850019e5842f"
                  type="text/html"
                  allowscriptaccess="always"
                  allowfullscreen="true"
                  scrolling="yes"
                  allownetworking="all"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Trending;
