import React from "react";
import miniLogo from "../../asset/minilogo.png";

const MiniLogo = () => {
  return (
    <>
      <img src={miniLogo} alt="logo" />
    </>
  );
};

export default MiniLogo;
