import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { creatRequestFormation } from "../../actions/requestFormation.actions";
import { getFormations } from "../../actions/formations.actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { Link } from "react-router-dom";

const RequestFormation = () => {
  const [formationName, setformationName] = useState("");
  const [formationDate, setformationDate] = useState("");
  const [usersId, setusersId] = useState("");

  const userData = useSelector((state) => state.userReducer);
  const usersData = useSelector((state) => state.usersReducer);

  const FormationsData = useSelector((state) => state.formationsReducer);
  const dispatch = useDispatch();

  const allFormations = Object.values(FormationsData).filter((formation) => {
    return formation;
  });

  allFormations.sort((a, b) => {
    if (a.formationName < b.formationName) {
      return -1;
    }
    if (a.formationName > b.formationName) {
      return 1;
    }
    return 0;
  });

  const allUsers = Object.values(usersData).filter((users) => {
    return users;
  });

  const toggleFormation = () => {
    const toggleMenu = document.querySelector(".profil_menu_edit");
    toggleMenu.classList.toggle("active_edit");
  };

  const handleRequestForm = (e) => {
    e.preventDefault();
    if (formationName && formationDate) {
      dispatch(
        creatRequestFormation(usersId, formationName, formationDate)
      ).then(() => toast.success("La demande est envoyée"));
    }
  };

  useEffect(() => {
    dispatch(getFormations());
  }, [dispatch, userData]);

  return (
    <>
      <ToastContainer />
      {userData.isCoach ? (
        <div className="action_request">
          <div className="profil_buttons_edit">
            <div className="admin">
              <div className="coach__button" onClick={toggleFormation}>
                <span> Demande de formation</span>
              </div>
            </div>
          </div>
          <div className="profil_menu_edit">
            <form action="" onSubmit={handleRequestForm}>
              <div className="close" onClick={toggleFormation}>
                <i className="far fa-times-circle"></i>
              </div>
              <Link
                to="/files/Competences-webtool.xlsx"
                target="_blank"
                download
              >
                <i className="fa-solid fa-file-arrow-down"></i> Télécharger le
                questionnaire (à faire remplir avant chaque demande)
              </Link>
              <br />
              <label htmlFor="formationName">Nom de la formation</label>
              <select
                name="formationName"
                id="formationName"
                onChange={(e) => setformationName(e.target.value)}
              >
                <option value="">--Voir la liste des formations--</option>
                {allFormations.map((formations) => {
                  let timeArray = formations.formationHours.split(":");
                  let hours = parseFloat(timeArray[0]);
                  let minutes = parseFloat(timeArray[1]);
                  let seconds = parseFloat(timeArray[2]);

                  let totalMinutes = hours * 60 + minutes + seconds / 60;

                  let formattedDuration;
                  if (totalMinutes >= 60) {
                    let h = Math.floor(totalMinutes / 60);
                    let m = totalMinutes % 60;
                    formattedDuration = h > 1 ? h + "h" : h + "h " + m + "min";
                  } else {
                    formattedDuration = totalMinutes + "min";
                  }

                  return (
                    <option value={formations.id} key={formations.id}>
                      {formations.formationName} - {formations.formationLvl} - (
                      {formattedDuration})
                    </option>
                  );
                })}
              </select>
              <br />

              <label htmlFor="DateFormation">Date souhaitée</label>
              <input
                type="datetime-local"
                name="DateFormation"
                className="input_signup"
                id="DateFormation"
                onChange={(e) => setformationDate(e.target.value)}
                required
              />
              <br />
              <label htmlFor="formationUser">Utilisateur(s) à former</label>
              <Select
                options={allUsers.map((user) => ({
                  label: user.firstname + " - " + user.name,
                  value: user.id,
                }))}
                isMulti
                onChange={(selectedUsers) =>
                  setusersId(selectedUsers.map((user) => user.value))
                }
              />
              <br />
              <input
                type="submit"
                className="submit"
                value="Demander une formation"
              />
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RequestFormation;
