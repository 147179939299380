import React, { useEffect, useState } from "react";
import socketIOClient from "socket.io-client";

const OnlineUsers = ({ userData }) => {
  const [onlineUsers, setOnlineUsers] = useState([]);

  useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_API_URL);

    socket.on("connect", () => {
      console.log("✅ Connecté au server.");
      socket.emit("newUser", userData.name + " " + userData.firstname);
    });

    socket.on("disconnect", () => {
      console.log("❌ Déconnecté du serveur.");
      socket.emit("disconnectUser", "current-user-name");
    });

    socket.on("onlineUsers", (users) => {
      setOnlineUsers(users);
    });

    return () => {
      if (socket.readyState === 1) {
        socket.disconnect();
      }
    };
  }, []);

  return (
    <div className="usersOnlineDashboard">
      <span>{onlineUsers.length}</span>
      <ul>
        {onlineUsers.map((user) => (
          <li className="userOnline_list" key={user._id}>
            <i className="fas fa-circle fa-beat-fade"></i> {user.username}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OnlineUsers;
