import axios from "axios";

export const ADD_REQUEST_KNOWLEDGE = "ADD_REQUEST_KNOWLEDGE";
export const GET_REQUEST_KNOWLEDGE = "GET_REQUEST_KNOWLEDGE";
export const EDIT_REQUEST_KNOWLEDGE = "EDIT_REQUEST_KNOWLEDGE";
export const EDIT_REQUEST_STATUS = "EDIT_REQUEST_STATUS";

export const addRequestKnowledge = (data) => {
  return (dispatch) => {
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/knowledge/requestKnowledge`,
      withCredentials: true,
      data: data,
    })
      .then((res) => {
        dispatch({ type: ADD_REQUEST_KNOWLEDGE, payload: res.data });
      })
      .catch((error) => console.error(error));
  };
};

export const getRequestKnowledge = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/knowledge/requestKnowledge/allRequest`,
      {
        withCredentials: true,
      }
    );
    dispatch({ type: GET_REQUEST_KNOWLEDGE, payload: response.data });
  } catch (error) {
    console.error(error);
  }
};

export const editRequestKnowledge = (
  knowledgeId,
  requestStatus,
  referentId
) => {
  return (dispatch) => {
    return axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/api/knowledge/requestKnowledge/${knowledgeId}`,
      withCredentials: true,
      data: { requestStatus: requestStatus, referentId: referentId },
    })
      .then((res) => {
        dispatch({
          type: EDIT_REQUEST_KNOWLEDGE,
          payload: { knowledgeId, requestStatus, referentId },
        });
      })
      .catch((err) => console.error(err));
  };
};

export const editRequestStatus = (knowledgeId, requestStatus) => {
  return (dispatch) => {
    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/api/knowledge/requestKnowledge/${knowledgeId}`,
      withCredentials: true,
      data: { requestStatus: requestStatus },
    })
      .then((res) => {
        dispatch({
          type: EDIT_REQUEST_STATUS,
          payload: { knowledgeId, requestStatus },
        });
      })
      .catch((err) => console.error(err));
  };
};
