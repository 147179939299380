import React, { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const IframeYoutube = () => {
  const [autoPlay, setAutoPlay] = useState(false);
  const [mute, setMute] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [loop, setLoop] = useState(false);
  const [rel, setRel] = useState(true);
  const [videoId, setVideoId] = useState("");

  const generateYoutubeUrl = () => {
    let url = "https://www.youtube.com/embed/";

    if (videoId) {
      url += videoId;
    }

    const params = [];

    if (autoPlay) {
      params.push("autoplay=1");
    }

    if (mute) {
      params.push("mute=1");
    }

    if (!showControls) {
      params.push("controls=0");
    }

    if (loop) {
      params.push(`loop=1&playlist=${videoId}`);
    }

    if (!rel) {
      params.push("rel=0");
    }

    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    return url;
  };

  const generateIframeCode = () => {
    const youtubeUrl = generateYoutubeUrl();
    const iframeCode = `<iframe width="560" height="315" src="${youtubeUrl}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>`;
    return iframeCode;
  };

  return (
    <>
      <div className="videoId">
        <div>
          <label>
            ID de la vidéo:
            <input
              type="text"
              value={videoId}
              onChange={(e) => setVideoId(e.target.value)}
            />
            <i className="fas fa-info-circle" id="youtube-tooltip" data-tip></i>
            <ReactTooltip
              anchorId="youtube-tooltip"
              place="bottom"
              content="Récuperer uniquement l'id de la vidéo, à la fin de l'url, exemple : mcb55EMkh6I "
            />
          </label>
        </div>
      </div>
      <div className="parameters">
        <div className="parameters_input">
          <label>
            <input
              type="checkbox"
              checked={autoPlay}
              onChange={(e) => setAutoPlay(e.target.checked)}
            />
            Auto play ?
          </label>
        </div>
        <div className="parameters_input">
          <label>
            <input
              type="checkbox"
              checked={mute}
              onChange={(e) => setMute(e.target.checked)}
            />
            Mute ?
          </label>
        </div>
        <div className="parameters_input">
          <label>
            <input
              type="checkbox"
              checked={showControls}
              onChange={(e) => setShowControls(e.target.checked)}
            />
            Afficher les commandes du lecteur ?
          </label>
        </div>
        <div className="parameters_input">
          <label>
            <input
              type="checkbox"
              checked={loop}
              onChange={(e) => setLoop(e.target.checked)}
            />
            Loop ?
          </label>
        </div>
        <div className="parameters_input">
          <label>
            <input
              type="checkbox"
              checked={rel}
              onChange={(e) => setRel(e.target.checked)}
            />
            Autoriser les vidéos similaires ?
          </label>
        </div>
      </div>
      <div className="youtube_result">
        <iframe
          width="560"
          height="315"
          src={generateYoutubeUrl()}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe>
        <div>
          <h3>Code d'intégration:</h3>
          <textarea
            value={generateIframeCode()}
            readOnly
            rows={4}
            style={{ width: "100%" }}
          ></textarea>
        </div>
      </div>
    </>
  );
};

export default IframeYoutube;
