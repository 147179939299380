import axios from "axios";
import { toast } from "react-toastify";

export const CREATE_QUIZZ = "CREATE_QUIZZ";
export const GET_QUIZZ = "GET_QUIZZ";
export const DELETE_QUIZZ = "DELETE_QUIZZ";

export const createQuiz = (quizData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/quizz/`,
      quizData,
      {
        withCredentials: true,
      }
    );
    dispatch({ type: CREATE_QUIZZ, payload: res.data });
    toast.success("Quiz créé avec succès");
  } catch (error) {
    console.error(error);
    toast.error("Erreur lors de la création du quiz");
  }
};

export const getQuiz = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/quizz/`, {
      withCredentials: true,
    });

    dispatch({
      type: GET_QUIZZ,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteQuizz = (id) => {
  return (dispatch) => {
    return axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/api/quizz/delete/${id}`,
      withCredentials: true,
    })
      .then((res) => {
        dispatch({ type: DELETE_QUIZZ, payload: { id } });
      })
      .catch((error) => console.error(error));
  };
};
