import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { creatUsersFormations } from "../../actions/usersFormation.actions";
import {
  getRequestFormation,
  editRequestFormation,
} from "../../actions/requestFormation.actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "add-to-calendar-button/assets/css/atcb.css";
import moment from "moment";
import "bs-stepper/dist/css/bs-stepper.min.css";
import Stepper from "bs-stepper";
import Finish from "../../asset/finish_form.png";
import Lottie from "lottie-react";
import Fleche from "../../asset/fleche.json";
import Sad from "../../asset/sad.json";
import Mid from "../../asset/mid.json";
import Top from "../../asset/top.json";

const AsignFormRequest = ({ request, closeModalValidation }) => {
  const [formationPercent, setformationPercent] = useState("50");
  const [formationDate, setformationDate] = useState(request?.formationDate);
  const [formationFormateur, setformationFormateur] = useState(
    request?.formationFormateur
  );

  const userId = request.user.id;
  const formationId = request.Formation.id;
  const subformationId = request.id;
  const [formationStatus] = useState("2");

  const dispatch = useDispatch();

  const handleCreateForm = (e) => {
    e.preventDefault();
    dispatch(
      editRequestFormation(subformationId, formationStatus),
      creatUsersFormations(
        userId,
        formationId,
        formationPercent,
        formationDate,
        formationFormateur
      )
    );
    dispatch(
      creatUsersFormations(
        userId,
        formationId,
        formationPercent,
        formationDate,
        formationFormateur
      )
    ).then(
      () => dispatch(getRequestFormation()),
      toast.success(
        `La formation est maintenant assignée à ${request.user.firstname} ${request.user.name}`
      )
    );
    closeModalValidation();
  };

  let momentDate = moment.utc(formationDate);
  let momentDuration = moment.duration(request.Formation.formationHours);
  momentDate.add(momentDuration);

  const [stepper, setStepper] = useState(null);

  useEffect(() => {
    const bsStepper = new Stepper(document.querySelector(".bs-stepper"));
    setStepper(bsStepper);

    return () => bsStepper.destroy();
  }, []);

  return (
    <>
      <div className="accordion-content">
        <h2>
          Valider la formation à :{" "}
          <span className="formaName">
            {request.user.firstname} {request.user.name}
          </span>
        </h2>
        <div className="Requestformation_container">
          <div className="bs-stepper">
            <div className="bs-stepper-header">
              <div className="step" data-target="#step1">
                <button className="step-trigger">Vérification</button>
              </div>
              <div className="line"></div>
              <div className="step" data-target="#step2">
                <button className="step-trigger">Réussite</button>
              </div>
              <div className="line"></div>
              <div className="step" data-target="#step3">
                <button className="step-trigger">Validation</button>
              </div>
            </div>

            <div className="bs-stepper-content">
              <form onSubmit={handleCreateForm}>
                <div id="step1" className="content">
                  <p>Vérifier si les éléments ci-dessous sont bien conforme</p>
                  <label htmlFor="formationDate">Date de la formation</label>
                  <input
                    type="datetime-local"
                    name="formationDate"
                    className="input_signup"
                    id="formationDate"
                    defaultValue={formationDate}
                    onChange={(e) => setformationDate(e.target.value)}
                    required
                  />
                  <br />
                  <label htmlFor="formationHour">Durée de formation</label>
                  <input
                    type="text"
                    name="formationHour"
                    className="input_signup"
                    id="formationHour"
                    value={request.Formation.formationHours}
                    disabled="disabled"
                  />
                  <br />
                  <label htmlFor="formationFormateur">Formateur</label>
                  <input
                    type="text"
                    name="formationFormateur"
                    className="input_signup"
                    id="formationFormateur"
                    defaultValue={formationFormateur}
                    onChange={(e) => setformationFormateur(e.target.value)}
                    required
                  />
                  <br />
                  <div className="bs-stepper-action">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => stepper && stepper.next()}
                    >
                      Suivant
                    </button>
                  </div>
                </div>
                <div id="step2" className="content">
                  <label htmlFor="formationPercent">
                    <p className="textePoucents">
                      {request.user.firstname} {request.user.name} à{" "}
                      <span className="formStyle">
                        {" "}
                        <output htmlFor="formationPercent">
                          {formationPercent}%
                        </output>{" "}
                      </span>
                      de réussite sur cette formation
                      <br />
                      <p>⚠️ merci de bien renseigner cette information ⚠️ </p>
                      <p>(visible que pour les admins)</p>
                    </p>
                  </label>
                  <div className="emoji">
                    <div className="emojiSad">
                      <Lottie animationData={Sad} loop={true} />
                    </div>
                    <div className="emojiMid">
                      <Lottie animationData={Mid} loop={true} />
                    </div>
                    <div className="emojiTop">
                      <Lottie animationData={Top} loop={true} />
                    </div>
                  </div>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    step="1"
                    name="formationPercent"
                    className="input_range"
                    id="formationPercent"
                    required
                    onChange={(e) => setformationPercent(e.target.value)}
                  />
                  <br />
                  <div className="bs-stepper-action">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => stepper && stepper.previous()}
                    >
                      Précédent
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => stepper && stepper.next()}
                    >
                      Suivant
                    </button>
                  </div>
                </div>
                <div id="step3" className="content">
                  <div className="texteFinish">
                    <p className="finishText">Félicitation!</p>
                    <p>Vous êtes à un clic pour valider la formation.</p>
                    <div className="finishImg">
                      <img src={Finish} alt="" />
                    </div>
                    <div className="fleche">
                      <Lottie animationData={Fleche} loop={true} />
                    </div>
                  </div>

                  <div className="bs-stepper-action">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => stepper && stepper.previous()}
                    >
                      Précédent
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Valider la formation
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AsignFormRequest;
