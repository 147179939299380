import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteMediaMessage,
  getPosts,
  updateMedia,
  updatePost,
} from "../../actions/post.actions";
import { UidContext } from "../App.Context";
import { DateParser, isEmpty } from "../utils/Utils";
import CardComments from "./CardComments";
import DeleteCard from "./DeleteCard";
import Like from "./Like";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import StatusMessage from "./StatusMessage";
import StatusResolve from "./StatusResolve";
import parse from "html-react-parser";
import ImageProfil from "../Profil/ImageProfil";
import ImagePost from "./ImagePost";
import { ToastContainer, toast } from "react-toastify";
import StatusUpdate from "./StatusUpdate";
import StatusTuto from "./StatusTuto";
import StatusInformation from "./StatusInformation";
import StatusNews from "./StatusNews";

const Card = ({ post, socket }) => {
  const [isUpdated, setIsUpdated] = useState(false);
  const [textUpdate, setTextUpdate] = useState(null);
  const [showComments, setShowComments] = useState(false);

  const uid = useContext(UidContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [mediaFront, setMediaFront] = useState(null);
  const [media, setMedia] = useState();
  const [sousTraitant, setSousTraitant] = useState(null);

  const usersData = useSelector((state) => state.usersReducer);
  const userData = useSelector((state) => state.userReducer);
  const commentsData = useSelector((state) => state.commentReducer);
  const dispatch = useDispatch();

  const author = usersData.find((user) => user.id === post.author);
  const authorName = `${author?.name} ${author?.firstname}`;

  const updateItem = () => {
    if (textUpdate && !sousTraitant) {
      dispatch(updatePost(post.id, textUpdate));
      dispatch(getPosts());
    } else if (textUpdate && sousTraitant) {
      dispatch(updatePost(post.id, textUpdate, sousTraitant));
      dispatch(getPosts());
    }
    setIsUpdated(false);
  };

  const updateImg = () => {
    const formData = new FormData();
    formData.append("media", media);
    dispatch(updateMedia(post.id, formData)).then(() => dispatch(getPosts()));
    setIsUpdated(false);
  };

  const deleteImg = () => {
    dispatch(deleteMediaMessage(post.id, media)).then(() =>
      dispatch(getPosts())
    );
    setIsUpdated(false);
  };

  const handlePicture = (e) => {
    setMediaFront(URL.createObjectURL(e.target.files[0]));
    setMedia(e.target.files[0]);
  };

  // Check admin
  useEffect(() => {
    if (uid === userData.id && userData.isAdmin) {
      setIsAdmin(true);
    }
  }, [uid, userData.id, userData.isAdmin]);

  const numbComm = Object.keys(commentsData).reduce((count, id) => {
    if (commentsData[id].postId === post.id) {
      return count + 1;
    } else {
      return count;
    }
  }, 0);

  const handleCopy = () => {
    const url = `${process.env.REACT_APP_API_URL}/post/${post.id}`;
    navigator.clipboard
      .writeText(url)
      .then(() => toast.success("Url du post copié"));
  };

  return (
    <div className="card-container" key={post.id}>
      <ToastContainer />
      <>
        <div className="cardHeader">
          <div className="cardheader-img">
            <Link
              to={`/userprofil/${
                usersData.find((user) => user?.id === post?.author)?.id
              }`}
            >
              <ImageProfil
                id={usersData.find((user) => user?.id === post?.author)?.id}
              />
            </Link>
          </div>
          <div className="carheader-name">
            <span className="name">
              {post.status === 1 ? <StatusMessage /> : null}
              {post.status === 2 ? <StatusResolve /> : null}
              {post.status === 3 ? <StatusUpdate /> : null}
              {post.status === 4 ? <StatusTuto /> : null}
              {post.status === 5 ? <StatusNews /> : null}
              {post.status === 6 ? <StatusInformation /> : null}
              {!isEmpty(usersData[0]) &&
                usersData
                  .map((user) =>
                    user.id === post.author
                      ? user.firstname + " " + user.name
                      : null
                  )
                  .join("")}
            </span>
            <span className="service">
              {!isEmpty(usersData[0]) &&
                usersData
                  .map((user) =>
                    user.id === post.author ? user.service : null
                  )
                  .join("")}
            </span>
            <span className="date">{DateParser(post?.createdAt)}</span>
          </div>
          <div className="button-container-edit">
            <div className="share_post">
              <i
                className="fas fa-share-alt"
                onClick={handleCopy}
                title="partager le post"
              ></i>
            </div>
            {isAdmin || userData?.id === post?.author ? (
              <div className="cardheader-container-edit">
                <div
                  className="cardheader-edit"
                  onClick={() => setIsUpdated(!isUpdated)}
                >
                  <i className="far fa-edit"></i>
                </div>
              </div>
            ) : null}

            <div className="cardheader-container-delete">
              {isAdmin || userData?.id === post?.author ? (
                <div className="cardheader-container-edit">
                  <div
                    className="cardheader-edit"
                    onClick={() => setIsUpdated(!isUpdated)}
                  >
                    <DeleteCard id={post?.id} />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="postContent">
          <div className="postContent-post">
            {isUpdated === false && <p>{parse(post?.texte)}</p>}
            {isUpdated && (
              <div className="update-post">
                <CKEditor
                  editor={Editor}
                  data={post?.texte}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setTextUpdate(data);
                  }}
                />
                <div className="button-container">
                  <div
                    className="sous-traitant-checkbox"
                    title="envoyer par mail au sous-traitant"
                  >
                    <input
                      type="checkbox"
                      id="sous-traitant-checkbox"
                      name="sous-traitant"
                      onChange={(e) => setSousTraitant(e.target.checked)}
                    />
                    <i className="fas fa-users"></i>
                  </div>
                  <button className="btn" onClick={updateItem}>
                    Modifier mon message
                  </button>
                </div>
              </div>
            )}
          </div>

          {isUpdated === false && (
            <div className="postContent-image">
              {post.media && <ImagePost id={post.id} />}
            </div>
          )}
          {isUpdated && (
            <div className="update-post">
              <div className="postContent-image">
                {post.media ? (
                  <div className="imageArea">
                    {post.media && <ImagePost id={post.id} />}
                  </div>
                ) : (
                  <div className="addImg"></div>
                )}

                <div className="rBlock">
                  <div className="input_btn">
                    <input
                      type="file"
                      id="file-upload"
                      name="media"
                      accept=".jpg, .jpeg, .png"
                      onChange={(e) => handlePicture(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="button-container">
                {media && (
                  <button className="btn" onClick={updateImg}>
                    Modifier l'image
                  </button>
                )}
                {post.media && (
                  <button
                    className="btn-delete-pic"
                    onClick={(e) => {
                      setMedia(" ");
                      deleteImg();
                    }}
                  >
                    X
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="carFooter">
          <div className="cardfooter-like">
            <Like post={post} socket={socket} authorName={authorName} />
          </div>
          <div
            className="cardfooter-comment"
            onClick={() => setShowComments(!showComments)}
          >
            <i className="far fa-comment-alt"></i>
            <span>
              {numbComm} {numbComm > 1 ? "commentaires" : "commentaire"}
            </span>
          </div>
        </div>
        {showComments && (
          <CardComments post={post} socket={socket} authorName={authorName} />
        )}
      </>
    </div>
  );
};

export default Card;
