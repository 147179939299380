import React from "react";

const StatusInformation = () => {
  return (
    <div className="info">
      <span>
        <i className="fas fa-bullhorn fa-beat"></i> Information !
      </span>
    </div>
  );
};

export default StatusInformation;
