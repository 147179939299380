import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPost, getPosts } from "../../actions/post.actions";
import { DateParser, isEmpty } from "../utils/Utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Share from "../../Components/Logo/Share";
import parse from "html-react-parser";
import ImageProfil from "../Profil/ImageProfil";
import { getScheduledPosts } from "../../actions/scheduledPost.actions";
import { Link } from "react-router-dom";

const NewPostForm = ({ socket }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [mediaFront, setMediaFront] = useState(null);
  const [media, setMedia] = useState();
  const statusConfig = [
    { value: "0", label: "Par défaut" },
    { value: "1", label: "🔔 Important" },
    { value: "2", label: "✅ Résolu" },
    { value: "4", label: "🧠 Tutoriel" },
    { value: "5", label: "📄 Nouveauté" },
    { value: "6", label: "📢 Information" },
  ];
  const [status, setStatus] = useState("0");
  const [scheduledDate, setScheduledDate] = useState(null);
  const [sousTraitant, setSousTraitant] = useState(null);

  const userData = useSelector((state) => state.userReducer);
  const scheduledPosts = useSelector((state) => state.scheduledPostReducer);

  const myScheduledPosts = Object.values(scheduledPosts).filter((posts) => {
    return userData.id === posts.author;
  });

  const dispatch = useDispatch();

  const handlePicture = (e) => {
    setMediaFront(URL.createObjectURL(e.target.files[0]));
    setMedia(e.target.files[0]);
  };

  const handlePost = async () => {
    if (!message) {
      toast.error("Veuillez entrer un message avant de poster");
    } else if (scheduledDate && new Date(scheduledDate) <= new Date()) {
      toast.error("La date et l'heure programmées doivent être dans le futur");
    } else if (message || mediaFront) {
      const data = new FormData();
      data.append("userId", userData.id);
      data.append("texte", message);
      data.append("status", status);
      if (media) data.append("media", media);
      if (scheduledDate) data.append("scheduledDate", scheduledDate);
      if (sousTraitant) data.append("sousTraitant", sousTraitant);
      await dispatch(addPost(data))
        .then(() =>
          socket?.emit("sendNotificationAll", {
            senderName: userData.name,
          })
        )
        .then(() => cancelPost());
    } else {
      toast.error("Veuillez entrer un message");
    }
  };

  const cancelPost = () => {
    setMessage("");
    setMediaFront("");
    setMedia("");
    setScheduledDate(null);
    dispatch(getPosts());
  };

  useEffect(() => {
    if (!isEmpty(userData)) setIsLoading(false);
  }, [userData]);

  useEffect(() => {
    dispatch(getScheduledPosts(10));
  }, [dispatch]);

  return (
    <>
      <div className="post-container">
        <ToastContainer />
        {isLoading ? (
          <div className="chargement">
            <i className="fas fa-spinner fa-spin"></i>
            <span>CHARGEMENT</span>
          </div>
        ) : (
          <div className="post-container-inside">
            <div className="post-container-post">
              <div className="left-post-bloc">
                <div className="user-profil">
                  <ImageProfil id={userData.id} />
                </div>
              </div>
              <div className="right-bloc">
                <div className="form-input">
                  <div className="post-form">
                    <CKEditor
                      editor={Editor}
                      data={"Quoi de neuf, " + userData.firstname + " ?"}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setMessage(data);
                      }}
                    />
                  </div>
                  <div className="display-button">
                    <button className="post-message" onClick={handlePost}>
                      <Share />
                      Poster !
                    </button>
                    {message || mediaFront ? (
                      <button className="cancel" onClick={cancelPost}>
                        Annuler
                      </button>
                    ) : null}
                  </div>
                </div>
                <div className="image-uplod">
                  <div className="message-icon">
                    <i className="far fa-image"></i>
                    <span>Ajouter une image ?</span>
                    <input
                      type="file"
                      id="file-upload"
                      name="media"
                      accept=".jpg, .jpeg, .png"
                      onChange={(e) => handlePicture(e)}
                    />
                  </div>
                  <select
                    className="status_message"
                    name="color-select"
                    id="color-select"
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    {statusConfig.map((status) => (
                      <option value={status.value}>{status.label}</option>
                    ))}
                  </select>
                  <input
                    type="datetime-local"
                    onChange={(e) => setScheduledDate(e.target.value)}
                  />
                  <div
                    className="sous-traitant-checkbox"
                    title="envoyer par mail au sous-traitant"
                  >
                    <input
                      type="checkbox"
                      id="sous-traitant-checkbox"
                      name="sous-traitant"
                      onChange={(e) => setSousTraitant(e.target.checked)}
                    />
                    <i className="fas fa-users"></i>
                  </div>
                </div>
              </div>
            </div>
            {message || mediaFront ? (
              <li className="preview-container ping">
                <div className="preview-left">
                  <ImageProfil id={userData.id} />
                </div>
                <div className="preview-header">
                  <h3 className="name">
                    {userData.firstname + " " + userData.name}
                  </h3>
                  <span className="service">{userData.service}</span> <br />
                  <span className="date">
                    {DateParser(new Date(Date.now()))}
                  </span>
                  <div className="preview-content">
                    <p>{parse(message)}</p>
                    <img src={mediaFront} alt="" />
                  </div>
                </div>
              </li>
            ) : null}
          </div>
        )}
      </div>
      {myScheduledPosts.length > 0 ? (
        <div className="scheduled-post">
          {myScheduledPosts.map((post) => (
            <div className="scheduled-content" key={post.id}>
              <Link to={`${process.env.REACT_APP_API_URL}/post/${post.id}`}>
                <i className="far fa-clock"></i> Vous avez un message programmé
                à la date du : {DateParser(post.scheduledDate)}
              </Link>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default NewPostForm;
