import React from "react";
import { useDispatch } from "react-redux";
import { deleteKnowledge, getKnwoledge } from "../../actions/knowledge.actions";

const DeleteCard = ({ id }) => {
  const dispatch = useDispatch();

  const deleteQuote = () =>
    dispatch(deleteKnowledge(id)).then(() => dispatch(getKnwoledge()));

  return (
    <div
      onClick={() => {
        if (window.confirm("Voulez-vous supprimer ce tutoriel ?")) {
          deleteQuote();
        }
      }}
    >
      <i className="far fa-trash-alt"></i>
    </div>
  );
};

export default DeleteCard;
