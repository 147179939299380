import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const WebpConverter = () => {
    const [convertedImages, setConvertedImages] = useState([]);

    const handleDrop = async (acceptedFiles) => {
        try {
            const convertedImages = await Promise.all(
                acceptedFiles.map(async (file) => {
                    const imageBlob = await fileToWebp(file);
                    return { name: file.name.replace(/\.[^/.]+$/, '.webp'), blob: imageBlob };
                })
            );
            setConvertedImages(convertedImages);
        } catch (error) {
            console.error('Error converting images:', error);
        }
    };

    const handleConvert = () => {
        const zip = new JSZip();
        convertedImages.forEach((image) => {
            zip.file(image.name, image.blob);
        });
        zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, 'converted_images.zip');
        });
    };

    const fileToWebp = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    canvas.toBlob((blob) => {
                        resolve(blob);
                    }, 'image/webp');
                };
                img.src = event.target.result;
            };
            reader.readAsDataURL(file);
        });
    };

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: '.jpg, .png',
        onDrop: handleDrop,
    });

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.name} - {file.size} bytes
        </li>
    ));

    return (
        <div className='column'>
            <div {...getRootProps()} style={{ border: '1px dashed #ccc', padding: '20px', margin: '20px 0', background: '#f2f2f2ad', borderRadius: '5px' }}>
                <input {...getInputProps()} />
                <p>Glissez-déposez des images JPG ou PNG ici, ou cliquez pour sélectionner des fichiers.</p>
            </div>
            <aside>
                <h4>Fichiers</h4>
                <ul>{files}</ul>
            </aside>
            <button className='submit_prompt' onClick={handleConvert} disabled={convertedImages.length === 0}>
                Convertir et Télécharger
            </button>

        </div>
    );
};

export default WebpConverter;
