import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersFormations } from "../../actions/usersFormation.actions";
import MiniLogo from "../Logo/MiniLogo";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import parse from "html-react-parser";
import { useTable, usePagination } from "react-table";
import ProgressBar from "@ramonak/react-progress-bar";
import { UidContext } from "../../Components/App.Context";

const CompetenceCard = ({ user }) => {
  const [query, setQuery] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const uid = useContext(UidContext);
  const userData = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const userId = user?.id;

  useEffect(() => {
    const checkAdmin = () => {
      if (uid === userData.id && userData.isAdmin) {
        setIsAdmin(true);
      }
    };
    checkAdmin();
  }, []);

  const usersFormationsData = useSelector(
    (state) => state.usersformationsReducer
  );

  useEffect(() => {
    dispatch(getUsersFormations(userId));
  }, [dispatch, userId]);

  const data = useMemo(() => {
    if (!usersFormationsData) return [];

    return Object.values(usersFormationsData).filter((competence) =>
      competence?.Formation?.formationName
        .toLowerCase()
        .includes(query.toLowerCase())
    );
  }, [usersFormationsData, query]);

  const columnsCommon = useMemo(
    () => [
      {
        Header: "Formation",
        accessor: "Formation.formationName",
        Cell: ({ cell }) => (
          <>
            <div className="formation_head_label">
              <MiniLogo />
              <div className="formateur">
                <p className="title">{cell?.value}</p>
                <p>
                  <span>Formé par :</span>{" "}
                  {cell?.row?.original?.formationFormateur}
                </p>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Niveau de la formation",
        accessor: "Formation",
        Cell: ({ cell }) => (
          <div className="formation_lvl">
            <i
              className="fas fa-info-circle"
              id={`formadesc-${cell?.row?.original?.id}`}
              key={cell?.row?.original?.id}
              data-tip
            ></i>{" "}
            <span className="badge">
              {" "}
              {cell.row?.values?.Formation?.formationLvl}
            </span>
            <ReactTooltip
              anchorId={`formadesc-${cell?.row?.original?.id}`}
              place="bottom"
              content={parse(
                cell?.row?.original?.Formation?.formationDescription
              )}
            />
          </div>
        ),
      },
      {
        Header: "Date de la formation",
        accessor: (row) => {
          let date = new Date(row.formationDate);
          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();
          return isNaN(date)
            ? "Formé plusieurs fois"
            : `${day}/${month}/${year}`;
        },
      },
    ],
    []
  );

  const columnsAdmin = useMemo(() => {
    if (isAdmin) {
      return [
        {
          Header: "Assimilation",
          accessor: "formationPercent",
          Cell: ({ cell }) => (
            <div className="progress-bar-wrapper">
              <span className="progress-label">{`${cell.value}%`}</span>
              <ProgressBar
                completed={cell.value}
                bgColor={
                  cell.value >= 80
                    ? "#2ecc71"
                    : cell.value >= 20
                    ? "#f39c12"
                    : "#e74c3c"
                }
                isLabelVisible={false}
                labelColor="#000000"
                height="10px"
                animateOnRender={true}
                labelAlignment="center"
                labelSize="0px"
              />
            </div>
          ),
        },
      ];
    }
    return [];
  }, [isAdmin]);

  const columns = useMemo(
    () => [...columnsCommon, ...columnsAdmin],
    [columnsCommon, columnsAdmin]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state: { pageIndex, pageSize },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }, // Page initiale et taille de la page
    },
    usePagination
  );

  return (
    <>
      <div className="search_post">
        <input
          type="text"
          placeholder="Rechercher une formation"
          className="searchbar_post"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      <div className="forma">
        <table {...getTableProps()} className="formation_table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <span>
          Afficher par :{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              // Mettez à jour la taille de la page en utilisant les options
              setPageSize(Number(e.target.value));
            }}
          >
            {[3, 6, 10, 20].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </span>{" "}
        <span>
          Page <strong>{pageIndex + 1}</strong> sur {pageOptions.length}
        </span>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Précédent
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          Suivant
        </button>
      </div>
    </>
  );
};

export default CompetenceCard;
